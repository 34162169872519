import { FC, useEffect, useState } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import {
  cx,
  primaryBtnClasses,
  roleIState,
  rolesIState,
  SEO,
} from "../../../utils";
import { IRole, IRoles } from "../../../interfaces";
import { ROLES_PATH } from "../../../constants";
import { AssignRoleModal } from "./components";
import { useUserContext } from "../../../context/UserContext";
import {
  Modal,
  ModalSize,
  Table,
  Td,
  BtnSize,
  BtnStyle,
  Button,
} from "../../../components";

export const UserRoles: FC = () => {
  const { user } = useUserContext();
  const [response, setResponse] = useState<IRoles>(rolesIState);
  const [role, setRole] = useState<IRole>(roleIState);
  const { totalCount, roles } = !!response && response;
  const { currentPage, handlePageChange } = useCurrentPage();

  const { toggle: assignRoleModalToggle, visible: assignRoleModalVisible } =
    useToggle();
  const { toggle: deleteRoleModalToggle, visible: deleteRoleModalVisible } =
    useToggle();

  const { apiCall: getUserRoles, errors } = useFetch("get");
  const { apiCall: assignRoles, loading } = useFetch("post");
  const { apiCall: deleteUserRole, loading: deleteUserRoleLoading } =
    useFetch("delete");

  const PER_PAGE = 10;

  useEffect(() => {
    SEO({
      title: "Porta - User Roles",
    });
  }, []);

  useEffect(() => {
    getUserRoles(
      `/Users/${user?.id}/Roles?page=${currentPage}&pageSize=${PER_PAGE}`,
      {},
      (response) => {
        setResponse(response);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const assignUserRoles = async (roles: IRole[]) => {
    let selectedRolesArr: any = [];

    roles?.map((role) =>
      selectedRolesArr.push({
        userId: user.id,
        roleId: role?.id,
      })
    );

    assignRoles(`/Users/Roles`, selectedRolesArr, () => {
      assignRoleModalToggle();
      getUserRoles(
        `/Users/${user?.id}/Roles?page=${currentPage}&pageSize=${PER_PAGE}`,
        {},
        (response) => {
          setResponse(response);
        }
      );
      toast.success("User role set successfully!");
    });
  };

  const onDeleteUserRole = () => {
    const userRoleObj = {
      userId: user.id,
      roleId: role.id,
    };

    deleteUserRole(`/Users/Roles`, { data: userRoleObj }, () => {
      deleteRoleModalToggle();
      setResponse({
        ...response,
        roles: response.roles.filter((item: IRole) => item.id !== role.id),
      });
    });
  };

  return (
    <>
      <div>
        <Button
          btnStyle={BtnStyle.primary}
          btnSize={BtnSize.normal}
          className={cx([primaryBtnClasses, "inline-flex items-center my-8"])}
          onClick={assignRoleModalToggle}
        >
          <span className="inline-block w-5 h-5">
            <Icon name="Plus" size="20" />
          </span>
          <span className="ml-2">Assign</span>
        </Button>
      </div>

      <Table
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Name" },
          { children: "Description" },
          { children: "", className: "border-transparent" },
        ]}
        values={roles}
        renderTr={(el: IRole) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.id}>
              <Td className="py-3">
                <NavLink
                  to={`${ROLES_PATH}/${el.id}/settings`}
                  className="group block"
                >
                  {el?.name}
                </NavLink>
              </Td>

              <Td className="py-3">{el?.description}</Td>

              <Td className="text-primary-secText py-3" align="right">
                <button
                  type="button"
                  className="hover:text-warning focus:outline-none"
                  title="Delete"
                  onClick={() => {
                    deleteRoleModalToggle();
                    setRole(el);
                  }}
                >
                  <span className="w-6 h-6 inline-block">
                    <Icon name="Delete" size={22} />
                  </span>
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: roles?.length,
        }}
        emptyView={{ text: errors || "There are no roles yet." }}
      />

      {assignRoleModalVisible && (
        <AssignRoleModal
          loading={loading}
          toggle={assignRoleModalToggle}
          visible={assignRoleModalVisible}
          passSelectedRoles={(roles: IRole[]) => assignUserRoles(roles)}
        />
      )}

      {deleteRoleModalVisible && (
        <Modal
          withFooter
          warningModal
          hide={deleteRoleModalToggle}
          visible={deleteRoleModalVisible}
          modalSize={ModalSize.md}
          confirmBtnText="Remove"
          onConfirmClick={onDeleteUserRole}
          title="Remove role"
          loading={deleteUserRoleLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to remove{" "}
            <strong className="font-medium">{role?.name}</strong>?
          </p>
        </Modal>
      )}
    </>
  );
};
