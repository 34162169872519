import { useEffect, useState } from "react";

import { useParams } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { IApiResource } from "../../../interfaces";
import { ApiResourceForm } from "./components";
import { Loader } from "../../../components";
import { useFetch } from "../../../hooks";
import { SEO } from "../../../utils";

export const ApiResource = () => {
  let { id } = useParams();

  const [accessTokens, setAccessTokens] = useState<string[]>([]);
  const [claims, setClaims] = useState<string[]>([]);
  const [scopes, setScopes] = useState<string[]>([]);
  const {
    apiCall: getApiResource,
    loading,
    response: apiResource,
  } = useFetch("get");

  const { apiCall: editApiResource, loading: editApiResourceLoading } =
    useFetch("put");

  const {
    name,
    scopes: sopesI,
    userClaims,
    allowedAccessTokenSigningAlgorithms,
  } = !!apiResource && apiResource;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta -  API Resource",
    });
  }, []);

  useEffect(() => {
    if (!!id)
      getApiResource(`/ApiResources/${id}`, {}, (response) => {
        reset(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setScopes(sopesI);
    setClaims(userClaims);
    setAccessTokens(allowedAccessTokenSigningAlgorithms);
  }, [sopesI, userClaims, allowedAccessTokenSigningAlgorithms]);

  const onEditApiResource: SubmitHandler<any> = (data: IApiResource) => {
    const apiResourceData = {
      ...data,
      allowedAccessTokenSigningAlgorithms: accessTokens,
      scopes,
      name: data.name.trim(),
      userClaims: claims,
    };

    editApiResource(
      `/ApiResources`,
      apiResourceData,
      () => {
        toast.success("API resource saved successfully!");
      },
      (error) => {
        const errRes = error?.response?.data;

        if (errRes?.errors?.Name) {
          toast.error(errRes?.errors?.Name[0]);
        }

        if (errRes?.errors["Api Resource already exists"]) {
          toast.error(errRes?.errors["Api Resource already exists"][0]);
        }
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  let props = {
    editMode: true,
    register,
    control,
    onSubmit: handleSubmit(onEditApiResource),
    claims,
    setClaims,
    scopes,
    setScopes,
    accessTokens,
    setAccessTokens,
    errors,
    apiResource,
    paramId: id,
    name,
    loading: editApiResourceLoading,
  };

  return <ApiResourceForm {...props} />;
};
