import { FC, KeyboardEvent, useState, ChangeEvent, useEffect } from "react";

import {
  Paragraph,
  SectionTitle,
  SearchHeader,
  ParagraphSize,
  Tooltip,
  TooltipPosition,
  ArrowPosition,
} from "../../components";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { CMP_API_URL } from "../../constants";
import { useDomainContext } from "../../context";
import { useFetch, useToggle } from "../../hooks";
import { SEO } from "../../utils";
import { CookiesTable, CreateCookieModal } from "./components";
import { IDomainCategory } from "./interface";

export const DomainCookies: FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState<number>(1);
  const [categoryId, setCategoryId] = useState<string>("");
  const [categories, setCategories] = useState<IDomainCategory[]>();

  const { domain } = useDomainContext();
  const { encryptedId } = !!domain && domain;
  const { toggle, visible } = useToggle();

  const { apiCall: getDomainCookies, response } = useFetch("get");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value);
  };

  useEffect(() => {
    SEO({
      title: "Porta - Domain Cookies",
    });
  }, []);

  const getDomainCookiesData = () => {
    getDomainCookies(
      `${CMP_API_URL}/CookiesListView?domainId=${encryptedId}&search=${searchTerm}&categoryId=${categoryId}&page=${page}`
    );
  };

  useEffect(() => {
    if (encryptedId || categoryId) getDomainCookiesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, encryptedId]);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getDomainCookiesData();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getDomainCookiesData();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    setCategories(response);
  }, [response]);

  return (
    <>
      <div className="mt-6">
        <div className="flex mt-6 mb-6">
          <SectionTitle noMargin>Cookie</SectionTitle>
          <div className="relative group inline-block ml-2">
            <Tooltip
              text="All cookies detected on your website are listed under the category they belong to. The cookies can be edited or deleted as needed."
              innerClassName="mb-0 w-96 pb-4"
              className="text-sm text-primary-secText w-7"
              tooltipPosition={TooltipPosition.underTooltip}
              arrowPosition={ArrowPosition.top}
              // bottom="bottom-0"
              >
              <Icon name="Info" width={7} />
            </Tooltip>
          </div>
        </div>

        <Paragraph parahraphSize={ParagraphSize.sm}>
          Below is a list of cookies detected on your web site along with
          detailed information about each of them. You can move them between
          categories add description to each cookie that will be shown in your
          auto generated cookie declaration
        </Paragraph>

        <SearchHeader
          searchValue={searchTerm}
          onSearchKeypress={handleKeyPress}
          onClearInputBtn={handleClearInputBtn}
          onSearchBtnClick={handleSearchBtnClick}
          onSearchChange={handleSearch}
          onButtonClick={toggle}
          buttonLabel="Add Cookie"
          className="mb-6 mt-6"
        />

        <div>
          {categories?.map(
            ({ categoryName, cookies }: IDomainCategory, index: number) => (
              <CookiesTable
                sectionName={categoryName}
                className="mb-8"
                domainId={encryptedId}
                cookies={cookies}
                categories={categories}
                setCategories={setCategories}
                key={index}
                setCategoryId={setCategoryId}
                setPage={setPage}
              />
            )
          )}
        </div>
      </div>

      {visible && (
        <CreateCookieModal
          visible={visible}
          toggle={toggle}
          categories={categories || []}
          setCategories={setCategories}
        />
      )}
    </>
  );
};
