import { FC, useEffect } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTemplateContext } from "../../../../context/TemplateContext";

const privacyPolicy = "Privacy Policy";
const cookiePolicy = "Cookie Policy";
const allowAllButton = "Allow All";
const settingsButton = "Settings";
const bannerMessage =
  "We and our partners use cookies, tags and other tracking technologies to customise advertisements, analyse how the site is used, enhance the site’s personalisation and perform some other necessary functions.";

export const BannerCategories: FC<any> = ({ watch, width }) => {
  const { preview, bannerType, setBannerType } = useTemplateContext();

  return (
    <div
      className={`CATEGORIES ${bannerType === "categories" ? "" : "hidden"}`}>
      <div
        className={`absolute ${
          preview !== "desktop" ? "" : "max-w-80 w-max"
        } top-1/2 left-1/2 -translate-x-1/2 transform -translate-y-1/2 overflow-hidden border shadow-configCard rounded-lg bg-white h-5/6 consent-banner-wrapper z-10 flex flex-col justify-center`}
        style={preview !== "desktop" ? { width: width } : {}}>
        <div className="control-section z-50 sticky top-0 px-6 py-4 flex justify-between items-center ">
          <div className="w-1/6">
            <img
              src="	https://accounts-admin.gjirafa.dev/images/porta-logo.svg"
              alt="Consent Logo"
            />
          </div>
          <div className="control-section p-2 absolute top-0 right-0  flex ">
            <span
              aria-label="dismiss cookie message"
              className={`cursor-pointer ${
                !watch("showLangGlobe") && "hidden"
              }`}
              role="button"
              tab-index="0">
              <Icon name="Globe" size={20} />
            </span>
            <span
              className={`cursor-pointer ${
                !watch("showCloseButton") && "hidden"
              }`}
              onClick={() => setBannerType("widget")}
              aria-label="dismiss cookie message"
              role="button"
              tab-index="0">
              <Icon name="Close" size={20} />
            </span>
          </div>
        </div>
        <div className="tabs flex justify-start px-6  border-b border-gray-light">
          <button
            type="button"
            className="tab active pointer relative pb-4 mr-6 text-blue-secText  font-medium text-base hover:text-primary-mainText"
            data-tab-target="#cookie-display">
            Categories
          </button>
          <button
            type="button"
            className="tab pointer relative pb-4 mr-6 text-blue-secText font-medium text-base active:text-primary-mainText hover:text-primary-mainText"
            id="cookies-tab"
            data-tab-target="#cookie-settings">
            Cookies
          </button>
        </div>
        <div className="content-wrapper h-4/5  px-1 sm:px-6 overflow-y-auto my-3">
          <div className="description-section py-3">
            <p className="pb-2 text-primary-hover text-xs font-medium  leading">
              We and our partners use cookies, tags and other tracking
              technologies to customise advertisements, analyse how the site is
              used, enhance the site’s personalisation and perform some other
              necessary functions. More information about each cookie category
              can be found qbelow. Cookies under the “Necessary” category are
              essential for the site to function and cannot be turned off.
              However you can choose to give or withdraw consent by enabling /
              disabling all other categories below. Please be aware that
              disabling some of these categories may affect your browsing
              experience.
            </p>
            <div className="flex">
              <span
                id="cookieconsent:desc"
                className="text-primary-hover cursor-pointer text-xs mr-1 font-medium border-b border-primary-hover">
                Privacy Policy
              </span>
              <span
                id="cookieconsent:desc"
                className="text-primary-hover cursor-pointer text-xs font-medium border-b border-primary-hover">
                Cookie Policy
              </span>
            </div>
          </div>
          <div className="tab-content">
            <div
              id="cookie-display"
              className="tab-content active"
              data-tab-content>
              <ul id="basic-categories-banner" className=" cc-categories ">
                <li
                  id="test-5"
                  className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                  <div
                    className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                    data-did="135">
                    <div className="flex items-end justify-between">
                      <p className=" category-title font-bold text-black-faded">
                        Necessary
                      </p>
                      <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                        <input
                          type="checkbox"
                          id="5"
                          checkedta-radio-parent-category-name="Necessary"
                          className="category-radio-button "
                          name="Necessary"
                          value="necessary"
                          checked
                          disabled
                        />
                        <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                          <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                        </div>
                      </button>
                    </div>
                    <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                      The necessary cookies are needed for the website to
                      function properly and cannot be switched off. Some of the
                      basic features that are enabled through these cookies are
                      logging in, filling in forms, or changing your consent
                      settings.
                    </p>
                    <button
                      value="5"
                      data-settings-details-id="5"
                      className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                      data-category-id="5">
                      <span>Show Cookies</span>{" "}
                      <img
                        src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                        className="toggle-accordion  transition duration-300 ease-in-out transform"
                        alt="show cookies chevron"
                      />
                    </button>
                  </div>
                </li>
                <li
                  id="test-4"
                  className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                  <div
                    className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                    data-did="135">
                    <div className="flex items-end justify-between">
                      <p className=" category-title font-bold text-black-faded">
                        Preferences
                      </p>
                      <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                        <input
                          type="checkbox"
                          id="4"
                          data-radio-parent-category-name="Preferences"
                          className="category-radio-button "
                          name="Preferences"
                          value="preferences"
                        />
                        <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                          <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                        </div>
                      </button>
                    </div>
                    <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                      The preference cookies enhance the website’s
                      personalisation based on the user’s preferences. They are
                      set by us or our third-party service providers.
                    </p>
                    <button
                      value="4"
                      data-settings-details-id="4"
                      className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                      data-category-id="4">
                      <span>Show Cookies</span>{" "}
                      <img
                        src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                        className="toggle-accordion  transition duration-300 ease-in-out transform"
                        alt="show cookies chevron"
                      />
                    </button>
                  </div>
                </li>
                <li
                  id="test-3"
                  className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                  <div
                    className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                    data-did="135">
                    <div className="flex items-end justify-between">
                      <p className=" category-title font-bold text-black-faded">
                        Analytical
                      </p>
                      <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                        <input
                          type="checkbox"
                          id="3"
                          data-radio-parent-category-name="Analytical"
                          className="category-radio-button "
                          name="Analytical"
                          value="analytical"
                        />
                        <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                          <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                        </div>
                      </button>
                    </div>
                    <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                      The analytics cookies help us understand and improve the
                      performance of the site by tracking the user’s behavior to
                      report on the website’s usage. Some information on metrics
                      provided by these cookies is the number of visitors, and
                      the most and least popular pages.
                    </p>
                    <button
                      value="3"
                      data-settings-details-id="3"
                      className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                      data-category-id="3">
                      <span>Show Cookies</span>{" "}
                      <img
                        src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                        className="toggle-accordion  transition duration-300 ease-in-out transform"
                        alt="show cookies chevron"
                      />
                    </button>
                  </div>
                </li>
                <li
                  id="test-2"
                  className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                  <div
                    className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                    data-did="135">
                    <div className="flex items-end justify-between">
                      <p className=" category-title font-bold text-black-faded">
                        Marketing
                      </p>
                      <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                        <input
                          type="checkbox"
                          id="2"
                          data-radio-parent-category-name="Marketing"
                          className="category-radio-button "
                          name="Marketing"
                          value="marketing"
                        />
                        <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                          <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                        </div>
                      </button>
                    </div>
                    <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                      The targeting cookies are used by us and third parties
                      that represent our advertising partners. These cookies
                      provide our site visitors with custom advertising based on
                      their interests such as the pages they have visited. If
                      not allowed, the site visitor will not be shown
                      advertising tailored to their interests.
                    </p>
                    <button
                      value="2"
                      data-settings-details-id="2"
                      className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                      data-category-id="2">
                      <span>Show Cookies</span>{" "}
                      <img
                        src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                        className="toggle-accordion  transition duration-300 ease-in-out transform"
                        alt="show cookies chevron"
                      />
                    </button>
                  </div>
                </li>
                <li
                  id="test-1"
                  className="cc-category flex-col  my-0.5 xl:my-2 rounded-md">
                  <div
                    className="accordion-header text-xs leading w-full flex flex-col justify-between py-3"
                    data-did="135">
                    <div className="flex items-end justify-between">
                      <p className=" category-title font-bold text-black-faded">
                        Other
                      </p>
                      <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-not-allowed consentButton ">
                        <input
                          type="checkbox"
                          id="1"
                          data-ra-parent-category-name="Other"
                          className="category-radio-button "
                          name="Other"
                          value="other"
                        />
                        <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                          <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                        </div>
                      </button>
                    </div>
                    <p className=" category-description w-4/5 text-black-faded text-xs transition duration-200 ease-in-out transform">
                      The other cookies are cookies that have not been
                      classified into a category yet.
                    </p>
                    <button
                      value="1"
                      data-settings-details-id="1"
                      className="cookie-details max-w-max flex items-center text-blue font-medium leading"
                      data-category-id="1">
                      <span>Show Cookies</span>{" "}
                      <img
                        src="https://tojnhu4mvp.gjirafa.net/CMP/chevron-fill-down.svg"
                        className="toggle-accordion  transition duration-300 ease-in-out transform"
                        alt="show cookies chevron"
                      />
                    </button>
                  </div>
                </li>
              </ul>
            </div>

            <div
              id="cookie-settings"
              className="tab-content py-6"
              data-tab-content>
              <div id="custom-categories-banner" className=" "></div>
            </div>
          </div>
        </div>
        <div className=" bottom-0 border-gray-200 border-t buttons flex justify-start left-0 px-6 py-6 w-full z-50">
          <button
            className="allow-all-button border text-xs border-primary rounded-sm text-primary-mainText bg-white px-4 py-1.5  ml-2"
            // onClick={handleBannerButtonClick}
            onClick={() => setBannerType("widget")}>
            Confirm My Choices
          </button>
          <button
            type="button"
            className="border border-blue text-xs rounded-sm text-white bg-blue px-4 py-1.5  ml-2"
            // onClick={handleBannerButtonClick}
            onClick={() => setBannerType("widget")}>
            Accept All Cookies
          </button>
        </div>
      </div>
    </div>
  );
};

// ? BANNER CENTER
export const BannerCenter: FC<any> = ({ watch }) => {
  const { preview, bannerType, setBannerType } = useTemplateContext();
  useEffect(() => {
    const subscription = watch((data: any) => {
      // setTheme(data);
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`INFO banner-center ${
        preview !== "desktop" ? "w-full" : "w-1/2"
      } ${
        bannerType === "info" ? "" : "hidden"
      } absolute top-1/2 left-1/2 transform transition-width -translate-x-1/2 -translate-y-1/2  layout-item flex p-4`}>
      <div
        id="cmp"
        className="cmp-container banner-wrapper flex flex-col  justify-between mx-auto  bg-white relative rounded-md shadow-configCard px-6 py-4">
        <div className=" cmp-banner-description  mb-9 md:mb-0 leading-4 ">
          <p className="text-base font-bold text-primary-mainText mb-3">
            {/* {bannerName} */}
            {watch("bannerName")}
          </p>
          <div className="control-section p-2 absolute top-0 right-0  flex ">
            <span
              aria-label="dismiss cookie message"
              className={`cursor-pointer ${
                !watch("showLangGlobe") && "hidden"
              }`}
              // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
              role="button"
              tab-index="0">
              <Icon name="Globe" size={20} />
            </span>
            <span
              className={`cursor-pointer ${
                !watch("showCloseButton") && "hidden"
              }`}
              onClick={() => setBannerType("widget")}
              aria-label="dismiss cookie message"
              role="button"
              tab-index="0">
              <Icon name="Close" size={20} />
            </span>
          </div>
          <div className="font-normal text-black text-2xs">
            <span className="">{bannerMessage}</span>
            <div className="inline-block" id="cookieconsent:desc">
              <a
                className="text-blue-300 border-b break-word border-blue-300 mx-0"
                aria-label="learn more about cookies"
                role="button"
                tab-index="0"
                href="{{href}}"
                rel="noopener noreferrer nofollow"
                target="{{target}}">
                {privacyPolicy}
              </a>
              <a
                className="text-blue-300 border-b break-word border-blue-300 mx-1"
                aria-label="learn more about cookies"
                role="button"
                tab-index="0"
                href="{{href}}"
                rel="noopener noreferrer nofollow"
                target="{{target}}">
                {cookiePolicy}
              </a>
            </div>
          </div>
          <div
            className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
            // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
          >
            <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
              <input
                type="checkbox"
                id="dnsmd"
                className="category-radio-button"
                name="do-not-sell-my-data"
                value=""
              />
              <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
              </div>
              <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                Do not sell my personal information
              </p>
            </button>
          </div>
        </div>
        <div className="banner-wrapper-controls  flex flex-col justify-between mt-6 text-sm leading-4 ">
          <button
            className="border border-blue bg-blue text-white rounded-sm px-3 mb-2 py-2"
            id="accept-all-cookies-at-once"
            onClick={() => setBannerType("widget")}>
            {allowAllButton}
          </button>
          <button
            className=" border border-blue bg-white text-blue-300 rounded-sm px-3 py-2 "
            id="accept-cookies"
            // onClick={() => setSwitchBannerType((prev: boolean) => !prev)}>
            onClick={() => setBannerType("categories")}>
            {settingsButton}
          </button>
        </div>
      </div>
    </div>
  );
};

// ? BANNER RIGHT
export const BannerRight: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  return (
    <div
      className={`absolute  ${bannerType === "info" ? "" : "hidden"} ${
        preview !== "desktop" ? "w-full" : "w-1/2"
      } h-auto bottom-3 top-3 right-0 layout-item flex p-4`}>
      <div className="cc-compliance transform transition-all bg-white relative w-full rounded-md shadow-configCard p-6">
        <div className="control-section p-2 absolute top-0 right-0  flex ">
          <span
            aria-label="dismiss cookie message"
            className={`cursor-pointer ${!watch("showLangGlobe") && "hidden"}`}
            // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
            role="button"
            tab-index="0">
            <Icon name="Globe" size={20} />
          </span>
          <span
            className={`cursor-pointer ${
              !watch("showCloseButton") && "hidden"
            }`}
            // className={`cursor-pointer ${!showCloseButton && "hidden"}`}
            onClick={() => setBannerType("widget")}
            aria-label="dismiss cookie message"
            role="button"
            tab-index="0">
            <Icon name="Close" size={20} />
          </span>
        </div>
        <div
          id="cmp"
          className="cmp-container transform banner-wrapper h-full flex flex-col justify-between	max-w-7xl mx-auto">
          <div className=" cmp-banner-description w-full mb-9 md:mb-0 leading-4 ">
            <p className="text-base font-bold text-primary-mainText mb-3">
              {/* {bannerName} */}
              {watch("bannerName")}
            </p>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0 md:mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div className="banner-wrapper-controls w-full flex flex-col text-sm ">
            <button
              className="border border-blue bg-blue text-white leading-4 rounded-sm px-3 mb-3 py-2"
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className="border border-blue bg-white text-blue-400 leading-4 rounded-sm px-3 py-2 "
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
// ? BANNER LEFT
export const BannerLeft: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  return (
    <div
      className={`absolute ${bannerType === "info" ? "" : "hidden"}  ${
        preview !== "desktop" ? "w-full" : "w-1/2"
      } h-auto bottom-3 top-3 left-0 layout-item flex p-4`}>
      <div className="cc-compliance transform transition-all bg-white relative  rounded-md shadow-configCard p-6">
        <div className="control-section p-2 absolute top-0 right-0  flex ">
          <span
            aria-label="dismiss cookie message"
            className={`cursor-pointer ${!watch("showLangGlobe") && "hidden"}`}
            // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
            role="button"
            tab-index="0">
            <Icon name="Globe" size={20} />
          </span>
          <span
            className={`cursor-pointer ${
              !watch("showCloseButton") && "hidden"
            }`}
            onClick={() => setBannerType("widget")}
            aria-label="dismiss cookie message"
            role="button"
            tab-index="0">
            <Icon name="Close" size={20} />
          </span>
        </div>
        <div
          id="cmp"
          className="cmp-container transform banner-wrapper h-full flex flex-col justify-between	max-w-7xl mx-auto">
          <div className=" cmp-banner-description w-full mb-9 md:mb-0 leading-4 ">
            <p className="text-base font-bold text-primary-mainText mb-3">
              {/* {bannerName} */}
              {watch("bannerName")}
            </p>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0 md:mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div className="banner-wrapper-controls w-full flex flex-col text-sm ">
            <button
              className="border border-blue bg-blue text-white leading-4 rounded-sm px-3 mb-3 py-2"
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className="border border-blue bg-white text-blue-400 leading-4 rounded-sm px-3 py-2 "
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// ? BANNER BOTTOM (DEFAULT)
export const BannerBottom: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();

  return (
    <div
      className={`banner-bottom ${bannerType === "info" ? "" : "hidden"}  ${
        preview !== "desktop" ? "flex-col" : "flex-row"
      } absolute bottom-3 transform transition-width w-full layout-item flex p-4`}>
      <div className="cc-compliance bg-white relative w-full rounded-md shadow-configCard p-6">
        <div className="control-section p-2 absolute top-0 right-0  flex ">
          <span
            aria-label="dismiss cookie message"
            className={`cursor-pointer ${!watch("showLangGlobe") && "hidden"}`}
            // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
            role="button"
            tab-index="0">
            <Icon name="Globe" size={20} />
          </span>
          <span
            className={`cursor-pointer ${
              !watch("showCloseButton") && "hidden"
            }`}
            onClick={() => setBannerType("widget")}
            aria-label="dismiss cookie message"
            role="button"
            tab-index="0">
            <Icon name="Close" size={20} />
          </span>
        </div>
        <div
          id="cmp"
          className={`cmp-container  transform  banner-wrapper flex ${
            preview !== "desktop" ? "flex-col" : "flex-row"
          } justify-between	max-w-7xl mx-auto`}>
          <div
            className={`cmp-banner-description ${
              preview !== "desktop" ? "w-full" : "w-2/3"
            } mb-9 md:mb-0 leading-4`}>
            <p className="text-base font-bold text-primary-mainText mb-3">
              {/* {bannerName} */}
              {watch("bannerName")}
            </p>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0 md:mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div
            className={`banner-wrapper-controls ${
              preview !== "desktop" ? "w-full flex-col" : "w-1/3"
            } flex items-end justify-center md:justify-end text-sm `}>
            <button
              className={`${
                preview !== "desktop" ? "w-full mt-3 mb-2" : "mr-2"
              } border border-blue bg-blue text-white leading-4 rounded-sm px-3  py-2`}
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className={`${
                preview !== "desktop" ? "w-full " : ""
              }border border-blue bg-white text-blue-400 leading-4 rounded-sm px-3 py-2 `}
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// ? BANNER TOP
export const BannerTop: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  return (
    <div
      className={`${bannerType === "info" ? "" : "hidden"} ${
        preview !== "desktop" ? "flex-col" : "flex-row"
      }  absolute top-3 w-full layout-item flex p-4`}>
      <div className="cc-compliance transform transition-all bg-white relative w-full rounded-md shadow-configCard p-6">
        <div className="control-section p-2 absolute top-0 right-0  flex ">
          <span
            aria-label="dismiss cookie message"
            className={`cursor-pointer ${!watch("showLangGlobe") && "hidden"}`}
            // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
            role="button"
            tab-index="0">
            <Icon name="Globe" size={20} />
          </span>
          <span
            className={`cursor-pointer ${
              !watch("showCloseButton") && "hidden"
            }`}
            onClick={() => setBannerType("widget")}
            aria-label="dismiss cookie message"
            role="button"
            tab-index="0">
            <Icon name="Close" size={20} />
          </span>
        </div>
        <div
          id="cmp"
          className={`cmp-container  transform  banner-wrapper flex ${
            preview !== "desktop" ? "flex-col" : "flex-row"
          } justify-between	max-w-7xl mx-auto`}>
          <div
            className={`cmp-banner-description ${
              preview !== "desktop" ? "w-full" : "w-2/3"
            } mb-9 md:mb-0 leading-4`}>
            <p className="text-base font-bold text-primary-mainText mb-3">
              {/* {bannerName} */}
              {watch("bannerName")}
            </p>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0 md:mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div
            className={`banner-wrapper-controls ${
              preview !== "desktop" ? "w-full flex-col" : "w-1/3"
            } flex items-end justify-center md:justify-end text-sm `}>
            <button
              className={`${
                preview !== "desktop" ? "w-full mt-3 mb-2" : "mr-2"
              } border border-blue bg-blue text-white leading-4 rounded-sm px-3  py-2`}
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className={`${
                preview !== "desktop" ? "w-full " : ""
              }border border-blue bg-white text-blue-400 leading-4 rounded-sm px-3 py-2 `}
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// ? BANNER TOP RIGHT
export const BannerTopRight: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  return (
    <div
      className={`absolute top-3 ${bannerType === "info" ? "" : "hidden"} ${
        preview !== "desktop" ? "w-full right-0" : "w-1/2 right-3"
      }layout-item flex p-4`}>
      <div className="cc-compliance transform transition-all bg-white relative w-full rounded-md shadow-configCard px-6 py-4">
        <div
          id="cmp"
          className="cmp-container  transform  banner-wrapper flex flex-col  justify-between	max-w-7xl mx-auto">
          <div className=" cmp-banner-description w-full mb-9 md:mb-0 leading-4 ">
            <div className="flex justify-between items-center mb-6">
              <p className="text-sm font-bold text-primary-mainText">
                {/* {bannerName} */}
                {watch("bannerName")}
              </p>
              <div className="control-section p-2 absolute top-0 right-0  flex ">
                <span
                  aria-label="dismiss cookie message"
                  className={`cursor-pointer ${
                    !watch("showLangGlobe") && "hidden"
                  }`}
                  // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
                  role="button"
                  tab-index="0">
                  <Icon name="Globe" size={20} />
                </span>
                <span
                  className={`cursor-pointer ${
                    !watch("showCloseButton") && "hidden"
                  }`}
                  onClick={() => setBannerType("widget")}
                  aria-label="dismiss cookie message"
                  role="button"
                  tab-index="0">
                  <Icon name="Close" size={20} />
                </span>
              </div>
            </div>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div className="banner-wrapper-controls w-full flex flex-col justify-between mt-6 text-sm leading-4 ">
            <button
              className="border border-blue bg-blue text-white rounded-sm px-3 mb-2 py-2"
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className=" border border-blue bg-white text-blue-300 rounded-sm px-3 py-2 "
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// ? BANNER TOP LEFT
export const BannerTopLeft: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  return (
    <div
      className={`absolute top-3 ${bannerType === "info" ? "" : "hidden"} ${
        preview !== "desktop" ? "w-full left-0" : "w-1/2 left-3"
      } layout-item flex p-4`}>
      <div className="cc-compliance transform transition-all bg-white relative w-full rounded-md shadow-configCard px-6 py-4">
        <div
          id="cmp"
          className="cmp-container  transform  banner-wrapper flex flex-col  justify-between	max-w-7xl mx-auto">
          <div className=" cmp-banner-description w-full mb-9 md:mb-0 leading-4 ">
            <div className="flex justify-between items-center mb-6">
              <p className="text-sm font-bold text-primary-mainText">
                {/* {bannerName} */}
                {watch("bannerName")}
              </p>
              <div className="control-section p-2 absolute top-0 right-0  flex ">
                <span
                  aria-label="dismiss cookie message"
                  className={`cursor-pointer ${
                    !watch("showLangGlobe") && "hidden"
                  }`}
                  // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
                  role="button"
                  tab-index="0">
                  <Icon name="Globe" size={20} />
                </span>
                <span
                  className={`cursor-pointer ${
                    !watch("showCloseButton") && "hidden"
                  }`}
                  onClick={() => setBannerType("widget")}
                  aria-label="dismiss cookie message"
                  role="button"
                  tab-index="0">
                  <Icon name="Close" size={20} />
                </span>
              </div>
            </div>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div className="banner-wrapper-controls w-full flex flex-col justify-between mt-6 text-sm leading-4 ">
            <button
              className="border border-blue bg-blue text-white rounded-sm px-3 mb-2 py-2"
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className=" border border-blue bg-white text-blue-300 rounded-sm px-3 py-2 "
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// ? BANNER BOTTOM LEFT
export const BannerBottomLeft: FC<any> = ({ theme, watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  const { showDNSMD } = theme;
  return (
    <div
      className={`absolute bottom-3 ${bannerType === "info" ? "" : "hidden"} ${
        preview !== "desktop" ? "w-full left-0" : "w-1/2 left-3"
      } layout-item flex p-4`}>
      {/* <div className="cc-compliance "> */}
      <div
        id="cmp"
        className="cmp-container transform transition-all bg-white relative w-full rounded-md shadow-configCard px-6 py-4 banner-wrapper flex flex-col  justify-between	max-w-7xl mx-auto">
        <div className=" cmp-banner-description w-full mb-9 md:mb-0 leading-4 ">
          <div className="flex justify-between items-center mb-6">
            <p className="text-sm font-bold text-primary-mainText">
              {/* {bannerName} */}
              {watch("bannerName")}
            </p>
            <div className="control-section p-2 absolute top-0 right-0  flex ">
              <span
                aria-label="dismiss cookie message"
                className={`cursor-pointer ${
                  !watch("showLangGlobe") && "hidden"
                }`}
                // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
                role="button"
                tab-index="0">
                <Icon name="Globe" size={20} />
              </span>
              <span
                className={`cursor-pointer ${
                  !watch("showCloseButton") && "hidden"
                }`}
                onClick={() => setBannerType("widget")}
                aria-label="dismiss cookie message"
                role="button"
                tab-index="0">
                <Icon name="Close" size={20} />
              </span>
            </div>
          </div>
          <div className="font-normal text-black text-2xs">
            <span className="">{bannerMessage}</span>
            <div className="inline-block" id="cookieconsent:desc">
              <a
                className="text-blue-300 border-b break-word border-blue-300 mx-0"
                aria-label="learn more about cookies"
                role="button"
                tab-index="0"
                href="{{href}}"
                rel="noopener noreferrer nofollow"
                target="{{target}}">
                {privacyPolicy}
              </a>
              <a
                className="text-blue-300 border-b break-word border-blue-300 mx-1"
                aria-label="learn more about cookies"
                role="button"
                tab-index="0"
                href="{{href}}"
                rel="noopener noreferrer nofollow"
                target="{{target}}">
                {cookiePolicy}
              </a>
            </div>
          </div>
          <div
            className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
            // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
          >
            <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
              <input
                type="checkbox"
                id="dnsmd"
                className="category-radio-button"
                name="do-not-sell-my-data"
                value={showDNSMD}
              />
              <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
              </div>
              <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                Do not sell my personal information
              </p>
            </button>
          </div>
        </div>
        <div className="banner-wrapper-controls w-full flex flex-col justify-between mt-6 text-sm leading-4 ">
          <button
            className="border border-blue bg-blue text-white rounded-sm px-3 mb-2 py-2"
            id="accept-all-cookies-at-once"
            onClick={() => setBannerType("widget")}>
            {allowAllButton}
          </button>
          <button
            className=" border border-blue bg-white text-blue-300 rounded-sm px-3 py-2 "
            id="accept-cookies"
            onClick={() => setBannerType("categories")}>
            {settingsButton}
          </button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

// ? BANNER BOTTOM RIGHT
export const BannerBottomRight: FC<any> = ({ watch }) => {
  const { bannerType, preview, setBannerType } = useTemplateContext();
  // const { showDNSMD } = theme;
  return (
    <div
      className={`absolute bottom-3 ${bannerType === "info" ? "" : "hidden"} ${
        preview !== "desktop" ? "w-full right-0" : "w-1/2 right-3"
      } layout-item flex p-4`}>
      <div className="cc-compliance transform transition-all bg-white relative w-full rounded-md shadow-configCard px-6 py-4">
        <div
          id="cmp"
          className="cmp-container  transform  banner-wrapper flex flex-col  justify-between	max-w-7xl mx-auto">
          <div className=" cmp-banner-description w-full mb-9 md:mb-0 leading-4 ">
            <div className="flex justify-between items-center mb-6">
              <p className="text-sm font-bold text-primary-mainText">
                {/* {bannerName} */}
                {watch("bannerName")}
              </p>
              <div className="control-section p-2 absolute top-0 right-0  flex ">
                <span
                  aria-label="dismiss cookie message"
                  className={`cursor-pointer ${
                    !watch("showLangGlobe") && "hidden"
                  }`}
                  // className={`cursor-pointer ${!showLanguagesIcon && "hidden"}`}
                  role="button"
                  tab-index="0">
                  <Icon name="Globe" size={20} />
                </span>
                <span
                  className={`cursor-pointer ${
                    !watch("showCloseButton") && "hidden"
                  }`}
                  onClick={() => setBannerType("widget")}
                  aria-label="dismiss cookie message"
                  role="button"
                  tab-index="0">
                  <Icon name="Close" size={20} />
                </span>
              </div>
            </div>
            <div className="font-normal text-black text-2xs">
              <span className="">{bannerMessage}</span>
              <div className="inline-block" id="cookieconsent:desc">
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-0"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {privacyPolicy}
                </a>
                <a
                  className="text-blue-300 border-b break-word border-blue-300 mx-1"
                  aria-label="learn more about cookies"
                  role="button"
                  tab-index="0"
                  href="{{href}}"
                  rel="noopener noreferrer nofollow"
                  target="{{target}}">
                  {cookiePolicy}
                </a>
              </div>
            </div>
            <div
              className={`DNSMD ${!watch("showDNSMD") && "hidden"} mt-5`}
              // className={`DNSMD ${!showDNSMD && "hidden"} mt-5`}
            >
              <button className="w-auto group relative flex justify-start items-center theme-dot-wrapper  cursor-pointer consentButton ">
                <input
                  type="checkbox"
                  id="dnsmd"
                  className="category-radio-button"
                  name="do-not-sell-my-data"
                  value=""
                />
                <div className="theme-switch-holder relative block border border-gray  w-9 h-6 rounded-full transition ">
                  <div className="bg-gray dot absolute left-1 top-1/2 transform -translate-y-1/2 my-0 w-4 h-4 rounded-full transition "></div>
                </div>
                <p className=" category-title text-xs font-medium text-blue-mainText ml-2">
                  Do not sell my personal information
                </p>
              </button>
            </div>
          </div>
          <div className="banner-wrapper-controls w-full flex flex-col justify-between mt-6 text-sm leading-4 ">
            <button
              className="border border-blue bg-blue text-white rounded-sm px-3 mb-2 py-2"
              id="accept-all-cookies-at-once"
              onClick={() => setBannerType("widget")}>
              {allowAllButton}
            </button>
            <button
              className=" border border-blue bg-white text-blue-300 rounded-sm px-3 py-2 "
              id="accept-cookies"
              onClick={() => setBannerType("categories")}>
              {settingsButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
