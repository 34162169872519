import { FC } from 'react'
import {
  FormControl,
  Input,
  InputSize,
} from "../../../../components";
import {
  inputClasses,
} from "../../../../utils";
import { IOutput } from '../../interface';

interface IActionOutputDetails {
  outputs: IOutput[];
}

export const ActionOutputDetails : FC<IActionOutputDetails> = ({
  outputs,
}) => {

  return (
    <>
      {outputs?.map((output: IOutput, index: number) => {
        return (
          <div key={index} className="flex gap-4 mb-3">
          <FormControl className="w-1/2 ">
            <Input
              inputSize={InputSize.sm}
              className={inputClasses}
              value={output?.propertyName}
              readOnly
              disabled
            />
          </FormControl>
          <FormControl className='w-1/2'>
            <Input
              inputSize={InputSize.sm}
              className={inputClasses}
              value={output?.value}
              readOnly
              disabled
            />
          </FormControl>
          </div>
        )
      })
      }
    </>
  )
}
