import React, { InputHTMLAttributes, forwardRef, ReactNode } from "react";

import { cx } from "../../../utils";

export interface IRadioButton extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  wrapperClassName?: string;
  checked: boolean;
  content: ReactNode;
  register: any;
}

export const RadioButtonSection: React.FC<IRadioButton> = forwardRef(
  ({ id, wrapperClassName, checked, content, register, ...props }, ref) => {
    // console.log("checked", checked);
    return (
      <div
        className={cx([
          "flex items-center border relative transition rounded p-4 mb-4 flex-shrink-0 flex-1",
          checked
            ? "border-primary"
            : "border-primary-stroke hover:border-gray-400",
        ])}
      >
        <input
          id={id}
          type="radio"
          className="opacity-0 absolute left-0 top-0 w-full h-full"
          {...register}
          {...props}
        />
        <label
          htmlFor={id}
          className="flex items-center cursor-pointer radio-btn-label text-primary-mainText text-sm flex-1"
        >
          {content && content}
          <span className="w-5 h-5 inline-block rounded-full border flex-shrink-0 absolute top-4 right-4">
            <span className="w-2.5 h-2.5 inner-circle transition rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"></span>
          </span>
        </label>
      </div>
    );
  }
);
