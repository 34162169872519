import { FC, useState, useEffect } from "react";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { toast } from "react-toastify";

import {
  Card,
  CardSpace,
  CardStyle,
  FormFooter,
  Loader,
  Modal,
  Paragraph,
  ParagraphSize,
  SectionTitle,
  BtnSize,
  BtnType,
  Button,
} from "../../components";
import { ModalSize } from "../../components/modal";
import { useDomainContext } from "../../context";
import { CategoryItem, ManageCategoryModal } from "./components";

import { cx, primaryBtnClasses, SEO } from "../../utils";
import { useFetch, useToggle } from "../../hooks";
import { CMP_API_URL } from "../../constants";
import { ICookieCategory } from "../../interfaces";

export const DomainCategories: FC = () => {
  const { domain } = useDomainContext();
  const { toggle, visible } = useToggle();
  const { toggle: toggleDeleteModal, visible: visibleDeleteModal } =
    useToggle();

  const { encryptedId } = !!domain && domain;
  const [categories, setCategories] = useState<ICookieCategory[]>();
  const [category, setCategory] = useState<ICookieCategory>();
  const [fromEdit, setFromEdit] = useState<boolean>();

  const { apiCall: getCategoryList, loading } = useFetch("get");
  const { apiCall: editDefaultCategory, loading: editLoading } =
    useFetch("post");
  const { apiCall: deleteCategory, loading: deleteCategoryLoading } =
    useFetch("delete");

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      items: categories,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });

  useEffect(() => {
    SEO({
      title: "Porta - Domain Categories",
    });
  }, []);

  useEffect(() => {
    if (encryptedId)
      getCategoryList(
        `${CMP_API_URL}​/CategoryListView?domainId=${encryptedId}`,
        {},
        (response) => {
          setCategories(response);
        }
      );
  }, [reset, encryptedId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reset({
      items: categories,
    });
  }, [reset, categories]);

  const onDeleteCategory = () => {
    deleteCategory(
      `${CMP_API_URL}​/DeleteCategory?id=${category?.encryptedId}&domainId=${encryptedId}`,
      {},
      () => {
        var index = categories?.findIndex(
          (x: { id: number }) => x.id === category?.id
        );
        toggleDeleteModal();
        remove(index);
        toast.success("Category deleted successfully!");
      }
    );
  };

  const onEditDefaultCategory: SubmitHandler<any> = (data: {
    items: ICookieCategory[];
  }) => {
    data?.items.forEach((item: ICookieCategory) => {
      item.encryptedDomainId = encryptedId;
      item.checked = item.checked === true ? true : false;
      item.preconsent = item.preconsent === true ? true : false;
      item.usedForTracking = item.usedForTracking === true ? true : false;
      item.isEnabled = true;
    });

    editDefaultCategory(
      `${CMP_API_URL}​/EditDefaultCategory`,
      data?.items,
      () => {
        toast.success("Categories updated successfully!");
      }
    );
  };

  return (
    <>
      <div className="mt-6">
        <SectionTitle noMargin>Category Settings</SectionTitle>

        <Paragraph parahraphSize={ParagraphSize.sm}>
          On this page you can control which categories are shown in the Porta
          CMP consent and settings dialogs on your web site. Additionally you
          can control the default state of the checkbox and whether the
          do-not-track (DNT) indicator from the client browsers should be
          honoured.
        </Paragraph>

        <Button
          btnType={BtnType.primary}
          btnSize={BtnSize.normal}
          className={cx([
            "flex items-center whitespace-nowrap mr-4 mt-6",
            primaryBtnClasses,
          ])}
          onClick={() => {
            toggle();
            setFromEdit(false);
          }}>
          <span className="inline-block w-5 h-5">
            <Icon name="Plus" size="20" />
          </span>
          <span className="ml-2">Add Custom Category</span>
        </Button>

        {loading ? (
          <Loader />
        ) : (
          <form>
            <Card
              cardStyle={CardStyle.bordered}
              cardSpace={CardSpace.xl}
              className="mt-6 cookie-categories">
              {fields?.reverse()?.map((category: ICookieCategory, index) => {
                if (!category.isDeafult) {
                  return <></>;
                }

                return (
                  <CategoryItem
                    control={control}
                    cat={category}
                    index={index}
                    key={category.id}
                  />
                );
              })}
            </Card>

            <Card
              cardStyle={CardStyle.bordered}
              cardSpace={CardSpace.xl}
              className="mt-6 cookie-categories"
              id="non-default-categories">
              {fields.map((category: ICookieCategory, index) => {
                if (category.isDeafult) {
                  return <></>;
                }

                return (
                  <CategoryItem
                    control={control}
                    cat={category}
                    index={index}
                    key={category.id}
                    isCustom
                    onEditCategory={() => {
                      toggle();
                      setCategory(category);
                      setFromEdit(true);
                    }}
                    onDeleteCategory={() => {
                      toggleDeleteModal();
                      setCategory(category);
                    }}
                  />
                );
              })}
            </Card>

            <FormFooter
              onSubmit={handleSubmit(onEditDefaultCategory)}
              submitBtnType="submit"
              loading={editLoading}
            />
          </form>
        )}
      </div>

      {visible && (
        <ManageCategoryModal
          toggle={toggle}
          visible={visible}
          append={append}
          setCategories={setCategories}
          category={category}
          encryptedDomainId={encryptedId}
          fromEdit={fromEdit}
        />
      )}

      {visibleDeleteModal && (
        <Modal
          hide={toggleDeleteModal}
          visible={visibleDeleteModal}
          title="Delete category"
          onConfirmClick={onDeleteCategory}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={deleteCategoryLoading}
          blockOutsideClick>
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">{category?.name}</strong>?
          </p>
        </Modal>
      )}
    </>
  );
};
