import { useEffect, useState } from "react";
import { format, subDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  Card,
  CardSpace,
  CardStyle,
  Loader,
  PageHeadeline,
  Paragraph,
  ParagraphSize,
} from "../../components";
import { useFetch } from "../../hooks";
import { Chart, DateRange, Map, UsersInsightsWrapper } from "./components";
import { IGraphInsights, IAnalytics, IUsersByDays } from "./interface";
import { cx, SEO } from "../../utils";
import useOnclickOutside from "../../hooks/useOnclickOutside";

export const Dashboard: React.FC = () => {
  const [rendered, setRendered] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<IAnalytics>();
  const { ref, isVisible, setIsVisible } = useOnclickOutside(false);

  const [activeUsersInsights, setActiveUsersInsights] =
    useState<IGraphInsights[]>();
  const [registeredUsersInsights, setRegisteredUsersInsights] =
    useState<IGraphInsights[]>();

  const [rangeDates, setRangeDates] = useState<any>([
    {
      startDate: subDays(new Date(), 29),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { apiCall: getAnalytics, response, loading } = useFetch("get");
  // const { visible, toggle } = useToggle();
  const toggle = () => {
    setIsVisible(!isVisible);
  };

  const fitDateFormat = (date: Date) => {
    return format(date, "yyyy/MM/dd").replace(/[/]/g, "%2F");
  };

  const returnUsersInsights = (usersListInsgights: IUsersByDays[]) => {
    let usersArray: IGraphInsights[] = [];
    usersListInsgights?.map(({ date, users }) =>
      usersArray.push({
        name: format(new Date(date), "MMMM dd, yyyy"),
        uv: users,
      })
    );

    return usersArray;
  };

  const getAnalyticsFunc = (param?: string) => {
    getAnalytics(`dashboard/Stats${param}`, {}, () => {
      setRendered(false);
    });
  };

  useEffect(() => {
    SEO({
      title: "Porta - Dashboard",
    });

    getAnalyticsFunc("");
    setRendered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAnalytics(response);
  }, [response]);

  useEffect(() => {
    setActiveUsersInsights(
      returnUsersInsights(analytics?.activeUsersByDays || [])
    );
    setRegisteredUsersInsights(
      returnUsersInsights(analytics?.registeredUsersByDays || [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  const onDateRangeSelected = () => {
    toggle();
    getAnalyticsFunc(
      `?startDate=${fitDateFormat(
        rangeDates[0]?.startDate
      )}&endDate=${fitDateFormat(rangeDates[0]?.endDate)}`
    );
  };

  return (
    <div className="container mx-auto" ref={ref}>
      <PageHeadeline
        title="Home"
        description="The dashboard provides a summary of key data about your Porta account."
        className="mb-10"
      />
      <DateRange
        rangeDates={rangeDates}
        setRangeDates={setRangeDates}
        onDateRangeSelected={onDateRangeSelected}
        toggle={toggle}
        visible={isVisible}
      />

      {rendered && <Loader />}

      {!rendered && (
        <div className="relative">
          {loading && <Loader className="absolute left-0 w-full h-full z-10" />}

          <div className={(loading && "pointer-events-none opacity-50") || ""}>
            <div className="flex mt-10">
              <UsersInsightsWrapper
                title="Active Users"
                subtitle="Last period"
                wrapperClassName="mr-3"
                count={analytics?.activeUsers || 0}
                statusTagValue={analytics?.activeUsersPercentageComparison || 0}
              >
                <Chart
                  startGradientColor="rgba(29, 121, 242, 0.6)"
                  endGradientColor="rgba(29, 121, 242, 0)"
                  lineStrokeColor="#0088FE"
                  data={activeUsersInsights}
                  gradientId="blueGradient"
                  tootlipTitle="Active users"
                />
              </UsersInsightsWrapper>

              <UsersInsightsWrapper
                title="Registered Users"
                subtitle="Lifetime"
                wrapperClassName="ml-3"
                count={analytics?.registeredUsers || 0}
                statusTagValue={
                  analytics?.registeredUsersPercentageComparison || 0
                }
              >
                <Chart
                  startGradientColor="rgba(54, 179, 126, 0.6)"
                  endGradientColor="rgba(54, 179, 126, 0)"
                  lineStrokeColor="#36B37E"
                  data={registeredUsersInsights}
                  gradientId="greenGradient"
                  tootlipTitle="Registered users"
                />
              </UsersInsightsWrapper>
            </div>

            <div className="flex mt-6">
              <Card
                cardStyle={CardStyle.bordered}
                cardSpace={CardSpace.xl}
                className="w-3/5 mr-3"
              >
                <h6 className="font-bold">Map Overview</h6>
                <Paragraph
                  parahraphSize={ParagraphSize.sm}
                  noMargin
                  className="mb-3 mt-1"
                >
                  An overview of the Active Users by Country.
                </Paragraph>
                <Map data={analytics?.mapOverview || []} />
              </Card>

              <Card
                cardStyle={CardStyle.bordered}
                cardSpace={CardSpace.none}
                className="flex-1 ml-3"
              >
                <div className="sm:px-6 px-4 sm:pt-6 pt-4">
                  <h6 className="font-bold">Identity Provider Overview</h6>
                  <Paragraph
                    parahraphSize={ParagraphSize.sm}
                    noMargin
                    className="mb-3 mt-1"
                  >
                    An overview of logins via Identity Providers.
                  </Paragraph>
                </div>

                <div className="social-providers sm:px-6 px-4 sm:pb-6 pb-4">
                  {analytics?.socialProviders?.map(
                    (
                      { providerName, numberOfLogins, percentage, isEnabled },
                      index
                    ) => {
                      const lastChild =
                        index === analytics?.socialProviders?.length - 1;
                      return (
                        <div
                          className={cx([
                            "flex justify-between items-center py-3 border-primary-stroke group",
                            !lastChild && "border-b",
                            !isEnabled && "opacity-60",
                          ])}
                          key={index}
                        >
                          <div className="text-sm flex items-center text-primary-secText group-hover:text-primary">
                            <div className="mr-3 p-2.5 border rounded border-primary-stroke">
                              <img
                                src={`images/socials/${providerName.toLowerCase()}-logo.svg`}
                                width={20}
                                alt={`${providerName} logo`}
                              />
                            </div>
                            {providerName}
                          </div>

                          <div>
                            <p className="font-medium">
                              {numberOfLogins}
                              <span className="text-sm ml-1 text-primary-secText">
                                ({percentage?.toFixed(2)}%)
                              </span>
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
