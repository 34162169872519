import { KeyboardEvent, ChangeEvent, ReactNode } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { BtnSize, BtnType, Button } from "../../components";
import {
  Permission,
  Can,
  Switch as PermissionsSwitch,
} from "../../context/Permissions";

import Search from "../form/Search";
import { cx, primaryBtnClasses } from "../../utils";

interface ISearchHeader {
  className?: string;
  rightContentClassName?: string;
  nextSearchContent?: ReactNode;
  searchValue: string;
  onButtonClick: () => void;
  onSearchBtnClick?: () => void;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchKeypress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onClearInputBtn?: () => void;
  buttonLabel: string;
  permissions?: Permission[];
}

export const SearchHeader: React.FC<ISearchHeader> = (props) => {
  return (
    <div className={cx(["flex justify-between", props.className])}>
      <PermissionsSwitch>
        <Can permissions={props.permissions}>
          <Button
            btnType={BtnType.primary}
            btnSize={BtnSize.normal}
            className={cx([
              "flex items-center whitespace-nowrap mr-4",
              primaryBtnClasses,
            ])}
            onClick={props.onButtonClick}
          >
            <span className="w-5 h-5 inline-block">
              <Icon name="Plus" size="20" />
            </span>
            <span className="ml-2">{props.buttonLabel}</span>
          </Button>
        </Can>
      </PermissionsSwitch>
      <span>&nbsp;</span>

      <div className={cx([props.rightContentClassName])}>
        {props.nextSearchContent}
        <Search
          onChange={props.onSearchChange}
          onKeyDown={props.onSearchKeypress}
          onClearInputBtn={props.onClearInputBtn}
          value={props.searchValue}
          onSearchBtnClick={props.onSearchBtnClick}
          placeholder="Search..."
        />
      </div>
    </div>
  );
};
