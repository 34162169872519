import { ButtonHTMLAttributes } from "react";
// @ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface ConfigIconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  iconName: string;
  iconSize?: number;
  //   btnSize?: BtnWidth;
  text: string;
  fontSize?: string;
  rounded?: string;
  disabled?: boolean;
  onButtonClick?: () => void;
  style?:any
}

export const ConfigIconButton: React.FC<ConfigIconButtonProps> = ({
  className,
  iconName,
  //   btnSize,
  iconSize,
  fontSize,
  text,
  disabled,
  rounded,
  onButtonClick,
  ...props
}) => {
  return (
    <button
      type="button"
      onClick={onButtonClick}
      className={cx([
        `inline-flex items-center justify-center flex-1 p-1 w-auto ${
          rounded || ""
        } transition hover:text-blue-mainText hover:border-blue-tertText focus:outline-none group`,
        disabled && "opacity-60 pointer-events-none",
        className,
      ])}
      {...props}>
      <div className="max-w-full flex-shrink-0 group-hover:text-primary">
        <Icon name={iconName} size={iconSize || 17} />
      </div>
      <span className={fontSize || "text-sm"}>{text || ""}</span>
    </button>
  );
};
