import { forwardRef, SelectHTMLAttributes } from "react";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface Option {
  label: string;
  value: string;
}

interface ISelect extends SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  wrapperClassName?: string;
  className?: string;
  options: Option[];
  selectRef?: any;
}

export const Select: React.FC<ISelect> = forwardRef(
  (
    { placeholder, options, className, selectRef, wrapperClassName, ...props },
    ref
  ) => {
    return (
      <div className={cx(["relative", wrapperClassName])}>
        <select
          ref={selectRef}
          className={cx([
            "text-sm leading-4.5 min-w-full rounded focus:outline-none focus:border focus:border-primary border border-gray-300 py-2 px-3 appearance-none text-blue-mainText transition focus:shadow-primary",
            className,
          ])}
          {...props}
        >
          <option value="" disabled defaultValue="" hidden>
            {placeholder}
          </option>

          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-secText w-5 h-5">
          <Icon name="ArrowDown" size={20} />
        </div>
      </div>
    );
  }
);
