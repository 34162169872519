import { useEffect } from "react";
import { Properties } from "../../../components";
import { I_RES_PATH } from "../../../constants";
import { SEO } from "../../../utils";

export const IdentityRescourceProperties = () => {
  useEffect(() => {
    SEO({
      title: "Porta - Identity Resource Properties",
    });
  }, []);

  return (
    <Properties
      endpoint="/IdentityResources"
      mainTitle="Identity Resource Properties"
      backTo={I_RES_PATH}
    />
  );
};
