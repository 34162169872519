import { FC } from "react";

export const LayoutIcon: FC = () => {
  return (
    <span className="w-6 h-6">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="3" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 6.5C12.5 5.94772 12.9477 5.5 13.5 5.5H17.5C18.0523 5.5 18.5 5.94772 18.5 6.5V10.5C18.5 11.0523 18.0523 11.5 17.5 11.5H13.5C12.9477 11.5 12.5 11.0523 12.5 10.5V6.5Z"
          fill="#A3B0C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 13.5C12.5 12.9477 12.9477 12.5 13.5 12.5H17.5C18.0523 12.5 18.5 12.9477 18.5 13.5V17.5C18.5 18.0523 18.0523 18.5 17.5 18.5H13.5C12.9477 18.5 12.5 18.0523 12.5 17.5V13.5Z"
          fill="#A3B0C2"
        />
        <path
          d="M6 6.5C6 6.22386 6.22386 6 6.5 6H10.5C10.7761 6 11 6.22386 11 6.5V10.5C11 10.7761 10.7761 11 10.5 11H6.5C6.22386 11 6 10.7761 6 10.5V6.5Z"
          stroke="#1D79F2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 13.5C5.5 12.9477 5.94772 12.5 6.5 12.5H10.5C11.0523 12.5 11.5 12.9477 11.5 13.5V17.5C11.5 18.0523 11.0523 18.5 10.5 18.5H6.5C5.94772 18.5 5.5 18.0523 5.5 17.5V13.5Z"
          fill="#A3B0C2"
        />
      </svg>
    </span>
  );
};
