export interface IProperty {
    name: string;
    id: string;
    inputTypeId?: number;
    inputType: {
      name: string;
      id: number
    };
    designationType:  {
      name: string;
      id: number
    };
    isRequired: boolean;
    isDefault?: boolean;
}

export interface IModel {
  id?: number | undefined | string,
  name: string,
  modelType: string,
  alias: string,
  createdDate: string,
  isPublished: boolean,
  isDefault: boolean,
  order: number,
}

export interface IHeaders {
  key: string,
  value: string
}

export interface IVisibilitySettings {
  propertyName: string,
  operatorId: number,
  operator: {
    id: number;
    name: string;
  }
  property: {
    id: number;
    name: string;
  }
  propertyId?: string;
  value: string,
}

export interface IOutput  {
  property: {
    id: number;
    name: string;
  };
  propertyId?: string;
  propertyName: string,
  value: string,
}

export interface IActionTypeId {
  name: string,
  id: number
}

export interface IMethodType {
  label: string,
  value: string
}

export interface IExpectedStatusCode {
  label: string,
  value: number
}

export interface IResponseType {
  label: string,
  value: string
}

export interface IScheduleType {
  label: string,
  value: number
}

export interface IAction {
  name: string,
  methodType: IMethodType,
  url: string,
  headers: IHeaders[],
  visibilitySettings: IVisibilitySettings[],
  outputs: IOutput[],
  body: string,
  actionType: IActionTypeId,
  actionTypeId: number;
  redirectInNewTab: false,
  showVisibilitySettings: false,
  addActionOutput: false,
  errorMessage: string,
  expectedSuccessStatusCodes: IExpectedStatusCode[],
}

export interface ICreateModel {
  modelId: string,
  name: string,
  icon: string,
  image: string,
  description: string,
  alias: string,
  actions: IAction[],
  properties: IProperty[]
}

export interface IPipelineJob {
  id: number,
  getDataEndpoint: string
  responseType: IResponseType,
  propertyAccessor: string,
  headers: IHeaders[],
  scheduleId: IScheduleType,
  scheduleFrequency: IScheduleType,
}

export type templateTypes = "subscription" | "newsletter" | "comments" | "none";

// Initial States
export const propertyIState = {
    name: "",
    inputType: null,
    isRequired: false
  };


  export const propertiesIState = [
  {
    id: 0,
    name: "",
    inputType: {
      id: 2,
      name: "String",
    },
    inputTypeId: 2,
    isRequired: true,
  },
  {
    id: 1,
    name: "",
    inputType: null,
    inputTypeId: 0,
    isRequired: false,
  }]

  export const headersIState = {
    key: "",
    value: ""
  }
  
  export const visibilitySettingsIState =  {
    propertyName: "",
    operatorId: 1,
    value: "",
    property: null,
    operator: null
  }

  export const outputIState =  {
    propertyName: "",
    value: "",
    property: null
  }

  export const actionIState = {
    name: "",
    methodType: "",
    url: "",
    headers: [headersIState],
    visibilitySettings: [
      visibilitySettingsIState
    ],
    outputs: [outputIState],
    body: "",
    actionType: null,
    redirectInNewTab: false,
    showVisibilitySettings: false,
    addActionOutput: false,
    errorMessage: "",
    expectedSuccessStatusCodes: [],
  }

