import { FC, ReactNode } from "react";

import { Card, CardSpace, CardStyle } from "../../../components";
import { StatusTag } from ".";
import { cx, formatNumberCount } from "../../../utils";

interface IUsersInsightsWrapper {
  title: string;
  statusTagValue: number;
  subtitle: string;
  wrapperClassName?: string;
  count: number;
  children: ReactNode;
}

export const UsersInsightsWrapper: FC<IUsersInsightsWrapper> = ({
  children,
  title,
  statusTagValue,
  subtitle,
  count,
  wrapperClassName,
}) => {
  return (
    <Card
      cardSpace={CardSpace.xl}
      cardStyle={CardStyle.bordered}
      className={cx(["flex w-1/2 justify-between", wrapperClassName])}
    >
      <div>
        <p className="font-bold mb-1 text-black">{title}</p>
        <p className="text-sm font-medium text-primary-secText">{subtitle}</p>

        <h1 className="font-bold text-black mt-2 break-all">
          {formatNumberCount(count)}
        </h1>

        <StatusTag value={statusTagValue || 0} />
      </div>

      {children}
    </Card>
  );
};
