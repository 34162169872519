import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BackButton,
  Container,
  FormControl,
  FormWrapper,
  Label,
  Loader,
  Paragraph,
  ToggleSwitch,
} from "../../components";
import { MultiTagsCard } from "../../components/ui/MultiTagsCard";
import { ATTACK_PROTECTION_PATH } from "../../constants";
import { useFetch } from "../../hooks";
import { SEO } from "../../utils";
import { BlockedIpsList, HeadlineStatus } from "./components";

interface IPThrottling {
  suspiciousIpThrottling: boolean;
  throttleTrafficFromAnIpAddress: boolean;
  sendEmailNotificationsForSuspiciousIpThrottling: boolean;
  ipAddressAllowedDtos: string[];
}

export const SuspiciousIpThrottling: FC = () => {
  const navigate = useNavigate();

  const [ipAllowList, setApiAllowList] = useState<string[]>();
  const { handleSubmit, control, reset, watch } = useForm();
  const {
    apiCall: getSuspiciousIpThrottling,
    loading,
    response,
  } = useFetch("get");

  const { apiCall: editApiThrottlingSettings, loading: editLoading } =
    useFetch("post");

  useEffect(() => {
    SEO({
      title: "Porta - Suspicious IP Throttling",
    });

    getSuspiciousIpThrottling(`Users/SuspiciousIpThrottling`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setApiAllowList(response?.ipAddressesAllowed);
    reset(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const onEditApiThrottlingSettings: SubmitHandler<any> = (
    data: IPThrottling
  ) => {
    const ipThrottlingObj = {
      ...data,
      ipAddressesAllowed: ipAllowList,
    };

    editApiThrottlingSettings(
      `Users/EditSuspiciousIpThrottlingSettings`,
      ipThrottlingObj,
      () => {
        toast.success("IP throttling saved successfully!");
        navigate(ATTACK_PROTECTION_PATH);
      }
    );
  };

  const watchSuspiciousIpThrottling = watch("suspiciousIpThrottling");

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <BackButton
              to={ATTACK_PROTECTION_PATH}
              label="Back to Attack Protection"
              className="mb-4"
            >
              <></>
            </BackButton>

            <HeadlineStatus
              control={control}
              title="Suspicious IP Throttling"
              description="Protect your application against suspicious logins targeting too many accounts from a single IP address."
              toggleId="ip-throtling"
              toggleName="suspiciousIpThrottling"
              statusEnabled={watchSuspiciousIpThrottling}
            />
          </Container>

          <FormWrapper
            onSubmit={handleSubmit(onEditApiThrottlingSettings)}
            className="mt-6"
            loading={editLoading}
          >
            <h6 className="text-primary-mainText">Response</h6>
            <div className="my-6">
              <h6 className="text-sm text-primary-mainText font-medium">
                Block Suspicious Logins and IP AllowList
              </h6>
              <Paragraph>
                Define policies for limiting suspicious traffic and allowing
                traffic for specified IPs.
              </Paragraph>
            </div>

            <FormControl>
              <Controller
                control={control}
                name="throttleTrafficFromAnIpAddress"
                render={({ field: { onChange, value, ref } }: any) => (
                  <ToggleSwitch
                    id="limit-velocity"
                    onChange={onChange}
                    checked={value || false}
                    inputRef={ref}
                    label="Limit high-velocity traffic targeting too many accounts"
                    description="Throttle traffic from an IP address when there is a high number of login attempts targeting too many different accounts."
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                control={control}
                name="sendEmailNotificationsForSuspiciousIpThrottling"
                render={({ field: { onChange, value, ref } }: any) => (
                  <ToggleSwitch
                    id="send-email-notification"
                    onChange={onChange}
                    checked={value || false}
                    inputRef={ref}
                    label="Send notification to account administrator"
                    description="Send an email notification when traffic is throttled on one or more IP addresses due to high-velocity traffic."
                  />
                )}
              />
            </FormControl>

            <FormControl description="The IP AllowList gives you the ability to create a list of trusted IP addresses from which your users can access your resources. These IP addresses will not have attack protection enforced against them. This field allows you to specify multiple IP addresses, or ranges, by comma-separating them. You can use IPv4 or IPv6 addresses and CIDR notation">
              <Label text="IP AllowList" />
              <MultiTagsCard
                hasInput
                noSuggestedTags
                tags={ipAllowList || []}
                setTags={setApiAllowList}
              />
            </FormControl>

            <FormControl lastChild>
              <BlockedIpsList
                endpoint="Users/BlockedIpAddressesForSuspiciousIpThrottling"
                listOpened
              />
            </FormControl>
          </FormWrapper>
        </>
      )}
    </div>
  );
};
