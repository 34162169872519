import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ModelDetails, ModelEntries, Swagger } from "..";
import { Loader, Tab, Tabs } from "../../components";
import { MainContainer } from "../../components/layout/MainContainer";
import { DataImportDetails, ModelDetailsHeadline } from "./components";
import { useModelDetailsContext } from "./context/ModelDetailsContext";

export const DetailsView = () => {
  let { modelId } = useParams();
  const { setModelId, loading, model } = useModelDetailsContext();

  const routes = [
    { path: `details`, element: <ModelDetails />, index: true },
    // { path: `customization`, element: <Customization /> },
    {
      path: `swagger`,
      element: (
        <Swagger organizationId={model?.organizationId} alias={model?.alias} />
      ),
    },
    { path: `data-import-details`, element: <DataImportDetails /> },
    {
      path: "browse",
      element: <ModelEntries modelAlias={model?.alias} />,
    },
  ];

  useEffect(() => {
    modelId && setModelId(modelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <MainContainer>
      <ModelDetailsHeadline />

      {model && (
        <Tabs routes={routes} wrapperClassname="mt-8 sm:mt-0">
          <Tab path="details" label="Details" />
          {/* <Tab path="customization" label="Customization" /> */}
          <Tab path="swagger" label="Swagger" />
          <Tab path="data-import-details" label="Data Import" />
          <Tab path="browse" label="Entries" />
        </Tabs>
      )}
    </MainContainer>
  );
};
