import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  Card,
  CardSpace,
  CardStyle,
  FormControl,
  Label,
  ToggleSwitch,
  Input,
  InputSize,
} from "../../../../components";
import { MultiTagsCard } from "../../../../components/ui/MultiTagsCard";
import { IApplication, IRestriction } from "../../../../interfaces";
import { inputClasses } from "../../../../utils";
import { SocialRestrictionItem } from ".";

interface IAuthentication {
  logoutRedirects: string[];
  setLogoutRedirects: Dispatch<SetStateAction<string[]>>;
  restrictions: IRestriction[];
  setRestrictions: Dispatch<SetStateAction<IRestriction[]>>;
}

export const Authentication: FC<IAuthentication> = (props) => {
  const { register, control } = useFormContext<IApplication>();

  const handleRestrictionChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let restrictionsArray = !!props.restrictions && [...props.restrictions];

    restrictionsArray[index].checked = event.target.checked ? true : false;
    if (!event.target.checked) {
      restrictionsArray[index].checked = false;
    }

    props.setRestrictions(restrictionsArray);
  };

  return (
    <>
      <FormControl description="Specifies logout URI at client for HTTP based front-channel logout. See the OIDC Front-Channel spec for more details.">
        <Label text="Front Channel Logout URL" htmlFor="front-channel-url" />
        <Input
          id="front-channel-url"
          {...register("frontChannelLogoutUri")}
          inputSize={InputSize.sm}
          className={inputClasses}
        />
      </FormControl>

      <FormControl description="Specifies if the user’s session id should be sent to the FrontChannelLogoutUri. Defaults to true.">
        <Controller
          control={control}
          name="frontChannelLogoutSessionRequired"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="channel-logout-session"
              label="Front Channel Logout Session Required"
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl description="Specifies logout URI at client for HTTP based back-channel logout. OpenID Connect Backchannel logout is a mechanism by which Relying Party (RP) applications are logged out with logout requests communicated directly between RPs and Porta bypassing the User Agent.">
        <Label text="Back Channel Logout URL" htmlFor="back-channel-url" />
        <Input
          id="back-channel-url"
          {...register("backChannelLogoutUri")}
          className={inputClasses}
          inputSize={InputSize.sm}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="backChannelLogoutSessionRequired"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="back-channel-logout"
              label="Back Channel Logout Session Required"
              description="Specifies if the user’s session id should be sent in the request to the BackChannelLogoutUri. Defaults to true."
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="enableLocalLogin"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="enable-local-login"
              label="Enable Local Login"
              description="Specifies if this client can use local accounts, or external IdPs only. Defaults to true."
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl
        description={
          <>
            Specifies allowed URIs to redirect to after logout. After a user
            logs out from Porta you can redirect them with the
            <code>post_logout_redirect_uri</code> query parameter. The URL that
            you use in <code>post_logout_redirect_uri</code> must be listed
            here.
          </>
        }
      >
        <Label text="Post Logout URL's" />

        <MultiTagsCard
          tags={props.logoutRedirects}
          setTags={props.setLogoutRedirects}
          suggestedEndpoint=""
          noSuggestedTags
          hasInput
        />
      </FormControl>

      <FormControl>
        <Label text="Identity Provider Restrictions" />

        <Card cardSpace={CardSpace.none} cardStyle={CardStyle.bordered}>
          {props.restrictions?.map(({ icon, label, id, checked }, index) => {
            const lastItem = index === props.restrictions?.length - 1;
            return (
              <SocialRestrictionItem
                key={id}
                icon={icon}
                id={id}
                label={label}
                isChecked={checked}
                lastChild={lastItem}
                checked={checked}
                onChange={(event) => handleRestrictionChange(event, index)}
              />
            );
          })}
        </Card>
      </FormControl>

      <FormControl
        description="The maximum duration (in seconds) since the last time the user authenticated. Defaults to null."
        lastChild
      >
        <Label text="User SSO Lifetime" htmlFor="sso-lifetime" />
        <Input
          id="sso-lifetime"
          {...register("userSsoLifetime", { valueAsNumber: true })}
          className={inputClasses}
          inputSize={InputSize.sm}
          type="number"
        />
      </FormControl>
    </>
  );
};
