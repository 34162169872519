import { NavLink } from "react-router-dom";
import { ItemProps } from "../../components/sidebar/SidebarItem";
import { Sidebar as SidebarLayout } from "./index";

import {
  API_RES_PATH,
  API_SCOPES_PATH,
  APPS_PATH,
  ATTACK_PROTECTION_PATH,
  AUTHENTICATION_FLOW,
  AUTHENTICATION_PASSLESS,
  AUTHENTICATION_SETTINGS_PATH,
  BRANDINGS_PATH,
  DOMAINS_PATH,
  INTEGRATIONS_PATH,
  I_RES_PATH,
  MODELS_PATH,
  MULTI_FACTOR_AUTH_PATH,
  M_AUDIT_LOGS,
  M_ERROR_LOGS,
  SETTINGS_GENERAL_PATH,
  SETTINGS_LANGUAGES_PATH,
  SETTINGS_MEMBER_PATH,
  TEMPLATES_PATH,
  USERS_PATH,
} from "../../constants";
import { Permission, onlyForRoles } from "../../context/Permissions";
import { useToggle } from "../../hooks";
import { cx, premiumFeatureEnabled } from "../../utils";
import {
  Brush,
  ChatLeft,
  Home,
  Integrations,
  LockSecurity,
  Monitor,
  PasteClipboard,
  Security,
  Settings,
  User,
  Widgets,
} from "../Icons";
import { FeedbackModal } from "./FeedbackModal";

export const Sidebar = () => {
  const { toggle: feedbackModalToggle, visible: feedbackModalVisible } =
    useToggle();

  interface ISidebarOptions extends ItemProps {
    permissions?: Permission | Permission[] | any;
    nestedList?: any;
  }

  const { admin, appsEditor, usersEditor } = Permission;

  const sidebarOptions: ISidebarOptions[] = [
    {
      to: "/",
      title: "Home",
      icon: <Home />,
      permissions: [admin],
    },
    {
      title: "Clients",
      icon: <Widgets />,
      nestedList: [
        {
          title: "Applications",
          to: APPS_PATH,
          permissions: [admin, appsEditor],
        },
        {
          title: "API Resources",
          to: API_RES_PATH,
          permissions: [admin, appsEditor],
        },
        {
          title: "API Scopes",
          to: API_SCOPES_PATH,
          permissions: [admin, appsEditor],
        },
        {
          title: "Identity Resources",
          to: I_RES_PATH,
          permissions: [admin],
        },
      ],
      permissions: [admin, appsEditor],
    },
    {
      title: "Authentication",
      icon: <LockSecurity />,
      nestedList: [
        {
          ...(!(premiumFeatureEnabled["authFlow"] === false) && {
            title: "Authentication Flow",
            to: AUTHENTICATION_FLOW,
            permissions: [admin],
          }),
        },
        {
          title: "Authentication Settings",
          to: AUTHENTICATION_SETTINGS_PATH,
          permissions: [admin],
        },
        {
          ...(!(premiumFeatureEnabled["passwordLess"] === false) && {
            title: "Passwordless",
            to: AUTHENTICATION_PASSLESS,
            permissions: [admin],
          }),
        },
      ],
      permissions: [admin],
    },
    {
      title: "User Management",
      icon: <User />,
      nestedList: [
        {
          title: "Users",
          to: USERS_PATH,
          permissions: [admin, usersEditor],
        },
        {
          title: "Roles",
          to: "/roles",
          permissions: [admin, usersEditor],
        },
      ],
      permissions: [admin, usersEditor],
    },
    {
      title: "Consent Management",
      icon: <PasteClipboard />,
      nestedList: [
        {
          ...(!(premiumFeatureEnabled["consent"] === false) && {
            title: "Domains",
            to: DOMAINS_PATH,
            permissions: [admin],
          }),
        },
      ],
      permissions: [admin],
    },
    {
      title: "Branding",
      icon: <Brush />,
      nestedList: [
        {
          title: "Universal Login",
          to: BRANDINGS_PATH,
          permissions: [admin],
        },
        {
          title: "Templates",
          to: TEMPLATES_PATH,
          permissions: [admin],
        },
      ],
      permissions: [admin],
    },
    {
      title: "Security",
      icon: <Security />,
      nestedList: [
        {
          title: "Attack Protection",
          to: ATTACK_PROTECTION_PATH,
          permissions: [admin],
        },
        {
          ...(!(
            premiumFeatureEnabled["sms"] === false &&
            premiumFeatureEnabled["email"] === false &&
            premiumFeatureEnabled["authApp"] === false
          ) && {
            title: "Multi-factor Auth",
            to: MULTI_FACTOR_AUTH_PATH,
            permissions: [admin],
          }),
        },
      ],
      permissions: [admin],
    },
    {
      title: "Monitoring",
      icon: <Monitor />,
      nestedList: [
        {
          title: "Audit Logs",
          to: M_AUDIT_LOGS,
          permissions: [admin],
        },
        {
          title: "Error Logs",
          to: M_ERROR_LOGS,
          permissions: [admin],
        },
      ],
      permissions: [admin],
    },
    // {
    //   title: "Identity Resolution",
    //   icon: <FingerPrintIcon />,
    //   nestedList: [
    //     {
    //       title: "Identity Resolution",
    //       to: I_RESOLUTION_PATH,
    //     },
    //   ],
    // },
    {
      title: "Integrations",
      icon: <Integrations />,
      nestedList: [
        {
          title: "Email Providers",
          to: INTEGRATIONS_PATH,
          permissions: [admin],
        },
      ],
      permissions: [admin],
    },
    {
      title: "Settings",
      // icon: <Icon name="Settings" size={20} />,
      icon: <Settings />,
      nestedList: [
        {
          title: "General Settings",
          to: SETTINGS_GENERAL_PATH,
          permissions: [admin],
        },
        {
          title: "Languages",
          to: SETTINGS_LANGUAGES_PATH,
          permissions: [admin],
        },
        {
          title: "Members",
          to: SETTINGS_MEMBER_PATH,
          permissions: [admin],
        },
      ],
      permissions: [admin],
    },
    {
      title: "User Profile",
      icon: <User />,
      nestedList: [
        {
          ...(!(premiumFeatureEnabled["userProfileModels"] === false) && {
            title: "Models",
            to: MODELS_PATH,
            permissions: [admin],
          }),
        },
      ],
      permissions: [admin],
    },
  ];

  const sidebarOptionsFinal = sidebarOptions.map((option) => {
    if (onlyForRoles(option.permissions)) {
      const nestedList: any = [];

      option?.nestedList?.map((item: any) => {
        if (onlyForRoles(item.permissions)) {
          nestedList.push(item);
        }
        return [];
      });
      const nestedListFiltered =
        !!nestedList && nestedList.length ? nestedList : null;

      return { ...option, nestedList: nestedListFiltered };
    }

    return [];
  });

  return (
    <>
      <SidebarLayout
        wrapperClassName="sidebar-wrapper border-r border-primary-stroke"
        renderBottomItem={
          <button
            type="button"
            onClick={feedbackModalToggle}
            className="flex w-full text-primary-secText focus:outline-none items-center hover:text-primary transition rounded p-2 focus:shadow-outlinePrimary active:shadow-outlinePrimary"
          >
            <span className="mr-4 w-4 h-4">
              <ChatLeft width={16} />
            </span>
            Give feedback
          </button>
        }
        renderItem={({ to, title, icon }) => {
          return (
            <NavLink
              style={{ willChange: "transform", backfaceVisibility: "hidden" }}
              className={({ isActive }) =>
                cx([
                  "flex items-center text-sm hover:text-primary transition focus:outline-none duration-150 rounded p-2.5 focus:shadow-outlinePrimary active:shadow-outlinePrimary",
                  isActive ? "text-primary active" : "text-primary-secText",
                ])
              }
              to={to}
            >
              <div
                className="flex items-center justify-between flex-grow transform-gpu transition duration-300"
                style={{
                  willChange: "transform",
                  backfaceVisibility: "hidden",
                }}
              >
                <div className="flex items-center">
                  {icon ? <span className="w-5 h-5">{icon}</span> : null}
                  <span className={cx([!icon ? "pl-5" : "ml-4"])}>{title}</span>
                </div>

                {/* {title === "Home" && (
                  <span className="px-2 rounded-lg bg-opacity-10 font-bold text-xs inline-flex items-center bg-green text-green-300">
                    Beta
                  </span>
                )} */}
              </div>
            </NavLink>
          );
        }}
        list={sidebarOptionsFinal as []}
      />

      {feedbackModalVisible && (
        <FeedbackModal
          visible={feedbackModalVisible}
          toggle={feedbackModalToggle}
        />
      )}
    </>
  );
};
