import { useEffect, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch, useToggle } from "../../hooks";
import { errorLogIState, SEO } from "../../utils";
import { IErrorLog } from "../../interfaces";
import { Eye } from "../../components/Icons";
import DeleteLogsTopBar from "../../components/ui/DeleteLogsTopBar";
import { ErrorLogDetailModal, LogsWrapper } from "./components";
import { Table, Td } from "../../components";

export const ErrorLogs: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState(null);
  const [log, setLog] = useState<IErrorLog>(errorLogIState);

  const { toggle, visible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();
  const {
    apiCall: getAuditLogs,
    response: errorLogsResponse,
    loading: logsLoading,
  } = useFetch("get");

  const {
    apiCall: deleteLogs,
    loading,
    response: deletedResponse,
  } = useFetch("post");

  useEffect(() => {
    SEO({
      title: "Porta - Error Logs",
    });
  }, []);

  useEffect(() => {
    getAuditLogs(`Log/ErrorsLog?page=${currentPage}&search=${searchTerm}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchTerm, deletedResponse]);

  const onDeleteLogs = () => {
    deleteLogs("Log/DeleteLogs", { deleteOlderThan: startDate }, () => {
      toast.success("Logs deleted successfully!");
    });
  };

  const emptySectionText =
    !!searchTerm && errorLogsResponse?.logs?.length < 1
      ? `No log found.`
      : "No logs yet.";

  return (
    <LogsWrapper
      title="Error Logs"
      description="Storage of error log data of authentications made by your users to identify and troubleshoot unexpected events"
    >
      <DeleteLogsTopBar
        setStartDate={setStartDate}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        onDeleteLogs={onDeleteLogs}
        startDate={startDate}
        deleteLoading={loading}
      />

      <Table
        loading={logsLoading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Date" },
          { children: "Description" },
          { children: "Details", className: "text-right" },
        ]}
        values={errorLogsResponse?.logs}
        renderTr={(el) => {
          return (
            <tr className="transition hover:bg-gray-50 group" key={el?.id}>
              <Td width={230}>
                {format(new Date(el?.timeStamp), "MM/dd/yyyy '-' h:mm:ss a")}
              </Td>
              <Td>
                <span className="truncate inline-block max-w-md">
                  {el?.message}
                </span>
              </Td>
              <Td align="right">
                <button
                  type="button"
                  className="text-primary-secText group-hover:text-primary  text-xs flex items-center hover:text-primary-mainText"
                  title="View Details"
                  onClick={() => {
                    toggle();
                    setLog(el);
                  }}
                >
                  <Eye />
                  <span className="ml-1">View</span>
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: errorLogsResponse?.pageSize,
          totalCount: errorLogsResponse?.totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: errorLogsResponse?.logs?.length,
        }}
        emptyView={{ text: emptySectionText }}
      />

      {!!visible && (
        <ErrorLogDetailModal visible={visible} toggle={toggle} log={log} />
      )}
    </LogsWrapper>
  );
};
