import { FC } from "react";
import { cx } from "../../utils";

export enum SpinnerSize {
  xs = "16px",
  sm = "30px",
  md = "40px",
  l = "60px",
}

export enum SpinnerPosition {
  left = "justify-items-start",
  center = "justify-center",
  right = "justify-end",
}

interface ISpinner {
  className?: string;
  size?: SpinnerSize;
  position?: SpinnerPosition;
  color?: string;
  padding?: string;
}

export const Spinner: FC<ISpinner> = ({
  className,
  size = SpinnerSize.md,
  color = "text-primary",
  padding = "p-4",
  position = SpinnerPosition.center,
}) => {
  return (
    <div
      className={cx([
        "flex items-start flex-1",
        className,
        color,
        padding,
        position,
      ])}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        style={{ width: size }}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="stroke-current"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  );
};
