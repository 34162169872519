import { Dispatch, FC } from "react";
import { Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";

import {
  BackButton,
  BtnSize,
  BtnStyle,
  Button,
  DeleteSection,
  FormControl,
  FormWrapper,
  Input,
  InputSize,
  Label,
  SectionTitle,
  Textarea,
  ToggleSwitch,
} from "../../../../components";
import { MultiTagsCard } from "../../../../components/ui/MultiTagsCard";
import { I_RES_PATH } from "../../../../constants";
import { IIdentityResource, IReactHookForm } from "../../../../interfaces";
import {
  inputClasses,
  primaryBtnClasses,
  textareaClasses,
} from "../../../../utils";

interface IIdentityResourceForm extends IReactHookForm {
  onSubmit: () => void;
  iResource?: IIdentityResource;
  id?: any;
  toggle?: () => void;
  editMode?: boolean;
  claims: string[];
  setClaims: Dispatch<string[]>;
  loading?: boolean;
}

export const IdentityResourceForm: FC<IIdentityResourceForm> = (props) => {
  return (
    <>
      <FormWrapper onSubmit={props.onSubmit} loading={props.loading}>
        <BackButton
          to={I_RES_PATH}
          className="mb-4"
          label="Back to Identity Resources"
        >
          <></>
        </BackButton>

        <SectionTitle>Identity Resource Form</SectionTitle>
        <FormControl description="The unique name of the identity resource. This is the value a client will use for the scope parameter in the authorize request.">
          <Label required={!props.editMode} text="Name" />
          <Input
            autoFocus={!props.editMode}
            {...props.register("name", {
              required: "Identity Resource name is required",
              validate: {
                notValidName: (value: string) =>
                  !!value.trim() || "Identity Resource name is required",
              },
            })}
            maxLength={100}
            className={inputClasses}
            error={!!props.errors.name && props.errors.name.message}
            inputSize={InputSize.sm}
            disabled={props.editMode}
          />
        </FormControl>

        <FormControl description="This value can be used e.g. on the consent screen.">
          <Label text="Display Name" htmlFor="display-name" />
          <Input
            id="display-name"
            {...props.register("displayName")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl description="This value can be used e.g. on the consent screen.">
          <Label text="description" htmlFor="description" />
          <Textarea
            id="description"
            {...props.register("description")}
            className={textareaClasses}
            maxLength={3000}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="showInDiscoveryDocument"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="discovery-doc"
                label="Show In Discovery Document"
                description="Specifies whether this scope is shown in the discovery document. Defaults to true."
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="required"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="required"
                label="Required"
                description="Specifies whether the user can de-select the scope on the consent screen (if the consent screen wants to implement such a feature). Defaults to false."
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={props.control}
            name="enabled"
            render={({ field: { onChange, value, ref } }: any) => (
              <ToggleSwitch
                id="enabled"
                label="Enabled"
                description="Indicates if this resource enabled and can be requested. Defaults to true."
                onChange={onChange}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </FormControl>

        {props.editMode && (
          <FormControl description="Dictionary to hold any custom API resource-specific values as needed.">
            <Label text="Properties" />
            <NavLink
              to={`${I_RES_PATH}/identity-resource-properties/${props.id}`}
              state={{
                id: props?.iResource?.id,
                name: props?.iResource?.displayName,
              }}
            >
              <Button
                type="button"
                btnStyle={BtnStyle.primary}
                btnSize={BtnSize.normal}
                className={primaryBtnClasses}
              >
                Manage Identity Resources Properties
              </Button>
            </NavLink>
          </FormControl>
        )}

        <FormControl description="List of associated user claim types that should be included in the access token.">
          <Label text="User Claims" />
          <MultiTagsCard
            tags={props.claims}
            hasInput
            setTags={props.setClaims}
            suggestedEndpoint="/Clients/SearchClaims?limit=0"
          />
        </FormControl>

        {props.editMode && (
          <DeleteSection
            title="Delete Identity Resource"
            message="Deleting the identity resource will not allow the application to request the group of claims"
            onDelete={props.toggle}
          />
        )}
      </FormWrapper>
    </>
  );
};
