import { FC, forwardRef } from "react";

import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import {
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
  Animation,
} from "../../../components";
import { cancelBtnClasses, cx, primaryBtnClasses } from "../../../utils";

import { enGB } from "date-fns/locale";

interface IDateRange {
  rangeDates?: any;
  setRangeDates?: any;
  toggle: any;
  visible: boolean;
  dateRef?: any;
  onDateRangeSelected: () => void;
}

export const DateRange: FC<IDateRange> = forwardRef(
  ({
    rangeDates,
    setRangeDates,
    onDateRangeSelected,
    visible,
    toggle,
    dateRef,
  },ref) => {
    return (
      <div className="flex items-center text-sm text-primary-secText" ref={dateRef}>
        Show data per:
        <div className="relative">
          <div
            className={cx([
              "py-2 px-3 border border-primary-stroke rounded inline-flex items-center bg-white hover:text-primary transition cursor-pointer ml-2 focus:shadow-outlinePrimary active:shadow-outlinePrimary",
              visible && "shadow-outlinePrimary text-primary",
            ])}
            onClick={toggle}
            role="button"
          >
            {!!rangeDates[0]?.startDate
              ? format(rangeDates[0]?.startDate, "yyyy/MM/dd")
              : "Start date"}{" "}
            {" - "}
            {!!rangeDates[0]?.endDate
              ? format(rangeDates[0]?.endDate, "yyyy/MM/dd")
              : "End date"}
            {/* Start date - End Date */}
            <div className="ml-1 w-5 h-5">
              <Icon name="Calendar" size={18} />
            </div>
          </div>
          {visible && (
            <Animation show={visible} type="fade-top">
              <div className="absolute top-10 left-2 shadowed-content rounded z-10">
                <button
                  onClick={toggle}
                  className={cx([
                    "p-1 self-start rounded transition",
                    "focus:outline-none text-primary-secText hover:bg-gray-50 hover:text-primary focus:shadow-outlinePrimary active:shadow-outlinePrimary",
                    "absolute top-5 right-5 w-8 h-8",
                  ])}
                  title="Close"
                  type="button"
                >
                  <span className="inline-block w-6 h-6">
                    <Icon name="Close" size={24} />
                  </span>
                </button>
                <DateRangePicker
                  onChange={(value) => setRangeDates([value.selection])}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  dateDisplayFormat="yyyy/MM/dd"
                  ranges={rangeDates}
                  direction="horizontal"
                  maxDate={new Date()}
                  weekStartsOn={1}
                  locale={enGB}
                />

                <div className="flex border-t border-primary-stroke py-4 px-6 mt-6 justify-end">
                  <Button
                    type="button"
                    btnType={BtnType.secondary}
                    btnStyle={BtnStyle.neutral}
                    btnSize={BtnSize.normal}
                    className={cancelBtnClasses}
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={onDateRangeSelected}
                    btnStyle={BtnStyle.primary}
                    btnSize={BtnSize.normal}
                    className={cx([
                      "ml-4 flex items-center",
                      primaryBtnClasses,
                    ])}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Animation>
          )}
        </div>
      </div>
    );
  }
);
