import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
//@ts-ignore
import {
  FormControl,
  Input,
  InputSize,
  Label,
  Textarea,
} from "../../../../components";
import {
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS,
} from "../../../../constants";
import { useDebounce, useFetch } from "../../../../hooks";
import { inputClasses, textareaClasses } from "../../../../utils";
import { handleErrors } from "../../utils/handle-errors";
import { ModelIcon } from "./ModelIcon";

interface IModelMainInfo {
  isFromEdit?: boolean;
}

export const ModelMainInfo: FC<IModelMainInfo> = ({ isFromEdit }) => {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  function createAlias(text: string) {
    const noSpaceAlias = text?.replace(/\s+/g, "_").toLocaleLowerCase();
    handleInputChange(noSpaceAlias);

    return noSpaceAlias;
  }

  const { apiCall: isUniqueAlias } = useFetch("post");
  const [aliasError, setAliasError] = useState("");
  const [alias, setAlias] = useState<string>("");
  const debouncedAlias = useDebounce(alias, 400);

  const handleInputChange = (e: any) => {
    clearErrors("alias");
    if (e.target) {
      setAlias(e.target.value);
    } else {
      setAlias(e);
    }
  };

  const [modelName] = useWatch({ name: ["name"] });

  useEffect(() => {
    !isFromEdit && setValue("alias", createAlias(modelName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName]);

  useEffect(() => {
    if (!!alias) {
      isUniqueAlias(
        `${USER_PROFILE_API}/integration-models/validate`,
        {
          alias: alias,
        },
        (response) => {
          const { data } = response;
          const errors = data.errors;
          if (!data.isValid) {
            setAliasError(`${Object.values(errors)}`);
          } else {
            setAliasError("");
          }
        },
        (err) => {
          handleErrors(err);
        },
        USER_PROFILE_API_HEADERS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAlias]);
  return (
    <>
      <FormControl>
        <Label required text="Name" htmlFor="name" />
        <Input
          id="name"
          autoFocus
          {...register("name", {
            required: "Property name is required",
          })}
          error={!!errors.name && errors.name.message}
          inputSize={InputSize.sm}
          className={inputClasses}
          placeholder="Enter Name"
          maxLength={100}
        />
      </FormControl>

      <FormControl>
        <Label text="Description" />
        <Textarea
          {...register("description")}
          className={textareaClasses}
          maxLength={3000}
        />
      </FormControl>

      <FormControl>
        <Label required text="Alias" htmlFor="alias" />
        <Input
          id="alias"
          {...register("alias", {
            required: "Alias is required",
            validate: {
              maxLength: (value: string) =>
                value.length < 25 || "Alias should have max 25 characters",
            },
          })}
          error={(!!errors.alias && errors.alias.message) || aliasError}
          inputSize={InputSize.sm}
          className={inputClasses}
          disabled={isFromEdit}
          placeholder="Enter Alias"
          onChange={handleInputChange}
        />
      </FormControl>

      <ModelIcon isFromEdit={isFromEdit || false} />

      {/* <FormControl>
        <Label required text="Model Type" htmlFor="model-type" />
        <Controller
          control={control}
          name="modelType"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, ref } }) => (
            <Select
              components={{
                Option: ModelTypeItem,
              }}
              noOptionsMessage={() => "No model type found."}
              options={modelTypes}
              getOptionLabel={(x) => x?.name}
              getOptionValue={(x) => x?.value}
              isSearchable
              ref={ref}
              menuPosition="fixed"
              portalPlacement="auto"
              onChange={onChange}
              className={cx([
                "text-sm",
                errors?.modelActionType && "invalid-field",
              ])}
              classNamePrefix="porta-react-select"
              placeholder="Model Type"
              styles={reactSelectStyle}
            />
          )}
        />
      </FormControl> */}
    </>
  );
};
