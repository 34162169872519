import { FC, useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import {
  Initials,
  Modal,
  ModalSize,
  Table,
  Td,
  BtnSize,
  BtnStyle,
  Button,
} from "../../../components";
import { cx, primaryBtnClasses, SEO, userIState } from "../../../utils";
import { AssignUserModal } from "./components/AssignUserModal";
import { USERS_PATH } from "../../../constants";
import { IUser } from "../../../interfaces";
import {
  Switch as PermissionsSwitch,
  Can,
  useUserContext,
  Permission,
} from "../../../context";

export const RoleUsers: FC = () => {
  const { admin } = Permission;
  const [response, setResponse] = useState<any>();
  const [user, setUser] = useState<IUser>(userIState);
  const { totalCount, users } = !!response && response;
  const { currentPage, handlePageChange } = useCurrentPage();

  const { toggle: assignUserModalToggle, visible: assignUserModalVisible } =
    useToggle();
  const { toggle: removeUserModalToggle, visible: removeUserModalVisible } =
    useToggle();
  const { role } = useUserContext();

  const {
    apiCall: getRoleUsers,
    loading: getRoleUserLoading,
    errors,
  } = useFetch("get");
  const { apiCall: removeUserFromRole, loading } = useFetch("delete");
  const { apiCall: assignRole, loading: assignRoleLoading } = useFetch("post");

  const PER_PAGE = 10;

  useEffect(() => {
    SEO({
      title: "Porta - Role Users",
    });
  }, []);

  useEffect(() => {
    getRoleUsers(
      `/Roles/${role?.id}/Users?page=${currentPage}&pageSize=${PER_PAGE}`,
      {},
      (response) => {
        setResponse(response);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, role]);

  const assignRoleToUsers = (users: IUser[]) => {
    let userIds: string[] = [];

    users?.map((item: any) => userIds.push(item.id));
    assignRole(`/Roles/AssignUsersToRole?roleId=${role.id}`, userIds, () => {
      assignUserModalToggle();
      toast.success("Users assigned successfully!");

      getRoleUsers(
        `/Roles/${role?.id}/Users?page=${currentPage}&pageSize=${PER_PAGE}`,
        {},
        (response) => {
          setResponse(response);
        }
      );
    });
  };

  const onRemoveUserFromRole = () => {
    const obje = {
      userId: user.id,
      roleId: role.id,
    };

    removeUserFromRole(`/Users/Roles`, { data: obje }, () => {
      removeUserModalToggle();
      setResponse({
        ...response,
        users: response.users.filter((item: IUser) => item.id !== user.id),
      });
    });
  };

  return (
    <>
      <PermissionsSwitch>
        <Can permissions={[admin]}>
          <div>
            <Button
              btnStyle={BtnStyle.primary}
              btnSize={BtnSize.normal}
              className={cx([primaryBtnClasses, "flex items-center mt-8"])}
              onClick={assignUserModalToggle}
            >
              <span className="w-5 h-5 inline-block">
                <Icon name="Plus" size="20" />
              </span>
              <span className="ml-2">Assign</span>
            </Button>
          </div>
        </Can>
      </PermissionsSwitch>

      <Table
        wrapperClassName="mt-8"
        names={[{ children: "Name" }, { children: "" }]}
        values={users}
        loading={getRoleUserLoading}
        renderTr={(el: IUser) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.id}>
              <Td className="py-3">
                <NavLink
                  to={`${USERS_PATH}/${el.id}-details`}
                  className="group block"
                >
                  <div className="flex items-center">
                    {el?.picture ? (
                      <div className="w-8 h-8 mr-4">
                        <img
                          src={el?.picture}
                          alt=""
                          className="max-w-full rounded-full w-full h-full"
                        />
                      </div>
                    ) : (
                      <Initials text={el?.firstName} className="mr-4" />
                    )}
                    <div>
                      <p className="text-sm group-hover:text-primary transition">
                        {el?.firstName} {el?.lastName}
                      </p>
                      <p className="text-primary-secText text-xs">
                        {el?.email}
                      </p>
                    </div>
                  </div>
                </NavLink>
              </Td>

              <PermissionsSwitch>
                <Can permissions={[admin]}>
                  <Td className="text-primary-secText py-3" align="right">
                    <button
                      type="button"
                      className="hover:text-warning focus:outline-none"
                      title="Delete"
                      onClick={() => {
                        removeUserModalToggle();
                        setUser(el);
                      }}
                    >
                      <span className="w-5 h-5 inline-block">
                        <Icon name="Delete" size={22} />
                      </span>
                    </button>
                  </Td>
                </Can>
              </PermissionsSwitch>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: users?.length,
        }}
        emptyView={{ text: errors || "No users" }}
      />

      {assignUserModalVisible && (
        <AssignUserModal
          toggle={assignUserModalToggle}
          visible={assignUserModalVisible}
          role={role}
          passSelectedUsers={(users: IUser[]) => assignRoleToUsers(users)}
          loading={assignRoleLoading}
        />
      )}

      {removeUserModalVisible && (
        <Modal
          hide={removeUserModalToggle}
          visible={removeUserModalVisible}
          title="Remove user"
          onConfirmClick={onRemoveUserFromRole}
          confirmBtnText="Remove"
          warningModal
          withFooter
          modalSize={ModalSize.sm}
          loading={loading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to remove{" "}
            <strong className="font-medium">
              {user?.firstName} {user?.lastName}
            </strong>
            ?
          </p>
        </Modal>
      )}
    </>
  );
};
