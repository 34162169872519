import { FC, useEffect } from "react";

import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import {
  Accordion,
  FormControl,
  FormFooter,
  Label,
  Paragraph,
  ParagraphSize,
  Input,
  InputSize,
  Loader,
  BackButton,
  Tooltip,
} from "../../../components";
import { useFetch } from "../../../hooks";
import { inputClasses, SEO, validateEmail } from "../../../utils";
import { Status } from "../../clients/api-resources/components";
import { ContainerSize, Wrapper } from "../../settings/components";
import { ISmartEmailingResponse } from "../../../interfaces/components/email-providers";
import { Parameter } from "../components";
import { INTEGRATIONS_PATH } from "../../../constants";

export interface IUseStateLocation {
  isEnabled: boolean;
}

export const SmartEmailing: FC = () => {
  const { state } = useLocation() as any;
  const { apiCall: getSmartEmailing, response, loading } = useFetch("get");
  const { apiCall: updateSmartEmailing, loading: updateSmartEmailingLoading } =
    useFetch("post");
  const {
    register,
    formState: { errors },
    reset,
    control,
    handleSubmit,
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: "smartEmailingTemplateConfigurations",
  });

  useEffect(() => {
    SEO({
      title: "Porta - Smart Emailing",
    });

    getSmartEmailing(
      "EmailIntegration/GetSmartEmailingConfiguration",
      {},
      (response) => {
        reset({
          isEnabled: !state?.isEnabled,
          accountAPIKey: response?.accountAPIKey,
          smartEmailingFromEmail: response?.smartEmailingFromEmail,
          smartEmailingReplyToEmail: response?.smartEmailingReplyToEmail,
          smartEmailingSenderName: response?.smartEmailingSenderName,
          userNameAuthentication: response?.userNameAuthentication,
          smartEmailingTemplateConfigurations:
            response?.smartEmailingTemplateConfigurations,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit: SubmitHandler<any> = (data: ISmartEmailingResponse) => {
    let emailTemplates: any = [];

    data?.smartEmailingTemplateConfigurations?.map((item) =>
      emailTemplates.push({
        templateTitle: item.templateTitle,
        templateId: item.templateId,
      })
    );

    const emailTemplateObj = {
      ...data,
      smartEmailingTemplateIdUpdateDtos: emailTemplates,
    };

    updateSmartEmailing(
      `EmailIntegration/UpdateSmartEmailingConfiguration`,
      emailTemplateObj,
      () => {
        toast.success("Smart Emailing Configuration updated successfully!");
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  return (
    <>
      {/* <Prompt when={isDirty} message="Are you sure you want to leave?" /> */}
      <form>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Wrapper noMarginTop className="items-baseline">
              <BackButton
                to={INTEGRATIONS_PATH}
                label="Back to Email Providers"
                className="mb-4"
              >
                <></>
              </BackButton>

              <div className="flex items-center">
                <div className="rounded p-1 border border-primary-stroke flex items-center justify-center w-16 h-16 bg-white">
                  <img
                    src="/images/SE-logo.png"
                    alt="Unified Logo"
                    width={64}
                    height={64}
                  />
                </div>
                <div className="ml-4">
                  <h4 className="text-primary-mainText flex items-center">
                    SmartEmailing
                    <Status enabled={state?.isEnabled} className="ml-6" />
                  </h4>
                  <Paragraph
                    parahraphSize={ParagraphSize.sm}
                    noMargin
                    className="mt-1"
                  >
                    Set up your mailing service with SmartEmailing
                  </Paragraph>
                </div>
              </div>
            </Wrapper>

            <Wrapper title="General" containerSize={ContainerSize.md}>
              <FormControl>
                <Label required text="SmartEmailing Account API Key" />
                <Input
                  autoFocus
                  {...register("accountAPIKey", {
                    required: "SmartEmailing Account API Key is required",
                  })}
                  error={!!errors.accountAPIKey && errors.accountAPIKey.message}
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  placeholder="Enter your Smart Emailing Account API Key"
                />
              </FormControl>

              <FormControl>
                <Label required text="SmartEmailing User Authentication" />
                <Input
                  {...register("userNameAuthentication", {
                    required: "SmartEmailing User Authentication is required",
                  })}
                  error={
                    !!errors.userNameAuthentication &&
                    errors.userNameAuthentication.message
                  }
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  placeholder="Enter your SmartEmailing User Credentials"
                />
              </FormControl>

              <FormControl>
                <Label required text="From" />
                <Input
                  {...register("smartEmailingFromEmail", {
                    required: "From is required",
                    validate: (value: string) =>
                      validateEmail(value) || "From email field is not valid",
                  })}
                  error={
                    !!errors.smartEmailingFromEmail &&
                    errors.smartEmailingFromEmail.message
                  }
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  placeholder="Enter from"
                />
              </FormControl>

              <FormControl>
                <Label required text="ReplyTo" />
                <Input
                  {...register("smartEmailingReplyToEmail", {
                    required: "ReplyTo is required",
                    validate: (value: string) =>
                      validateEmail(value) ||
                      "ReplyTo email field is not valid",
                  })}
                  error={
                    !!errors.smartEmailingReplyToEmail &&
                    errors.smartEmailingReplyToEmail.message
                  }
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  placeholder="Enter replyto"
                />
              </FormControl>

              <FormControl lastChild>
                <Label required text="Sender Name" />
                <Input
                  {...register("smartEmailingSenderName", {
                    required: "Sender name is required",
                  })}
                  error={
                    !!errors.smartEmailingSenderName &&
                    errors.smartEmailingSenderName.message
                  }
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  placeholder="Enter sender name"
                />
              </FormControl>
            </Wrapper>

            <Wrapper title="Configure Emails" containerSize={ContainerSize.md}>
              <Accordion
                title="Default Parameters"
                className="mb-6"
                opened
                content={
                  <>
                    <p className="text-sm font-medium text-primary-secText">
                      Properties that you can use for each template:
                    </p>
                    <p className="text-xs text-primary-secText mb-4">
                      Hover over a property and click to copy to clipboard.
                    </p>

                    <div className="flex flex-wrap">
                      {response?.defaultParametres?.map((param: any) => (
                        <Parameter
                          key={param.parametreCodeValue}
                          label={`replace_${param.parametreCodeValue}`}
                          copyText={`replace_${param.parametreCodeValue}`}
                        />
                      ))}
                    </div>
                  </>
                }
              />

              {fields?.map((emailing: any, index: number) => {
                return (
                  <Accordion
                    title={
                      <div className="flex">
                        <span>{emailing.templateName}</span>

                        {emailing?.templateDescription && (
                          <div className="relative group inline-block ml-2">
                            <Tooltip
                              text={emailing?.templateDescription}
                              innerClassName="mb-2 w-80"
                              className="text-sm text-primary-secText w-5"
                              bottom="bottom-4"
                            >
                              <span className="w-3 h-3 inline-block">
                                <Icon name="Info" width={12} />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    }
                    className="mb-6"
                    key={emailing.id}
                    opened={false}
                    buttonClassName={
                      !!errors &&
                      errors.smartEmailingTemplateConfigurations?.[index]
                        ?.templateId &&
                      "text-red"
                    }
                    content={
                      <>
                        <FormControl>
                          <Label required text="SmartEmailing Email ID" />
                          <Input
                            autoFocus
                            key={emailing?.id}
                            defaultValue={emailing?.templateId}
                            {...register(
                              `smartEmailingTemplateConfigurations.${index}.templateId` as const,
                              {
                                required: "SmartEmailing Email ID is required",
                              }
                            )}
                            error={
                              !!errors &&
                              !!errors.smartEmailingTemplateConfigurations &&
                              errors?.smartEmailingTemplateConfigurations?.[
                                index
                              ]?.templateId?.message
                            }
                            inputSize={InputSize.sm}
                            className={inputClasses}
                            placeholder="Enter your SmartEmailing User Credentials"
                          />
                        </FormControl>

                        <p className="text-sm font-medium text-primary-secText">
                          Properties:
                        </p>
                        <p className="text-xs text-primary-secText mb-4">
                          Hover over a property and click to copy to clipboard.
                        </p>

                        <div className="flex flex-wrap">
                          {emailing.templateParametres.map((param: any) => (
                            <Parameter
                              label={`replace_${param.parametreCodeValue}`}
                              copyText={`replace_${param.parametreCodeValue}`}
                              key={param.parametreCodeValue}
                            />
                          ))}
                        </div>
                      </>
                    }
                  />
                );
              })}
            </Wrapper>

            <FormFooter
              containerSize="w-1/2"
              // hasOptionalButton
              // onOptionalButtonClick={handleSendTestEmail}
              onSubmit={handleSubmit(onFormSubmit)}
              // optionalBtnLabelText="Send Test Email"
              submitBtnType="submit"
              disabled={updateSmartEmailingLoading}
              loading={updateSmartEmailingLoading}
            />
          </>
        )}
      </form>
    </>
  );
};
