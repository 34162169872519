import { createContext, FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../components";
import { CMP_API_URL } from "../constants";
import { useFetch } from "../hooks";
import { IConsentStyle } from "../interfaces";

const TemplateContext = createContext<any>(null);

// export const TemplatesProvider: FC<{ children: any }> = ({
const TemplatesContextProvider: FC<{ children: any }> = ({ children }) => {
  // debugger;
  const { apiCall: getTheme, loading: getThemeLoading } = useFetch("get");
  const { apiCall: editSelectedTheme } = useFetch("put");
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTheme, setActiveTheme] = useState<boolean>(false);
  const [theme, setTheme] = useState<IConsentStyle>();
  const [themeId, setThemeId] = useState<IConsentStyle["encryptedId"]>();
  const [switchBannerType, setSwitchBannerType] = useState<boolean>(false);
  const [showWidget, setShowWidget] = useState<boolean>(false);
  // const [cookies, setCookies] = useState<any>([]);
  const [preview, setPreview] = useState<string>("desktop");
  const [bannerType, setBannerType] = useState("info");

  const createTheme = () => {};
  const editTheme = (data: any) => {
    editSelectedTheme(`${CMP_API_URL}/EditConsentStyle`, data);
  };
  const deleteTheme = (id: string) => {};

  // const getConsentStyles = (currentPage: number, perPage: number) => {
  //   getThemes(
  //     `${CMP_API_URL}/ConsentStyleListView?page=${currentPage}&pageSize=${perPage}`,
  //     {},
  //     (response) => {
  //       setResponse(response);
  //     }
  //   );
  // };
  let { templateId } = useParams();

  useEffect(() => {
    // if (!!themetemplateId) {
    getTheme(
      `${CMP_API_URL}/ConsentStyleOverview?consentStyleId=${templateId}`,
      {},
      (res) => {
        // console.log("RESP", res);
        setTheme({ ...res, activeTheme: true });
      }
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, themeId]);
  return (
    <TemplateContext.Provider
      value={{
        activeTheme,
        setActiveTheme,
        loading,
        setLoading,
        theme,
        setTheme,
        getThemeLoading,
        setThemeId,
        preview,
        setPreview,
        createTheme,
        editTheme,
        deleteTheme,
        switchBannerType,
        setSwitchBannerType,
        showWidget,
        setShowWidget,
        bannerType,
        setBannerType,
        // cookies
      }}>
      {!!theme?.encryptedId ? children : <Loader />}
    </TemplateContext.Provider>
  );
};

const useTemplateContext = () => {
  return useContext(TemplateContext);
};

export { TemplatesContextProvider, useTemplateContext };
