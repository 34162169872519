import { FC } from "react";

export const Widgets: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2573 8.6213C12.0857 7.44973 12.0857 5.55023 13.2573 4.37866L15.3786 2.25734C16.5502 1.08577 18.4497 1.08577 19.6213 2.25734L21.7426 4.37866C22.9142 5.55023 22.9142 7.44973 21.7426 8.6213L19.6213 10.7426C18.4497 11.9142 16.5502 11.9142 15.3786 10.7426L13.2573 8.6213ZM14.318 7.56064C13.7322 6.97486 13.7322 6.02511 14.318 5.43932L16.4393 3.318C17.0251 2.73222 17.9748 2.73222 18.5606 3.318L20.6819 5.43932C21.2677 6.02511 21.2677 6.97486 20.6819 7.56064L18.5606 9.68196C17.9748 10.2677 17.0251 10.2677 16.4393 9.68196L14.318 7.56064Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.99998C2 3.34313 3.34315 1.99998 5 1.99998H8C9.65685 1.99998 11 3.34313 11 4.99998V7.99998C11 9.65684 9.65685 11 8 11H5C3.34315 11 2 9.65684 2 7.99998V4.99998ZM5 3.49998H8C8.82843 3.49998 9.5 4.17156 9.5 4.99998V7.99998C9.5 8.82841 8.82843 9.49998 8 9.49998H5C4.17157 9.49998 3.5 8.82841 3.5 7.99998V4.99998C3.5 4.17156 4.17157 3.49998 5 3.49998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13C3.34315 13 2 14.3431 2 16V19C2 20.6568 3.34315 22 5 22H8C9.65685 22 11 20.6568 11 19V16C11 14.3431 9.65685 13 8 13H5ZM8 14.5H5C4.17157 14.5 3.5 15.1716 3.5 16V19C3.5 19.8284 4.17157 20.5 5 20.5H8C8.82843 20.5 9.5 19.8284 9.5 19V16C9.5 15.1716 8.82843 14.5 8 14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16C13 14.3431 14.3431 13 16 13H19C20.6569 13 22 14.3431 22 16V19C22 20.6568 20.6569 22 19 22H16C14.3431 22 13 20.6568 13 19V16ZM16 14.5H19C19.8284 14.5 20.5 15.1716 20.5 16V19C20.5 19.8284 19.8284 20.5 19 20.5H16C15.1716 20.5 14.5 19.8284 14.5 19V16C14.5 15.1716 15.1716 14.5 16 14.5Z"
      />
    </svg>
  );
};
