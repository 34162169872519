import { cx } from "../../utils";
import { Spinner, SpinnerPosition, SpinnerSize } from "./Spinner";

interface IStatus {
  className?: string;
  enabled: boolean;
  loading?: boolean;

}

export const Status: React.FC<IStatus> = ({ className, enabled, loading }) => {
  return (
    <div className={cx(["flex items-center text-sm h-5", className])}>
      {loading ? (
        <Spinner
          size={SpinnerSize.xs}
          position={SpinnerPosition.left}
          padding="p-0"
        />
      ) : (
        <>
          <span
            className={cx([
              "w-2 h-2 rounded-full block mr-2",
              enabled ? "bg-confirm" : "bg-warning",
            ])}
          ></span>
          {enabled ? "Enabled" : "Disabled"}
        </>
      )}
    </div>
  );
};
