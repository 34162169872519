import { FC, HTMLAttributes, useState, ReactNode } from "react";
import { SidebarItem, ItemProps } from "./SidebarItem";

export interface SidebarMenuProps {
  arrowIcon?: ReactNode;
  ulProps?: HTMLAttributes<HTMLUListElement>;
  list: ItemProps[];
  depth?: number;
  renderItem?: (item: any) => ReactNode;
}

export const SidebarMenu: FC<SidebarMenuProps> = ({
  ulProps,
  list,
  arrowIcon,
  renderItem,
  depth,
}) => {
  const [openList, setOpenList] = useState<string | null>(null);
  return (
    <ul {...ulProps} className="py-1">
      {list.map(({ nestedList, ...rest }, key) => (
        <SidebarItem
          key={key}
          {...rest}
          depth={depth}
          renderItem={renderItem}
          arrowIcon={arrowIcon}
          nestedList={nestedList}
          openList={openList}
          setOpenList={setOpenList}
        />
      ))}
    </ul>
  );
};
