import { ChangeEvent, FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  FormControl,
  FormFooter,
  FramePosition,
  Input,
  InputSize,
  Label,
  PageHeadeline,
  UploadImageFrame,
} from "../../components";
import { useFetch } from "../../hooks";
import { SEO, inputClasses, reactSelectStyle } from "../../utils";
import { Wrapper } from "./components";

import { IOrganizationSettings } from "../../interfaces/components/general-settings";
import { countries } from "../../utils/countries";
import { handleErrors } from "../user-profile/utils/handle-errors";

export const GeneralSettings: FC = () => {
  const [logo, setLogo] = useState<string>("");
  const { apiCall: addOrganizationLogo, loading: addLogoLoading } =
    useFetch("post");
  const {
    apiCall: getOrganizationSettings,
    response: organizationSettings,
    loading,
  } = useFetch("get");

  const { apiCall: editOrganizationSettings, loading: editOrgLoading } =
    useFetch("post");
  const {
    register,
    formState: { errors },
    reset,
    control,
    handleSubmit,
    setValue,
  } = useForm();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const value = e.target?.files[0];
      const formData = new FormData();

      formData.append("file", value);

      addOrganizationLogo(
        "OrganizationSettings/AddClientLogo",
        formData,
        (response) => {
          setLogo(response);
          setValue("organizationLogo", response);
        },
        (error) => {
          handleErrors(error);
        }
      );
    }
  };

  const removeLogo = () => {
    setLogo("");
  };

  useEffect(() => {
    SEO({
      title: "Porta - General Settings",
    });

    getOrganizationSettings("OrganizationSettings/OrganizationSettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const organizationSettingsObj = {
      ...organizationSettings,
      state: {
        name: organizationSettings?.state,
        id: organizationSettings?.state,
      },
    };
    reset(organizationSettingsObj);
    setLogo(organizationSettings?.organizationLogo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSettings]);

  const onOrganizationSettingsSubmit: SubmitHandler<any> = (
    data: IOrganizationSettings
  ) => {
    const organization = {
      ...data,
      state: data.state?.name || "",
      organizationLogo: logo,
    };

    editOrganizationSettings(
      "OrganizationSettings/EditOrganizationSettings",
      organization,
      () => {
        toast.success("Organization settings saved successfully!");
      }
    );
  };

  return (
    <form>
      <Wrapper noMarginTop>
        <PageHeadeline
          className="mb-10"
          title="General Settings"
          description="Add information on your organization and configure general settings associated with your organization."
        />

        <FormControl>
          <Label text="Organization Logo" />
          <UploadImageFrame
            onFileChange={handleFileChange}
            onRemoveImage={removeLogo}
            title="Logo"
            imageScr={logo}
            id="organization-logo"
            framePosition={FramePosition.left}
            loading={addLogoLoading || loading}
          />
        </FormControl>

        <FormControl>
          <Label required text="Organization Name" />
          <Input
            {...register("organizationName", {
              required: "Organization name is required",
              validate: {
                notValidName: (value: string) =>
                  !!value.trim() || "Organization name is required",
              },
            })}
            error={!!errors.organizationName && errors.organizationName.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label text="Address" />
          <Input
            {...register("address")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label text="City" />
          <Input
            {...register("city")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label text="Country" />
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, value, ref } }: any) => (
              <Select
                onChange={onChange}
                options={countries}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                value={value || ""}
                ref={ref}
                isSearchable
                menuPortalTarget={document.body}
                className="text-sm mb-2"
                classNamePrefix="porta-react-select"
                styles={reactSelectStyle}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Label required text="Support Email" />
          <Input
            {...register("supportEmail", {
              required: "Support email is required",
            })}
            error={!!errors.supportEmail && errors.supportEmail.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label text="Support URL" />
          <Input
            {...register("supportUrl")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label text="Cookies URL" />
          <Input
            {...register("cookiesUrl")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label required text="Terms and Conditions URL" />
          <Input
            {...register("termsConditionsUrl", {
              required: "Terms and Conditions Url is required",
            })}
            error={
              !!errors.termsConditionsUrl && errors.termsConditionsUrl.message
            }
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl lastChild>
          <Label required text="Privacy Policy URL" />
          <Input
            {...register("privacyPolicyUrl", {
              required: "Privacy Policy Url is required",
            })}
            error={!!errors.privacyPolicyUrl && errors.privacyPolicyUrl.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>
      </Wrapper>

      <Wrapper title="Social" className="mb-6">
        <FormControl>
          <Label text="Facebook URL" />
          <Input
            {...register("facebookUrl")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl>
          <Label text="Twitter URL" />
          <Input
            {...register("twitterUrl")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <FormControl lastChild>
          <Label text="Instagram URL" />
          <Input
            {...register("instagramUrl")}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>
      </Wrapper>

      <FormFooter
        onSubmit={handleSubmit(onOrganizationSettingsSubmit)}
        submitBtnType="submit"
        loading={editOrgLoading}
        containerSize="w-1/2"
      />
    </form>
  );
};
