import { FC } from 'react'
import {
  FormControl,
  Input,
  InputSize,
} from "../../../../components";
import {
  inputClasses,
} from "../../../../utils";
import { IHeaders } from '../../interface';

interface IRequestHeadersDetails {
  headers: IHeaders[]; // check this
}

export const RequestHeadersDetails : FC<IRequestHeadersDetails> = ({
  headers,
}) => {

  return (
    <>
      <div className="flex gap-4 py-2 px-1 mb-3 items-center border-b border-primary-stroke justify-between pb-2">
        <p className="text-primary-secText font-medium w-1/2 text-sm">Header Name</p>
        <p className="text-primary-secText font-medium w-1/2 text-sm">Header Value</p>
      </div>
      {headers?.map((header: IHeaders, index: number) => (
        <div
          className="flex justify-between gap-4 py-2 px-1 mb-3"
          key={index}
        >
          <FormControl className="w-1/2 ">
            <Input
              inputSize={InputSize.sm}
              className={inputClasses}
              value={header?.key}
              readOnly
              disabled
            />
          </FormControl>
          <FormControl className='w-1/2'>
            <Input
              inputSize={InputSize.sm}
              className={inputClasses}
              value={header?.value}
              readOnly
              disabled
            />
          </FormControl>
        </div>
      ))}
    </>
  )
}
