import { FC } from "react";

export const Brush: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.009 8.89143L16.3575 4.05476C17.1597 2.89598 18.7425 2.59383 19.9152 3.37561C21.0886 4.15787 21.418 5.73651 20.6554 6.92278L17.2281 12.2542C17.5029 12.8455 17.6772 13.4853 17.7304 14.1555C17.8976 16.2646 16.8559 18.5044 14.335 20.3339C12.2177 21.8704 9.74738 21.9753 7.60935 21.4953C6.8491 21.3247 6.11841 21.0777 5.4436 20.786C5.3639 20.7558 5.28416 20.7237 5.20439 20.6895C5.16058 20.6707 5.11948 20.6483 5.0813 20.6227C4.02422 20.1263 3.1258 19.5279 2.49739 18.9608L1.67627 18.2199L2.66844 17.7312C3.44158 17.3504 3.88163 17.0228 4.15684 16.7282C4.42167 16.4448 4.56788 16.156 4.69633 15.7827C4.75306 15.6179 4.80633 15.4313 4.86561 15.2238C5.08038 14.4717 5.37399 13.4436 6.1965 12.1702C7.97553 9.41588 10.7871 8.4861 13.009 8.89143ZM17.5908 4.90857C17.9273 4.42249 18.5912 4.29575 19.0831 4.62369C19.5754 4.95183 19.7135 5.61403 19.3936 6.11164L18.8313 6.98635L16.9983 5.76434L17.5908 4.90857ZM16.1444 6.99782L18.0201 8.2483L16.3318 10.8745C15.8206 10.2837 15.1916 9.78307 14.4791 9.40315L16.1444 6.99782ZM10.6728 20.1982C10.708 20.1767 10.7428 20.1549 10.7775 20.1329C12.2879 19.1717 13.4373 17.637 13.7353 16.1472C13.8165 15.7411 13.5531 15.3459 13.1469 15.2647C12.7407 15.1835 12.3456 15.4469 12.2644 15.8531C12.0624 16.8632 11.2117 18.0786 9.97217 18.8674C8.81321 19.6049 7.40736 19.9124 6.00359 19.3939C5.3846 19.1238 4.82443 18.8134 4.35225 18.499C4.71355 18.2593 5.00905 18.0133 5.25292 17.7523C5.70574 17.2676 5.94237 16.7716 6.1147 16.2708C6.19901 16.0258 6.26761 15.7873 6.33719 15.5455C6.54762 14.814 6.76695 14.0516 7.45651 12.984C9.23149 10.236 12.097 9.88178 13.6628 10.6695C15.1453 11.4152 16.1162 12.7757 16.2351 14.2741C16.3519 15.7479 15.6541 17.5232 13.4539 19.1199C12.5941 19.7439 11.6465 20.0787 10.6728 20.1982Z"
      />
    </svg>
  );
};
