import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BackButton,
  Card,
  CardSpace,
  CardStyle,
  Container,
  FormControl,
  FormWrapper,
  Label,
  Paragraph,
  Input,
  InputSize,
} from "../../components";
import { ATTACK_PROTECTION_PATH } from "../../constants";
import { useFetch } from "../../hooks";
import { inputClasses, SEO } from "../../utils";
import { HeadlineStatus } from "./components";

interface ICaptcha {
  siteKey: string;
  secretKey: string;
  version: string;
}

export const BotDetection: FC = () => {
  const navigate = useNavigate();

  const { apiCall: getCaptchaSettings, response } = useFetch("get");
  const { apiCall: editCaptchaSettings, loading } = useFetch("post");

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta - Bot Detection",
    });

    getCaptchaSettings(`Users/CaptchaSettings`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const saveCapchaSettings: SubmitHandler<any> = (data: ICaptcha) => {
    editCaptchaSettings("Users/UpdateCaptchaSettings", data, () => {
      toast.success("Captcha updated successfully!");
      navigate(ATTACK_PROTECTION_PATH);
    });
  };

  const watchStatus = watch("isEnabled");
  const watchCapchaVersion = watch("version");

  return (
    <div>
      <Container>
        <BackButton
          to={ATTACK_PROTECTION_PATH}
          className="mb-4"
          label="Back to Attack Protection"
        >
          <></>
        </BackButton>
        <HeadlineStatus
          control={control}
          title="Bot Detection"
          description="Enable CAPTCHA to detect and block bots and scripted attacks."
          statusEnabled={watchStatus}
          toggleId="bott-detection"
          toggleName="isEnabled"
        />
      </Container>

      <FormWrapper
        onSubmit={handleSubmit(saveCapchaSettings)}
        className="mt-6"
        loading={loading}
      >
        <div className="mb-6">
          <h6 className="text-sm text-primary-mainText font-medium">
            Enforce CAPTCHA
          </h6>
          <Paragraph>
            Block suspected bot traffic by requiring a CAPTCHA during the login
            process.
          </Paragraph>
        </div>

        <Label text="Choose CAPTCHA version:" />
        <Card
          cardSpace={CardSpace.none}
          cardStyle={CardStyle.bordered}
          className="mb-2"
        >
          <label htmlFor="v2" className="p-4 flex justify-between">
            <div>
              <h6 className="text-sm text-primary-mainText font-medium">
                CAPTCHA V2
              </h6>
              <Paragraph>
                Users are not required to complete a CAPTCHA to log in.
              </Paragraph>
            </div>

            <input {...register("version")} type="radio" id="v2" value="v2" />
          </label>

          {watchCapchaVersion === "v2" && (
            <div className="px-4 pb-4 pt-2 inline-block w-full">
              <FormControl>
                <Label required text="Site Key" htmlFor="siteKeyV2" />
                <Input
                  autoFocus
                  {...register("siteKeyV2", {
                    required: "Site Key is required",
                  })}
                  error={!!errors.siteKeyV2 && errors.siteKeyV2.message}
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  type="text"
                  maxLength={300}
                />
              </FormControl>

              <FormControl lastChild>
                <Label required text="Secret Key" htmlFor="secretKeyV2" />
                <Input
                  {...register("secretKeyV2", {
                    required: "Secret Key is required",
                  })}
                  error={!!errors.secretKeyV2 && errors.secretKeyV2.message}
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  type="text"
                  maxLength={300}
                />
              </FormControl>
            </div>
          )}
        </Card>

        <Card cardSpace={CardSpace.none} cardStyle={CardStyle.bordered}>
          <label htmlFor="v3" className="p-4 flex justify-between">
            <div>
              <h6 className="text-sm text-primary-mainText font-medium">
                CAPTCHA V3
              </h6>
              <Paragraph>
                Users are not required to complete a CAPTCHA to log in.
              </Paragraph>
            </div>

            <input
              {...register("version")}
              // defaultChecked={ === "Disabled"}
              type="radio"
              id="v3"
              value="v3"
            />
          </label>

          {watchCapchaVersion === "v3" && (
            <div className="px-4 pb-4 pt-2 inline-block w-full">
              <FormControl>
                <Label required text="Site Key" htmlFor="siteKeyV3" />
                <Input
                  autoFocus
                  {...register("siteKeyV3", {
                    required: "Site Key is required",
                  })}
                  error={!!errors.siteKeyV3 && errors.siteKeyV3.message}
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  type="text"
                  maxLength={300}
                />
              </FormControl>

              <FormControl lastChild>
                <Label required text="Secret Key" htmlFor="secretKeyV3" />
                <Input
                  {...register("secretKeyV3", {
                    required: "Secret Key is required",
                  })}
                  error={!!errors.secretKeyV3 && errors.secretKeyV3.message}
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  type="text"
                  maxLength={300}
                />
              </FormControl>
            </div>
          )}
        </Card>
      </FormWrapper>
    </div>
  );
};
