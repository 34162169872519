import { FC } from "react";
import { BtnSize, BtnStyle, BtnType, Button } from "../../../../components";
import { cancelBtnClasses, cx, primaryBtnClasses } from "../../../../utils";

interface ISortableAppFooter {
  onSaveBtnClick: () => void;
  onCancelBtnClick: () => void;
  loading: boolean;
  disabled?: boolean;
}

export const SortableAppFooter: FC<ISortableAppFooter> = ({
  onSaveBtnClick,
  onCancelBtnClick,
  disabled,
  loading,
}) => {
  return (
    <div className="border-t border-primary-stroke pt-4 mt-auto sticky bottom-0 bg-white pb-2">
      <Button
        type="button"
        onClick={onSaveBtnClick}
        btnStyle={BtnStyle.primary}
        btnSize={BtnSize.normal}
        className={cx(["flex items-center w-full", primaryBtnClasses])}
        disabled={disabled || loading}
      >
        {loading && (
          <img
            src="/images/oval.svg"
            alt="Spinner"
            width={16}
            className="mr-2"
          />
        )}
        Save
      </Button>

      <Button
        btnType={BtnType.secondary}
        btnStyle={BtnStyle.neutral}
        btnSize={BtnSize.normal}
        className={cx([cancelBtnClasses, "w-full mt-2"])}
        type="button"
        onClick={onCancelBtnClick}
      >
        Cancel
      </Button>
    </div>
  );
};
