import { useEffect } from "react";
import { Secrets } from "../../../components";
import { API_RES_PATH } from "../../../constants";
import { SEO } from "../../../utils";

export const ApiResourceSecrets = () => {
  useEffect(() => {
    SEO({
      title: "Porta - API Resource Secrets",
    });
  }, []);

  return (
    <Secrets
      endpoint="/ApiResources"
      mainTitle="API Resource Secrets"
      backTo={API_RES_PATH}
    />
  );
};
