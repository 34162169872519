import { FC, useEffect } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import Select from "react-select";

import {
  Checkbox,
  Input,
  InputSize,
  Message,
  MessageTypes,
} from "../../../../components";
import { cx, inputClasses, reactSelectStyle } from "../../../../utils";
import { IProperty, propertyIState } from "../../interface";
import { ConstTypes, useConstant } from "../../utils/useConstant";
import { AddNewButton } from "../model-form-ui/AddNewButton";
import { DefaultProperties } from "../model-form-ui/DefaultProperties";
import { RemoveButton } from "../model-form-ui/RemoveButton";

interface IModelProperties {
  propertiesData?: IProperty[];
  isFromEdit?: boolean;
}

export const ModelProperties: FC<IModelProperties> = ({
  propertiesData,
  isFromEdit,
}) => {
  const { constTypes: inputTypes } = useConstant(ConstTypes.inputTypes);
  const { constTypes: designationTypes } = useConstant(
    ConstTypes.designationTypes
  );
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "properties",
  });

  useEffect(() => {
    isFromEdit && replace(propertiesData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replace, propertiesData]);

  const addNewProperty = () => {
    append(propertyIState);
  };

  const removeProperty = (index: number) => {
    remove(index);
  };

  const [properties] = useWatch({
    name: ["properties"],
  });

  const propertiesLength = propertiesData?.length || 0;

  const removePropertyButton = (condition: boolean, index: number) => {
    if (condition)
      return <RemoveButton onClick={() => removeProperty(index)} />;
    return <div className="w-6 h-6"></div>;
  };

  const filteredDesignationsTypes = designationTypes?.filter(
    (designationType) => {
      return !properties?.find((property: IProperty) => {
        return property?.designationType?.name === designationType?.name;
      });
    }
  );
  const propertiesName = properties?.map((property: any) => property.name);

  return (
    <div>
      <div className="flex items-center border-b border-primary-stroke justify-between mt-8 pb-2 gap-4">
        <div className="text-primary-secText font-medium w-80 text-sm flex-shrink-0">
          Property Name
        </div>
        <div className="text-primary-secText font-medium max-w-xs w-80 text-sm min-w-0">
          Data Type
        </div>
        <div className="text-primary-secText font-medium max-w-xs w-80 text-sm min-w-0">
          Designation Type
        </div>
        <div className="text-primary-secText font-medium max-w-xs w-24 text-sm text-center flex-shrink-0">
          Required
        </div>
        <div className="w-6 flex-shrink-0"></div>
      </div>

      <div className="mt-4">
        {!isFromEdit && <DefaultProperties />}
        {fields?.map((field, index) => {
          if (index === 0 && !isFromEdit) {
            properties && setValue("modelId", properties[index]?.name);
          }
          return (
            <div
              className="flex justify-between py-2 px-1 mb-3 hover:bg-gray-50 transition rounded gap-4"
              key={field.id}
            >
              <div className="w-80">
                <Input
                  {...register(`properties.${index}.name`, {
                    required: "This field is required",
                    validate: {
                      notLowerCase: (value: string) =>
                        /^[a-z_]+$/.test(value) ||
                        "Property must be lowercase, can't contain spaces, numbers or special characters",
                      notValidPropertyName: (value: string) =>
                        propertiesName.indexOf(value) ===
                          propertiesName.lastIndexOf(value) ||
                        "Property Name needs to be unique",
                    },
                  })}
                  inputSize={InputSize.sm}
                  className={inputClasses}
                  type="text"
                  disabled={isFromEdit && index + 1 <= propertiesLength}
                  maxLength={300}
                  placeholder={
                    index === 0 ? "Enter Model Id" : "Enter Property Name"
                  }
                  error={
                    !!errors.properties?.[index]?.name &&
                    errors.properties?.[index]?.name?.message
                  }
                />
              </div>

              <div className="flex-1 max-w-xs">
                <Controller
                  control={control}
                  name={`properties.${index}.inputType`}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, ref, value } }) => (
                    <Select
                      options={inputTypes || []}
                      getOptionLabel={(x) => x?.name}
                      getOptionValue={(x) => x?.id}
                      isDisabled={
                        (isFromEdit && index + 1 <= propertiesLength) ||
                        index === 0
                      }
                      isSearchable
                      ref={ref}
                      value={!!value?.name ? value : null}
                      menuPosition="fixed"
                      portalPlacement="auto"
                      onChange={onChange}
                      className={cx([
                        "text-sm",
                        errors.properties?.[index]?.inputType &&
                          "invalid-field",
                      ])}
                      classNamePrefix="porta-react-select"
                      placeholder="Select Data Type"
                      styles={reactSelectStyle}
                    />
                  )}
                />

                {errors?.properties?.[index]?.inputType && (
                  <Message
                    containerClassName="mt-2"
                    type={MessageTypes.error}
                    message="Data Type is required."
                  />
                )}
              </div>

              <div className="flex-1 max-w-xs">
                <Controller
                  control={control}
                  name={`properties.${index}.designationType`}
                  // rules={{
                  //   required:
                  //     (isFromEdit && index + 1 <= propertiesLength) ||
                  //     index === 0
                  //       ? false
                  //       : true,
                  // }}
                  render={({ field: { onChange, ref, value } }) => (
                    <Select
                      options={filteredDesignationsTypes || []}
                      getOptionLabel={(x) => x?.name}
                      getOptionValue={(x) => x?.id}
                      isDisabled={
                        (isFromEdit && index + 1 <= propertiesLength) ||
                        index === 0
                      }
                      isSearchable
                      ref={ref}
                      value={!!value?.name ? value : null}
                      menuPosition="fixed"
                      portalPlacement="auto"
                      onChange={onChange}
                      className={cx([
                        "text-sm",
                        // errors.properties?.[index]?.designationType &&
                        //   "invalid-field",
                      ])}
                      classNamePrefix="porta-react-select"
                      placeholder="Select Designation"
                      styles={reactSelectStyle}
                    />
                  )}
                />
                {/* 
                {errors?.properties?.[index]?.designationType && (
                  <Message
                    containerClassName="mt-2"
                    type={MessageTypes.error}
                    message="Designation type is required."
                  />
                )} */}
              </div>

              {!isFromEdit && (
                <div className="w-24 text-center">
                  <Controller
                    control={control}
                    name={`properties.${index}.isRequired`}
                    render={({ field: { onChange, value, ref } }: any) => (
                      <Checkbox
                        label=""
                        id={`required-${index}`}
                        disabled={
                          (isFromEdit && index + 1 <= propertiesLength) ||
                          index === 0
                        }
                        checked={value || false}
                        inputRef={ref}
                        onChange={onChange}
                        wrapperClassName="mt-3"
                      />
                    )}
                  />
                </div>
              )}

              {isFromEdit && (
                <>
                  {index + 1 <= propertiesLength ? (
                    <div className="w-24 text-center">
                      <Controller
                        control={control}
                        name={`properties.${index}.isRequired`}
                        render={({ field: { onChange, value, ref } }: any) => (
                          <Checkbox
                            label=""
                            id={`required-${index}`}
                            disabled={
                              (isFromEdit && index + 1 <= propertiesLength) ||
                              index === 0
                            }
                            checked={value || false}
                            inputRef={ref}
                            onChange={onChange}
                            wrapperClassName="mt-3"
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className="w-24"></div>
                  )}
                </>
              )}

              {isFromEdit
                ? removePropertyButton(!(index + 1 <= propertiesLength), index)
                : removePropertyButton(index + 1 > 2, index)}
            </div>
          );
        })}

        <AddNewButton text="Add Property" onClick={addNewProperty} />
      </div>
    </div>
  );
};
