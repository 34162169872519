import { FC } from "react";
import { Initials, Td } from "../../../../components";
import { IModel } from "../../interface";
import { Status } from "./Status";

interface IModelListItemDefault {
  el: IModel;
}

export const ModelListItemDefault: FC<IModelListItemDefault> = ({ el }) => {
  return (
    <tr className="transition hover:bg-gray-50">
      <Td className="py-3">
        <div className="flex items-center transition">
          <Initials text={el?.name} className="mr-4" />
          {el.name}
        </div>
      </Td>
      {/* <Td className="relative py-3">{el?.modelType}</Td> */}
      <Td className="relative py-3"></Td>
      <Td className="py-3">
        <Status
          label={el?.isPublished ? "Published" : "Unpublished"}
          isPublished={el?.isPublished}
        />
      </Td>
      <Td></Td>
      <Td></Td>
    </tr>
  );
};
