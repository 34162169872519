import { FC, KeyboardEvent, useState, ChangeEvent, useEffect } from "react";

import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch } from "../../../hooks";
import { IRole, IUser, IUsersAll } from "../../../interfaces";
import { getUserInfo, SEO, userIState, usersIState } from "../../../utils";
import { Dropdown } from "../../../pages/clients/api-resources/components";
import {
  DropdownOption,
  Modal,
  ModalSize,
  ThreeDotsButton,
  SearchHeader,
  Table,
  Td,
  PageHeadeline,
  Avatar,
  AvatarSize,
  AvatarRounded,
} from "../../../components";
import { USERS_PATH } from "../../../constants";
import { AssignRoleModal, UserBlockModal, UserCreateModal } from "./components";
import { MainContainer } from "../../../components/layout/MainContainer";

export const Users: FC = () => {
  const [activeModal, setActiveModal] = useState("");
  const {
    profile: { sub },
  } = getUserInfo();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [response, setResponse] = useState<IUsersAll>(usersIState);
  const [user, setUser] = useState<IUser>(userIState);

  const { users, totalCount } = !!response && response;
  const { currentPage, handlePageChange, setCurrentPage } = useCurrentPage();

  const { apiCall: getUsers, loading, errors } = useFetch("get");
  const { apiCall: assignUserRoles, loading: assignRolesLoading } =
    useFetch("post");
  const { apiCall: deleteUser, loading: deleteUserLoading } =
    useFetch("delete");
  const {
    apiCall: sendVerificationEmail,
    loading: sendVerificationEmailLoading,
  } = useFetch("post");

  const PER_PAGE = 10;

  const getUsersData = () => {
    getUsers(
      `Users/v1?searchText=${searchTerm}&page=${currentPage}&pageSize=${PER_PAGE}`,
      {},
      (response) => {
        setResponse(response);
      }
    );
  };

  useEffect(() => {
    getUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    SEO({
      title: "Porta - Users",
    });
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value);
  };

  // TODO: should move these functions to Search component, or SearchHeader
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getUsersData();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getUsersData();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  const closeModal = () => {
    setActiveModal("");
  };

  const onDeleteUser = () => {
    deleteUser(`/Users/${user.id}`, {}, () => {
      closeModal();
      setCurrentPage((prev) => prev - 1);
      toast.success("User deleted successfully!");
    });
  };

  const onAssignUserRoles = (roles: IRole[]) => {
    let selectedRolesArr: any = [];

    roles?.map((role) =>
      selectedRolesArr.push({
        userId: user.id,
        roleId: role?.id,
      })
    );

    assignUserRoles(`/Users/Roles`, selectedRolesArr, () => {
      closeModal();
      toast.success("User role set successfully!");
    });
  };

  const onSendVerificationEmail = (firstName: string, email: string) => {
    sendVerificationEmail(
      `/Users/SendVerificationEmail?email=${email}&firstName=${firstName}`,
      {},
      () => {
        toast.success("Email verification sent!");
      }
    );
  };

  const emptySectionText =
    !!searchTerm && users?.length < 1
      ? `No user found.`
      : "There are no users yet.";

  return (
    <MainContainer>
      <PageHeadeline
        title="Users"
        description="An easy to use interface to help administrators manage user identities including password resets, MFA method resets, blocking and deleting users."
        className="mb-10"
      />

      <SearchHeader
        onClearInputBtn={handleClearInputBtn}
        onSearchBtnClick={handleSearchBtnClick}
        onSearchKeypress={handleKeyPress}
        searchValue={searchTerm}
        onSearchChange={handleSearch}
        onButtonClick={() => setActiveModal("create-user")}
        buttonLabel="Create User"
      />

      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Name" },
          { children: "Email" },
          { children: "Latest Login" },
          { children: "Identity Provider" },
          { children: "Actions", className: "text-right" },
        ]}
        values={users}
        renderTr={(el: IUser, index) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.id}>
              <Td className="py-3">
                <NavLink
                  to={`${USERS_PATH}/${el.id}/user-details`}
                  className="hover:text-primary transition"
                  state={{
                    index: el.colorId,
                  }}
                >
                  <div className="flex items-center">
                    <Avatar
                      imgUrl={el?.picture}
                      text={el?.firstName || el?.email}
                      size={AvatarSize.sm}
                      rounded={AvatarRounded["rounded-full"]}
                      className="mr-4"
                      colourful
                      index={el.colorId}
                    />
                    {el?.firstName || "-"} {el?.lastName || "-"}
                  </div>
                </NavLink>
              </Td>
              <Td className="relative py-3">{el.email}</Td>
              <Td className="py-3">
                {el?.lastLogin
                  ? format(new Date(el?.lastLogin), "MMMM dd, yyyy")
                  : null}
              </Td>
              <Td className="py-3">{el?.identityProvider}</Td>
              <Td className="text-primary-secText py-3" align="right">
                <Dropdown
                  width="w-48"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        isLink
                        to={`${USERS_PATH}/${el.id}/user-details`}
                        label=" View Details"
                      />

                      <DropdownOption
                        onClick={() => {
                          setUser(el);
                          setActiveModal("assign-role");
                        }}
                        label="Assign Role"
                      />

                      <DropdownOption
                        onClick={() =>
                          onSendVerificationEmail(el?.firstName, el?.email)
                        }
                        label="Send Verification Email"
                        loading={sendVerificationEmailLoading}
                      />

                      <DropdownOption
                        disabled={sub === el?.id}
                        onClick={() => {
                          setUser(el);
                          setActiveModal("block-user");
                        }}
                        label={el.isBlocked ? "Unblock" : "Block"}
                      />

                      <DropdownOption
                        disabled={sub === el?.id}
                        onClick={() => {
                          setUser(el);
                          setActiveModal("delete-user");
                        }}
                        label="Delete"
                      />
                    </>
                  }
                >
                  <ThreeDotsButton />
                </Dropdown>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: users?.length,
        }}
        emptyView={{ text: errors || emptySectionText }}
      />

      {activeModal === "create-user" && (
        <UserCreateModal
          toggle={closeModal}
          visible={activeModal === "create-user"}
          setCurrentPage={setCurrentPage}
          response={response}
          setResponse={setResponse}
        />
      )}

      {activeModal === "block-user" && (
        <UserBlockModal
          user={user}
          response={response}
          setResponse={setResponse}
          visible={activeModal === "block-user"}
          toggle={closeModal}
        />
      )}

      {activeModal === "assign-role" && (
        <AssignRoleModal
          passSelectedRoles={(roles: IRole[]) => onAssignUserRoles(roles)}
          visible={activeModal === "assign-role"}
          toggle={closeModal}
          loading={assignRolesLoading}
        />
      )}

      {activeModal === "delete-user" && (
        <Modal
          hide={closeModal}
          visible={activeModal === "delete-user"}
          title="Delete user"
          onConfirmClick={onDeleteUser}
          confirmBtnText="Delete"
          warningModal
          modalSize={ModalSize.sm}
          loading={deleteUserLoading}
          withFooter
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">
              {user?.firstName} {user?.lastName}
            </strong>
            ?
          </p>
        </Modal>
      )}
    </MainContainer>
  );
};
