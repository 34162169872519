  interface IUserInfo {
    access_token: string;
    expires_at: number;
    id_token: string;
    profile: {
      birthdate: string;
      gender: string;
      given_name: string;
      preferred_username: string;
      family_name: string;
      language_code: string;
      name: string;
      role: string[];
      sub: string;
      picture: string;
      email: string;
    };
}

const userInfoIState = {
  access_token: "",
  expires_at: 0,
  id_token: "",
  profile: {
    birthdate: "",
    gender: "",
    preferred_username: "",
    given_name: "",
    family_name: "",
    language_code: "",
    name: "",
    role: [],
    sub: "",
    picture: "",
    email: ""
  },
};

export const getUserInfo = ():IUserInfo => {
  let access_token_value:IUserInfo = userInfoIState;

  const _user = window.localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`);
      if (_user) {
    
          const { ...rest } = JSON.parse(_user);
          if (rest) {
            access_token_value = rest
          }
      }

  return access_token_value;
}