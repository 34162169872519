import { useEffect, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch, useToggle } from "../../hooks";
import { logIState, SEO } from "../../utils";
import { LogsDetailModal } from "../user-management/users/components/LogsDetailModal";
import { ILog } from "../../interfaces";
import { Eye } from "../../components/Icons";
import DeleteLogsTopBar from "../../components/ui/DeleteLogsTopBar";
import { LogsWrapper } from "./components";
import { Table, Td } from "../../components";

export const AuditLogs: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState(null);
  const [log, setLog] = useState<ILog>(logIState);

  const { toggle, visible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();

  const {
    apiCall: getAuditLogs,
    response: auditLogsResponse,
    loading: logsLoading,
    errors,
  } = useFetch("get");

  const {
    apiCall: deleteLogs,
    loading,
    response: deletedResponse,
  } = useFetch("post");

  useEffect(() => {
    SEO({
      title: "Porta - Audit Logs",
    });
  }, []);

  useEffect(() => {
    getAuditLogs(
      `Log/AuditLogSearch?search=${searchTerm}&page=${currentPage}&pageSize=10`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchTerm, deletedResponse]);

  const onDeleteLogs = () => {
    // () => deleteLogs({ deleteOlderThan: startDate })
    deleteLogs(`Log/DeleteAuditLogs`, { deleteOlderThan: startDate }, () => {
      toast.success("Logs deleted successfully!");
    });
  };

  const emptySectionText =
    !!searchTerm && auditLogsResponse?.logs?.length < 1
      ? `No log found.`
      : "No logs yet.";

  return (
    <LogsWrapper
      title="Audit Logs"
      description="Storage of log data of authentications made by your users"
    >
      <DeleteLogsTopBar
        setStartDate={setStartDate}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        onDeleteLogs={onDeleteLogs}
        startDate={startDate}
        deleteLoading={loading}
      />

      <Table
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Event" },
          { children: "Source" },
          { children: "Date" },
          { children: "Details", className: "text-right" },
        ]}
        values={auditLogsResponse?.logs}
        loading={logsLoading}
        renderTr={(el) => {
          return (
            <tr className="transition hover:bg-gray-50 group" key={el?.id}>
              <Td>{el.event}</Td>
              <Td className="relative">{el?.source}</Td>
              <Td className="relative">
                {format(new Date(el?.created), "MM/dd/yyyy '-' h:mm:ss a")}
              </Td>
              <Td align="right">
                <button
                  type="button"
                  className="text-primary-secText group-hover:text-primary text-xs flex items-center hover:text-primary-mainText"
                  title="View Details"
                  onClick={() => {
                    toggle();
                    setLog(el);
                  }}
                >
                  <Eye />
                  <span className="ml-1">View</span>
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: auditLogsResponse?.pageSize,
          totalCount: auditLogsResponse?.totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: auditLogsResponse?.logs?.length,
        }}
        emptyView={{ text: errors || emptySectionText }}
      />

      {!!visible && (
        <LogsDetailModal visible={visible} toggle={toggle} log={log} />
      )}
    </LogsWrapper>
  );
};
