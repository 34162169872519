import { ReactNode } from "react";
import { Paragraph, ParagraphSize, SectionTitle } from "../../../components";
import { MainContainer } from "../../../components/layout/MainContainer";

interface ILogsWrapper {
  title: string;
  description: string;
  children: ReactNode;
}

export const LogsWrapper: React.FC<ILogsWrapper> = ({
  children,
  title,
  description,
}) => {
  return (
    <MainContainer>
      <SectionTitle noMargin>{title}</SectionTitle>
      <Paragraph className="mb-6" parahraphSize={ParagraphSize.sm}>
        {description}
      </Paragraph>

      {children}
    </MainContainer>
  );
};
