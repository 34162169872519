import { FC } from "react";

import { Controller } from "react-hook-form";

import { Status } from "../../clients/api-resources/components";
import { Paragraph, ParagraphSize, ToggleSwitch } from "../../../components";
import { cx } from "../../../utils";

interface IHeadlineStatus {
  className?: string;
  title: string;
  description?: string;
  toggleId: string;
  toggleName: string;
  control: any;
  statusEnabled: boolean;
  onToggleSwitchChange?: () => void;
}

export const HeadlineStatus: FC<IHeadlineStatus> = (props) => {
  return (
    <div className={cx(["flex justify-between", props.className])}>
      <div className="sm:w-4/5">
        <h4 className="flex items-center font-medium text-primary-mainText">
          {props.title}{" "}
          <Status enabled={props.statusEnabled} className="ml-4" />
        </h4>

        <Paragraph parahraphSize={ParagraphSize.sm}>
          {props.description}
        </Paragraph>
      </div>

      <Controller
        control={props.control}
        name={props.toggleName}
        render={({ field: { onChange, value, ref } }: any) => (
          <ToggleSwitch
            id={props.toggleId || ""}
            onChange={(value) => {
              onChange(value);
              props.onToggleSwitchChange && props.onToggleSwitchChange();
            }}
            checked={value || false}
            inputRef={ref}
          />
        )}
      />
    </div>
  );
};
