import { FC, ReactNode } from "react";
import { cx } from "../../utils";

import { SidebarMenu, SidebarMenuProps } from "./SidebarMenu";

export interface SidebarProps extends SidebarMenuProps {
  wrapperClassName?: string;
  renderBottomItem?: ReactNode;
}

export const Sidebar: FC<SidebarProps> = ({
  wrapperClassName,
  ulProps,
  list,
  renderBottomItem,
  ...props
}) => {
  return (
    <aside
      className={cx([
        "py-4 bg-white w-72 hidden overflow-auto md:flex flex-col justify-between fixed left-0 sidebar-wrapper border-r border-primary-stroke h-auto sidebar-wrapper",
        wrapperClassName,
      ])}
    >
      <SidebarMenu ulProps={ulProps} list={list} {...props} depth={0} />

      {renderBottomItem && (
        <div className="px-5">{renderBottomItem && renderBottomItem}</div>
      )}
    </aside>
  );
};
