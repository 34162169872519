import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { useFetch } from "../../../../hooks";
import { IArrangeApplication } from "../interface";
import { SortableApp, SortableAppFooter } from "./index";
import { cx } from "../../../../utils";
import { Loader } from "../../../../components";

let IApplicationIState = [
  {
    name: "",
    clientId: "",
    applicationOrderNumber: "",
    picture: "",
    id: 0,
  },
];

export const AppsArrangement: FC<{
  className?: string;
  toggle: () => void;
}> = ({ className, toggle }) => {
  const [applications, setApplications] =
    useState<IArrangeApplication[]>(IApplicationIState);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const { apiCall: getAppsOrdered, response, loading } = useFetch("get");
  const { apiCall: arrangeApps, loading: arrangeAppsLoading } =
    useFetch("post");

  useEffect(() => {
    getAppsOrdered(`ManageApi/GetApplicationsToDisplay?arrange=true`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const sortedByOrderNumber = response?.sort(
    //   (a: any, b: any) => a.applicationOrderNumber - b.applicationOrderNumber
    // );

    setApplications(response);
  }, [response]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setApplications((items) => {
        const oldIndex = items.findIndex((item) => {
          return item.id === active.id;
        });

        const newIndex = items.findIndex((item) => {
          return item.id === over.id;
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const onArrangeAppsBtnSave = () => {
    const applicationsResorted: any = {};

    applications?.map((app, index) => {
      return (applicationsResorted[app.id] = index + 1);
    });

    arrangeApps(
      "ManageApi/ArrangeApplicationsToDisplay",
      applicationsResorted,
      () => {
        toast.success("Apps arranged successfuly");
      }
    );
  };

  const SortableAppComponent = (props: any) => {
    return <SortableApp {...props} />;
  };

  return (
    <div
      className={cx([
        "border border-primary-stroke px-4 pt-4 rounded w-64 flex-shrink-0 flex flex-col flex-grow",
        className,
      ])}
    >
      <h5 className="text-sm">Arrange Apps</h5>
      <p className="mt-2 text-xs text-primary-secText">
        Drag and drop the applications displayed under user's My Account to
        rearrange the order they are displayed in.
      </p>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={applications || []}
          strategy={rectSortingStrategy}
        >
          {loading ? (
            <Loader />
          ) : (
            <div
              className="flex gap-3 flex-wrap mt-6 overflow-y-auto overflow-x-hidden"
              style={{
                maxHeight: `calc(100vh - 544px)`,
                minHeight: 140,
              }}
            >
              {applications?.map((item: IArrangeApplication, index) => (
                <SortableAppComponent {...item} key={index} index={index} />
              ))}
            </div>
          )}
        </SortableContext>
      </DndContext>

      <SortableAppFooter
        onSaveBtnClick={onArrangeAppsBtnSave}
        onCancelBtnClick={toggle}
        loading={arrangeAppsLoading}
      />
    </div>
  );
};
