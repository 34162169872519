import Select from "react-select";
import {
  Accordion,
  FormControl,
  Input,
  InputSize,
  Label,
  Textarea,
} from "../../../../components";
import {
  inputClasses,
  reactSelectStyle,
  textareaClasses,
} from "../../../../utils";
import { requestMethodTypes } from "../../dummy-data";
import {
  ActionOutputDetails,
  RequestHeadersDetails,
  VisibilitySettingsDetails,
} from "./index";

export const ModelActionsDetails = (props: any) => {
  return (
    <div>
      <h3 className="text-xl text-primary-mainText font-medium mb-4 mt-10">
        Model Actions
      </h3>
      {props?.actions?.map((action: any, index: number) => {
        return (
          <div className="flex justify-between mb-6" key={index}>
            <Accordion
              title={action?.name || `Action ${index + 1}`}
              className="flex-1"
              key={index}
              opened={index === 0 ? true : false}
              content={
                <>
                  <FormControl>
                    <Label text="Model Action Type" />
                    <Input
                      id="action-type"
                      inputSize={InputSize.sm}
                      className={inputClasses}
                      value={action?.actionType?.name}
                      readOnly
                      disabled
                    />
                  </FormControl>

                  {(action?.actionType?.name === "Hyperlink" ||
                    action?.actionType?.name === "Button") && (
                    <>
                      <FormControl>
                        <Label required text="URL" htmlFor="url" />
                        <Input
                          id="url"
                          inputSize={InputSize.sm}
                          className={inputClasses}
                          value={action?.url}
                          readOnly
                          disabled
                        />
                      </FormControl>

                      {/* Visibility Settings */}
                      {action?.showVisibilitySettings && (
                        <div className="mb-4">
                          <p className="text-primary-mainText mb-3">
                            Visibility Settings
                          </p>

                          <VisibilitySettingsDetails
                            visibilitySettings={action?.visibilitySettings}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Headers */}
                  {action?.actionType?.name === "Button" && (
                    <>
                      <div className="mt-4">
                        <p className="text-primary-mainText mb-3">Headers</p>
                        <RequestHeadersDetails headers={action?.headers} />
                      </div>

                      <FormControl className="mt-4">
                        <Label text="Request Method" />
                        <Input
                          value={
                            requestMethodTypes?.find(
                              (methodType) =>
                                methodType?.value === action?.methodType
                            )?.label
                          }
                          inputSize={InputSize.sm}
                          className={inputClasses}
                          readOnly
                          disabled
                        />
                      </FormControl>

                      <FormControl>
                        <Label text="Body" htmlFor="body" />
                        <Textarea
                          id="body"
                          className={textareaClasses}
                          value={action?.body}
                          readOnly
                          disabled
                        />
                      </FormControl>

                      <FormControl>
                        <Label text="Expected HTTP Status Code" />
                        <Select
                          noOptionsMessage={() =>
                            "No expected HTTP Status Code."
                          }
                          getOptionLabel={(x) => x}
                          getOptionValue={(x) => x}
                          value={action?.expectedSuccessStatusCodes}
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          className="text-sm"
                          classNamePrefix="porta-react-select"
                          placeholder="Expected HTTP Status Code"
                          styles={reactSelectStyle}
                          isMulti
                          isDisabled
                        />
                      </FormControl>

                      <FormControl>
                        <Label text="Error Message" htmlFor="errorMessage" />
                        <Input
                          id="errorMessage"
                          inputSize={InputSize.sm}
                          className={inputClasses}
                          value={action?.errorMessage}
                          readOnly
                          disabled
                        />
                      </FormControl>

                      {/* Action Output */}
                      {action?.addActionOutput && (
                        <div className="mt-8 mb-2">
                          <p className="text-primary-secText mb-1.5">
                            Action Output
                          </p>

                          <ActionOutputDetails outputs={action?.outputs} />
                        </div>
                      )}
                    </>
                  )}
                </>
              }
            />
          </div>
        );
      })}
    </div>
  );
};
