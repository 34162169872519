import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

import {
  FormControl,
  Modal,
  ModalSize,
  ToggleSwitch,
  Textarea,
  Label,
} from "../../../components";
import { CategoriesSelect } from "./CategoriesSelect";
import { CookieTag } from ".";
import { CookieStatus } from "./CookieTag";
import { textareaClasses } from "../../../utils";
import { ManageCookieForm } from "./ManageCookieForm";
import { useFetch } from "../../../hooks";
import { CMP_API_URL } from "../../../constants";
import { IDomainCategory } from "../interface";
import { ICookie } from "../../../interfaces";

interface IEditCookieModal {
  visible: boolean;
  toggle: () => void;
  cookie: ICookie;
  domainId: string;
  categories: IDomainCategory[];
  setCategories: Dispatch<SetStateAction<IDomainCategory[] | undefined>>;
}

export const EditCookieModal: FC<IEditCookieModal> = (props) => {
  const { apiCall: editCookie, loading } = useFetch("post");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const updateCookieCategory = (
    categories: IDomainCategory[],
    categoryName: string,
    prevCategoryName: string,
    cookieId: number,
    updatedCookie: ICookie
  ) => {
    return categories?.map((category) => {
      if (category.categoryName === categoryName) {
        if (prevCategoryName === categoryName) {
          return {
            ...category,
            cookies: {
              ...category.cookies,
              data: category.cookies.data.map((cookie) => {
                if (cookie.id === cookieId) {
                  return {
                    ...updatedCookie,
                  };
                } else {
                  return {
                    ...cookie,
                  };
                }
              }),
            },
          };
        } else {
          return {
            ...category,
            cookies: {
              ...category.cookies,
              data: [updatedCookie, ...category.cookies.data],
              pageSize: category.cookies?.totalCount + 1,
              totalCount: category.cookies?.totalCount + 1,
            },
          };
        }
      } else {
        if (
          category.categoryName === prevCategoryName &&
          prevCategoryName !== categoryName
        ) {
          return {
            cookies: {
              ...category.cookies,
              data: category.cookies.data.filter(
                (cookie) => cookie.id !== cookieId
              ),
              pageSize: category.cookies?.totalCount + 1,
              totalCount: category.cookies?.totalCount + 1,
            },
          };
        } else
          return {
            ...category,
          };
      }
    });
  };

  const onEditCookie: SubmitHandler<any> = (cookie: ICookie) => {
    const editCookieObj = {
      cookieDomain: cookie.cookieDomain,
      description: cookie.description,
      encryptedCategoryId:
        cookie?.category?.encryptedId || cookie.encryptedCategoryId,
      encryptedDomainId: props.domainId,
      encryptedId: cookie.encryptedId,
      expiration: cookie.expiration,
      expirationUnit: cookie.expirationUnit,
      isFromCrawler: cookie.isFromCrawler,
      is_httponly: cookie.is_httponly,
      is_secure: cookie.is_secure,
      name: cookie.name,
      path: cookie.path,
    };

    editCookie(`${CMP_API_URL}/EditCookie`, editCookieObj, () => {
      props.toggle();
      const cookieToEdit = {
        ...cookie,
        encryptedCategoryId:
          cookie?.category?.encryptedId || cookie.encryptedCategoryId,
        encryptedDomainId: props.domainId,
      };
      const updatedCookies = updateCookieCategory(
        props.categories,
        cookie?.category?.name || "",
        props.cookie.category?.name || "",
        props.cookie?.id,
        cookieToEdit
      ) as IDomainCategory[];
      props.setCategories(updatedCookies);
      toast.success("Cookie edited successfully!");
    });
  };

  useEffect(() => {
    reset(props.cookie);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.cookie]);

  return (
    <Modal
      hide={props.toggle}
      visible={props.visible}
      title="Edit Cookie"
      onConfirmClick={handleSubmit(onEditCookie)}
      confirmBtnText="Save cookie details"
      modalSize={ModalSize.lg}
      withFooter
      loading={loading}
      onCloseClick={props.toggle}
      blockOutsideClick
    >
      <p className="text-sm text-primary-mainText mb-6">
        You can manually add cookies to your cookie declaration
      </p>

      {props?.cookie?.isFromCrawler && (
        <>
          <div className="flex flex-wrap mb-6">
            <div className="sm:w-1/2 sm:flex-none flex-1">
              <div className="mb-6">
                <div className="text-sm text-primary-secText mb-2">Name</div>
                <p className="text-sm text-primary-mainText">
                  {props.cookie?.name}
                </p>
              </div>

              <div className="mb-6">
                <div className="text-sm text-primary-secText mb-2">
                  Hostname
                </div>
                <p className="text-sm text-primary-mainText">
                  {props.cookie?.cookieDomain}
                </p>
              </div>

              <div>
                <div className="text-sm text-primary-secText mb-2">Path</div>
                <p className="text-sm text-primary-mainText">
                  {props.cookie?.path}
                </p>
              </div>
            </div>

            <div className="sm:w-1/2 sm:flex-none flex-1">
              <div className="mb-6">
                <div className="text-sm text-primary-secText mb-2">Cookies</div>
                <p className="text-sm text-primary-mainText">sasadasd</p>
              </div>

              <div className="mb-6">
                <div className="text-sm text-primary-secText mb-2">
                  Detected on
                </div>
                <p className="text-sm text-primary-mainText">23/06/2021</p>
              </div>

              <div>
                <div className="text-sm text-primary-secText mb-2">Tags</div>
                <div className="inline-flex">
                  {props.cookie?.is_secure && <CookieTag text="Secure" />}

                  {props.cookie?.is_httponly && <CookieTag text=" HTTP only" />}

                  {props.cookie?.is_third_party && (
                    <CookieTag
                      text=" 3rd party"
                      cookieStatus={CookieStatus.warning}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <FormControl>
            <Controller
              control={control}
              name="hideDesclaration"
              render={({ field: { onChange, value, ref } }: any) => (
                <ToggleSwitch
                  id="hide-declaration"
                  label="Hide from cookie declaration"
                  onChange={onChange}
                  checked={value || false}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <CategoriesSelect
              domainId={props.domainId}
              control={control}
              errors={errors}
              cookie={props.cookie}
            />
          </FormControl>

          <FormControl description="What is the cookie used for, who’s the actial data collector and why should the user allow it? ">
            <Label text="Description" htmlFor="description" />
            <Textarea
              id="description"
              {...register("description")}
              maxLength={3000}
              className={textareaClasses}
            />
          </FormControl>
        </>
      )}

      {!props?.cookie?.isFromCrawler && (
        <ManageCookieForm
          control={control}
          errors={errors}
          register={register}
          domainId={props.domainId}
        />
      )}
    </Modal>
  );
};
