import { FC } from 'react'
import {
  FormControl,
  Input,
  InputSize,
  Label,
} from "../../../../components";
import {
  inputClasses,
} from "../../../../utils";
import { IVisibilitySettings } from '../../interface';

interface IVisibilitySettingsDetails {
  visibilitySettings: IVisibilitySettings[];
}

export const VisibilitySettingsDetails : FC<IVisibilitySettingsDetails> = ({
  visibilitySettings
}) => {

  return (
    <>
      {visibilitySettings?.map((property: any) => {
        return (
          <div
            className="grid gap-4 grid-cols-3"
            key={property.id}
          >
            <FormControl>
              <Label required text="Property Name"/>
              <Input
                inputSize={InputSize.sm}
                className={inputClasses}
                value={property?.propertyName || ''}
                readOnly
                disabled
              />
            </FormControl>
            <FormControl>
              <Label required text="Operator"/>
              <Input
                inputSize={InputSize.sm}
                className={inputClasses}
                value={property?.operator?.name}
                readOnly
                disabled
              />
            </FormControl>
            <FormControl>
              <Label required text="Value"/>
              <Input
                inputSize={InputSize.sm}
                className={inputClasses}
                value={property?.value || ''}
                readOnly
                disabled
              />
            </FormControl>
          </div>
        );
      })}
    </>
  )
}
