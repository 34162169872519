import { InputHTMLAttributes, useRef } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface ISearch extends InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string;
  onSearchBtnClick?: () => void;
  onClearInputBtn?: () => void;
}

const Search: React.FC<ISearch> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    props.onSearchBtnClick && props.onSearchBtnClick();

    inputRef.current && inputRef.current.focus();
  };

  const handleClearBtn = () => {
    props.onClearInputBtn && props.onClearInputBtn();

    inputRef.current && inputRef.current.focus();
  };

  return (
    <div className={cx(["relative w-64", props.wrapperClassName])}>
      <input
        type="text"
        ref={inputRef}
        {...props}
        className="placeholder-primary-tertText w-full search-input text-sm min-w-full rounded focus:outline-none focus:border-primary text-primary-mainText transition focus:shadow-primary hover:border-gray-400 border border-gray-300 py-2 pl-3"
      />
      {/* Clear Input Value Button */}
      {props.onClearInputBtn && props.value && (
        <button
          type="button"
          title="Clear"
          onClick={handleClearBtn}
          className="px-0 w-4 h-4 right-12 top-3 flex absolute items-center justify-center rounded-full bg-gray-200 border-l focus:bg-gray-300 border-primary-stroke text-gray-500 hover:text-primary-secText focus:text-primary-secText transition"
        >
          <span className="inline-block -ml-0.5">
            <Icon name="Close" size="18" />
          </span>
        </button>
      )}

      <button
        type="button"
        title="Search"
        onClick={handleClick}
        className="absolute search-button flex items-center justify-center w-10 h-full top-0 right-0 rounded-tr rounded-br bg-primary focus:bg-primary-hover text-white transition"
      >
        <span className="inline-block">
          <Icon name="Search" size="24" />
        </span>
      </button>
    </div>
  );
};

export default Search;
