import { FC } from "react";

//! BANNER POSITIONS
export const Center: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/3 h-1/2 mx-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-sm  `}></span>
    </div>
  );
};

export const Right: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/2 mx-auto bottom-2 right-2  transform rounded-sm h-3/4 `}></span>
    </div>
  );
};

export const Left: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/2 mx-auto bottom-2 left-2  transform rounded-sm h-3/4 `}></span>
    </div>
  );
};

export const Top: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-11/12 mx-auto top-2 left-1/2 -translate-x-1/2 transform rounded-sm h-2.5 `}></span>
    </div>
  );
};

export const Bottom: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-11/12 mx-auto bottom-2 left-1/2 -translate-x-1/2 transform rounded-sm h-2.5 `}></span>
    </div>
  );
};

export const BottomRight: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/3 mx-auto bottom-2 right-2  transform rounded-sm h-1/2 `}></span>
    </div>
  );
};

export const BottomLeft: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/3 mx-auto bottom-2 left-2 transform rounded-sm h-1/2 `}></span>
    </div>
  );
};

export const TopRight: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/3 mx-auto top-2 right-2  transform rounded-sm h-1/2 `}></span>
    </div>
  );
};

export const TopLeft: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-1/3 mx-auto top-2 left-2  transform rounded-sm h-1/2 `}></span>
    </div>
  );
};

//! BANNER WIDGETS POSITIONS
export const WidgetBottomLeft: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-3 h-3 mx-auto bottom-2 left-2 transform rounded-full  `}></span>
    </div>
  );
};
export const WidgetBottomRight: FC<any> = ({ selectedLayout }) => {
  return (
    <div className="relative bg-white h-20 px-2  mb-2  w-full rounded shadow-sm ">
      <span
        className={`absolute transition-all group-hover:bg-blue duration-300 ${
          selectedLayout ? "bg-blue" : "bg-gray-300"
        } w-3 h-3 mx-auto bottom-2 right-2 transform rounded-full `}></span>
    </div>
  );
};
