import { Checkbox, Input, InputSize } from "../../../../components";
import { cx, inputClasses } from "../../../../utils";
import { ConstTypes, useConstant } from "../../utils/useConstant";

export const DefaultProperties = () => {
  const { constTypes: defaultProperties } = useConstant(
    ConstTypes.defaultProperties
  );
  const { constTypes: inputTypes } = useConstant(ConstTypes.inputTypes);

  return (
    <>
      {defaultProperties?.map((property) => {
        const inputType = inputTypes?.find(
          (type: { id: number }) => type.id === property?.inputTypeId
        );

        return (
          <div
            className="flex justify-between py-2 px-1 mb-3 hover:bg-gray-50 transition rounded gap-4"
            key={property?.id}
          >
            <div className="w-80 flex-shrink-0">
              <Input
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
                disabled
                maxLength={300}
                value={property.name}
              />
            </div>
            <div className="flex-1 max-w-xs  min-w-0">
              <Input
                inputSize={InputSize.sm}
                className={cx([inputClasses, "w-full"])}
                type="text"
                disabled
                maxLength={300}
                value={property.inputType?.name}
              />
            </div>
            <div className="flex-1 max-w-xs min-w-0">
              <Input
                inputSize={InputSize.sm}
                className={cx([inputClasses, "w-full"])}
                type="text"
                disabled
                maxLength={300}
                value={inputType?.designation}
              />
            </div>
            <div className="w-24 text-center flex-shrink-0">
              <Checkbox
                label=""
                id="required"
                disabled
                checked={property?.isRequired}
                wrapperClassName="mt-3"
              />
            </div>
            <div className="w-6 flex-shrink-0"></div>
          </div>
        );
      })}
    </>
  );
};
