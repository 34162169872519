import { FC, KeyboardEvent, useState, ChangeEvent, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import { IRole, IRoles, IUser } from "../../../interfaces";
import { roleIState, rolesIState, SEO } from "../../../utils";
import { Dropdown } from "../../../pages/clients/api-resources/components";

import {
  DropdownOption,
  Modal,
  ModalSize,
  ThreeDotsButton,
  SearchHeader,
  Table,
  Td,
  PageHeadeline,
} from "../../../components";
import { ROLES_PATH } from "../../../constants";
import { RoleCreateModal } from "./components";
import { AssignUserModal } from "./components/AssignUserModal";
import {
  Can,
  Switch as PermissionsSwitch,
  onlyForRoles,
  Permission,
} from "../../../context";
import { MainContainer } from "../../../components/layout/MainContainer";

export const Roles: FC = () => {
  const { admin } = Permission;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [response, setResponse] = useState<IRoles>(rolesIState);
  const [role, setRole] = useState<IRole>(roleIState);

  const { toggle, visible } = useToggle();
  const { toggle: deleteRoleModalToggle, visible: deleteRoleModalVisible } =
    useToggle();
  const { toggle: assignUserModalToggle, visible: assignUserModalVisible } =
    useToggle();

  const { roles, totalCount } = !!response && response;
  const { currentPage, handlePageChange } = useCurrentPage();

  const { apiCall: assignRole, loading: assingRoleLoading } = useFetch("post");
  const { apiCall: getRoles, loading } = useFetch("get");
  const { apiCall: deleteRole, loading: deleteRoleLoading } =
    useFetch("delete");

  const PER_PAGE = 10;

  const onDeleteRole = () => {
    deleteRole(`/Roles/${role?.id}`, {}, () => {
      setResponse({
        ...response,
        roles: response?.roles.filter((item) => item.id !== role?.id),
      });
      deleteRoleModalToggle();
      toast.success("Role deleted successfully!");
    });
  };

  useEffect(() => {
    SEO({
      title: "Porta - Roles",
    });
  }, []);

  const getRolesData = () => {
    getRoles(
      `/Roles?searchText=${searchTerm}&page=${currentPage}&pageSize=${PER_PAGE}`,
      {},
      (response) => {
        setResponse(response);
      }
    );
  };

  useEffect(() => {
    getRolesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value);
  };

  // TODO: should move these functions to Search component, or SearchHeader
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRolesData();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getRolesData();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  const onAssignRole = (users: IUser[]) => {
    let userIds: string[] = [];

    users?.map((item: any) => userIds.push(item.id));

    assignRole(`/Roles/AssignUsersToRole?roleId=${role.id}`, userIds, () => {
      assignUserModalToggle();
      toast.success("Users assigned successfully!");
    });
  };

  const emptySectionText =
    !!searchTerm && roles?.length < 1
      ? `No role found.`
      : "There are no roles yet.";

  return (
    <MainContainer>
      <PageHeadeline
        title="Roles"
        description="Create and manage Roles for your applications that can be assigned to users."
        className="mb-10"
      />

      <SearchHeader
        searchValue={searchTerm}
        onClearInputBtn={handleClearInputBtn}
        onSearchBtnClick={handleSearchBtnClick}
        onSearchKeypress={handleKeyPress}
        onSearchChange={handleSearch}
        onButtonClick={toggle}
        buttonLabel="Create Role"
        permissions={[admin]}
      />

      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Name" },
          { children: "Description" },
          ...(onlyForRoles([admin])
            ? [{ children: "Actions", className: "text-right" }]
            : []),
        ]}
        values={roles}
        renderTr={(el: IRole) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.id}>
              <Td>
                <NavLink
                  to={`${ROLES_PATH}/${el.id}/${
                    onlyForRoles([admin]) ? "settings" : "users"
                  }`}
                  className="hover:text-primary"
                >
                  <div className="flex items-center">{el?.name}</div>
                </NavLink>
              </Td>
              <Td className="relative">{el?.description}</Td>
              <PermissionsSwitch>
                <Can permissions={[admin]}>
                  <Td className="text-primary-secText" align="right">
                    <Dropdown
                      width="w-44"
                      noPadding
                      dropdownContent={
                        <>
                          <DropdownOption
                            onClick={() => {
                              assignUserModalToggle();
                              setRole(el);
                            }}
                            label="Assign to Users"
                          />

                          <DropdownOption
                            onClick={() => {
                              deleteRoleModalToggle();
                              setRole(el);
                            }}
                            label="Delete Role"
                          />
                        </>
                      }
                    >
                      <ThreeDotsButton />
                    </Dropdown>
                  </Td>
                </Can>
              </PermissionsSwitch>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: roles?.length,
        }}
        emptyView={{ text: emptySectionText }}
      />

      {visible && (
        <RoleCreateModal
          toggle={toggle}
          visible={visible}
          response={response}
          setResponse={setResponse}
        />
      )}

      {deleteRoleModalVisible && (
        <Modal
          hide={deleteRoleModalToggle}
          visible={deleteRoleModalVisible}
          title="Delete role"
          onConfirmClick={onDeleteRole}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={deleteRoleLoading}
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">{role?.name}</strong>?
          </p>
        </Modal>
      )}

      {assignUserModalVisible && (
        <AssignUserModal
          toggle={assignUserModalToggle}
          visible={assignUserModalVisible}
          passSelectedUsers={onAssignRole}
          role={role}
          loading={assingRoleLoading}
        />
      )}
    </MainContainer>
  );
};
