import {
  useState,
  useContext,
  useMemo,
  createContext,
  FC,
  isValidElement,
  Children,
  ReactNode,
  useEffect,
} from "react";
import { useAuth } from "oidc-react";

import { getUserInfo } from "../utils";

export enum Permission {
  admin = "SuperAdmin",
  usersEditor = "EditorUsers",
  appsEditor = "EditorSpecificApps",
}

export const SystemRoles: Permission[] = Object.values(Permission);

type PermissionContextValue = {
  permissions: Permission[];
};

export const PermissionsContext = createContext<PermissionContextValue | null>(
  null
);

export const PermissionsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const auth = useAuth();
  const [permissions, setPermissions] = useState<Permission[] | any>([""]);

  const {
    profile: { role },
  } = getUserInfo();

  useEffect(() => {
    setPermissions((prev: any) => (prev = role));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const value = useMemo(() => {
    return { permissions };
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const pc = useContext(PermissionsContext);
  if (pc === null) {
    throw new Error("usePermissions must be inside of PermissionsProvider");
  }
  return pc;
};

interface CanProps {
  permissions?: Permission | Permission[];
  children: ReactNode;
}

export const checkMatch = (
  userPermissions: Permission[],
  canProps: CanProps
) => {
  let match = false;
  const { permissions = [] } = canProps;
  const permissionsArr = Array.isArray(permissions)
    ? permissions
    : [permissions];
  if (permissionsArr.length === 0) {
    match = true;
  } else {
    match = permissionsArr?.some((p) => userPermissions?.includes(p));
  }
  return match;
};

export const Can: FC<CanProps> = (props) => {
  const { children } = props;
  const { permissions: userPermissions } = usePermissions();
  const match = checkMatch(userPermissions, props);

  if (match) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export const Switch: FC<{ children: ReactNode }> = ({ children }) => {
  const { permissions: userPermissions } = usePermissions();

  let element: ReactNode = null;
  let match = false;

  Children.forEach(children, (child) => {
    if (!match && isValidElement(child) && child.type === Can) {
      element = child;
      match = checkMatch(userPermissions, child.props as CanProps);
    }
  });

  return match ? element : null;
};

export const onlyForRoles = (permissionRoles: Permission[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { permissions } = usePermissions();

  const userPermissionsArr = Array.isArray(permissions)
    ? permissions
    : [permissions];

  const userPermissions = userPermissionsArr?.filter((role) =>
    permissionRoles?.includes(role)
  );

  return userPermissions.length > 0;
};
