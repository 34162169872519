import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";

import { format } from "date-fns";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  DropdownOption,
  Modal,
  PageHeadeline,
  SearchHeader,
  Table,
  Td,
  ThreeDotsButton,
} from "../../components";
import { useCurrentPage, useFetch, useToggle } from "../../hooks";
import { IAppDataTableItem } from "../../interfaces";
import { Dropdown, Status } from "../../pages/clients/api-resources/components";
import { MainContainer } from "../layout/MainContainer";
import { ModalSize } from "../modal";
import { showCurrentTableItems, showCurrentTags } from "./functions";
import { IAppTable, ICurrentItem } from "./interface";
import { LimitedTagsDropdown } from "./LimitedTagsDropdown";

export const AppDataTable: FC<IAppTable> = (props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<ICurrentItem>({
    id: 0,
    name: "",
  });
  const [response, setResponse] = useState<any>();

  const { toggle, visible } = useToggle();
  const { totalCount, items } = !!response && response;
  const { currentPage, handlePageChange } = useCurrentPage();
  const { apiCall: getTableItems, loading, errors } = useFetch("get");
  const { apiCall: deleteTableItem, loading: deleteTableItemLoading } =
    useFetch("delete");
  const navigate = useNavigate();

  const PER_PAGE = 10;

  const onDeleteTableItem = () => {
    deleteTableItem(`${props.endpoint}/${currentItem?.id}`, {}, () => {
      toast.success(props.deleteSuccessMessage);
      setShouldUpdate((current) => !current);

      // const filteredItems = showCurrentTableItems(
      //   props.itemsKey,
      //   response
      // )?.filter((item: any) => item.id !== currentItem?.id);

      // setResponse({
      //   ...response,
      //   items: filteredItems,
      //   totalCount: response?.totalCount - 1,
      // });

      toggle();
    });
  };

  const allTableDataHead = [
    { children: "Name" },
    { children: props.itemsKey === "apiResources" ? "Scopes" : "User Claims" },
    { children: "Status" },
    { children: "Date" },
    { children: "Actions", className: "text-right" },
  ];

  const lessTableDataHead = [
    { children: "Name" },
    { children: "User Claims" },
    { children: "Status" },
    { children: "Actions", className: "text-right" },
  ];

  const getTableData = () => {
    getTableItems(
      `${props.endpoint}?${props.searchKey}=${searchTerm}&page=${currentPage}&pageSize=${PER_PAGE}`,
      {},
      (response) => {
        setResponse({
          ...response,
          items: showCurrentTableItems(props.itemsKey, response),
        });
      }
    );
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, shouldUpdate]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value);
  };

  // TODO: should move these functions to Search component, or SearchHeader
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getTableData();
    }
  };

  const handleSearchBtnClick = () => {
    searchTerm !== "" && getTableData();
  };

  const handleClearInputBtn = () => {
    setSearchTerm("");
  };

  const redirectToCreate = () => {
    navigate(`${props.path}/create`);
  };

  const openDeleteModal = (name: string, id: number) => {
    setCurrentItem({
      ...currentItem,
      name,
      id,
    });
    toggle();
  };

  useEffect(() => {
    props?.setExtraProps &&
      props?.setExtraProps({
        searchTerm,
        itemsCount: items?.length,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <MainContainer>
      <PageHeadeline
        title={props.pageTitle}
        description={props.pageDescription}
        className="mb-10"
      />

      <SearchHeader
        searchValue={searchTerm}
        onSearchKeypress={handleKeyPress}
        onClearInputBtn={handleClearInputBtn}
        onSearchBtnClick={handleSearchBtnClick}
        onSearchChange={handleSearch}
        onButtonClick={redirectToCreate}
        buttonLabel={props.addButtonLabel}
      />

      <Table
        loading={loading}
        wrapperClassName="mt-4 sm:mt-6"
        names={
          props.itemsKey === "identityResources"
            ? allTableDataHead
            : lessTableDataHead
        }
        values={items}
        renderTr={(el: IAppDataTableItem) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.id}>
              <Td className="py-3">
                <NavLink
                  to={`${props.path}/${el.id}`}
                  className="hover:text-primary flex items-center transition"
                >
                  {/* <Initials text={el?.name} className="mr-4" /> */}
                  {el.name}
                </NavLink>
              </Td>
              <Td className="relative py-3">
                <LimitedTagsDropdown
                  tagLimit={4}
                  tags={showCurrentTags(props.itemsKey, el)}
                />
              </Td>
              <Td className="py-3">
                <Status enabled={el?.enabled} />
              </Td>
              {props.itemsKey === "identityResources" && (
                <Td className="py-3">
                  {el?.created
                    ? format(new Date(el?.created), "MMMM dd, yyyy")
                    : ""}
                </Td>
              )}

              <Td className="text-primary-secText py-3" align="right">
                <Dropdown
                  width="w-40"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        isLink
                        withIcon
                        iconName="Edit"
                        label="Edit"
                        to={`${props.path}/${el.id}`}
                      />

                      <DropdownOption
                        withIcon
                        iconName="Delete"
                        label="Delete"
                        onClick={() => openDeleteModal(el.name, el.id)}
                      />
                    </>
                  }
                >
                  <ThreeDotsButton />
                </Dropdown>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: items?.length,
        }}
        emptyView={{ text: errors || props.emptyViewMessage }}
      />

      {visible && (
        <Modal
          visible={visible}
          modalSize={ModalSize.sm}
          hide={toggle}
          title={props.deleteModalTitle}
          onConfirmClick={onDeleteTableItem}
          confirmBtnText="Delete"
          withFooter
          warningModal
          blockOutsideClick
          loading={deleteTableItemLoading}
        >
          <p className="text-sm text-gray-700">
            Are you sure you want to delete <strong>{currentItem?.name}</strong>
            ?
          </p>
        </Modal>
      )}
    </MainContainer>
  );
};
