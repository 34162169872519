import { FC } from "react";

export const Monitor: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.34315 3 2 4.34315 2 6V14C2 15.6569 3.34315 17 5 17H11.25V19.25H9C8.58579 19.25 8.25 19.5858 8.25 20C8.25 20.4142 8.58579 20.75 9 20.75H15C15.4142 20.75 15.75 20.4142 15.75 20C15.75 19.5858 15.4142 19.25 15 19.25H12.75V17H19C20.6569 17 22 15.6569 22 14V6C22 4.34315 20.6569 3 19 3H5ZM19 4.5H5C4.17157 4.5 3.5 5.17157 3.5 6V14C3.5 14.8284 4.17157 15.5 5 15.5H19C19.8284 15.5 20.5 14.8284 20.5 14V6C20.5 5.17157 19.8284 4.5 19 4.5Z"
      />
    </svg>
  );
};
