import { FC, useEffect, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { format } from "date-fns";
import { toast } from "react-toastify";

import {
  Card,
  CardSpace,
  CardStyle,
  FormControl,
  Label,
  Modal,
  ModalSize,
  SectionTitle,
  Input,
  InputSize,
} from "../../../components";
import { IUser } from "../../../interfaces";
import { getUserInfo, inputClasses } from "../../../utils";
import {
  DeleteUser,
  DownloadUserData,
  EnrollmentVerification,
  MfaMethods,
  ResetPassword,
} from "./components";
import { useUserContext } from "../../../context/UserContext";
import { BlockUser } from "./components/BlockUser";
import { useFetch, useToggle } from "../../../hooks";

export const UserDetails: FC = () => {
  const { profile } = getUserInfo();
  const [updatedField, setUpdatedField] = useState({
    key: "",
    label: "",
    maxLength: 30,
  });

  const { apiCall: updateUserData, loading } = useFetch("put");
  const { apiCall: setEmailAsVerified } = useFetch("put");
  const { apiCall: getMfaSettings, response } = useFetch("get");
  const { toggle, visible } = useToggle();

  const { user, setUser } = useUserContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getMfaSettings(`Users/GetMfaSettings`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateUser: SubmitHandler<any> = (data: IUser) => {
    const userObject = {
      ...data,
      userName: user?.userName || user.email,
    };

    updateUserData(
      `/Users?firstName=${data.firstName}&lastName=${data.lastName}`,
      userObject,
      () => {
        toast.success("User details updated successfully!");
        setUser(data);
        toggle();
      },
      (error) => {
        // debugger;
        const errRes = error?.response?.data;
        if (errRes?.errors?.firstName) {
          toast.error(errRes?.errors?.firstName[0]);
        }

        if (errRes?.errors?.lastName) {
          toast.error(errRes?.errors?.lastName[0]);
        }

        if (errRes?.errors?.PhoneNumber) {
          toast.error(errRes?.errors?.PhoneNumber[0]);
        }
      }
    );
  };

  const onSetEmailAsVerified = () => {
    setEmailAsVerified(
      `/Users/SetUserEmailConfirmed?userId=${user?.id}`,
      {},
      () => {
        setUser({
          ...user,
          emailConfirmed: true,
        });
      }
    );
  };

  const toggleModal = (key: string, label: string, maxLength: number) => {
    toggle();
    setUpdatedField({
      key,
      label,
      maxLength,
    });
  };

  const mfaSettingsDisabled =
    response?.authenticator2faEnabled === false &&
    response?.email2faEnabled === false &&
    response?.sms2faEnabled === false;

  return (
    <>
      <form className="my-6 border border-primary-stroke rounded p-4 sm:p-6">
        <div className="grid gap-4 grid-cols-3">
          <FormControl className="flex-1">
            <Label text="First Name" />
            <p className="text-sm text-primary-mainText break-all">
              {user?.firstName}
            </p>
            <button
              type="button"
              onClick={() => toggleModal("firstName", "Full Name", 40)}
              className="text-sm text-primary mt-2 hover:text-primary-mainText cursor-pointer"
            >
              Edit
            </button>
          </FormControl>

          <FormControl className="flex-1">
            <Label text="Last Name" />
            <p className="text-sm text-primary-mainText break-all">
              {user?.lastName}
            </p>
            <button
              type="button"
              onClick={() => toggleModal("lastName", "Last Name", 40)}
              className="text-sm text-primary mt-2 hover:text-primary-mainText cursor-pointer"
            >
              Edit
            </button>
          </FormControl>

          <FormControl className="flex-1">
            <Label text="Phone Number" />
            <p className="text-sm text-primary-mainText break-all">
              {user?.phoneNumber || "N/A"}
            </p>
            <button
              type="button"
              onClick={() => toggleModal("phoneNumber", "Phone Number", 50)}
              className="text-sm text-primary mt-2 hover:text-primary-mainText cursor-pointer"
            >
              Edit
            </button>
          </FormControl>

          <FormControl className="flex-1">
            <Label text="Email" />
            <p className="text-sm text-primary-mainText break-all">
              {user?.email}

              {user?.emailConfirmed ? (
                <p className="text-sm text-primary-secText mt-1">(Verified)</p>
              ) : (
                <p className="text-sm text-primary-secText mt-1">(Pending)</p>
              )}
            </p>

            {!user?.emailConfirmed && (
              <button
                type="button"
                className="mt-2 transition no-underline focus:outline-none block px-1 bg-primary-stroke hover:bg-primary hover:text-white text-sm rounded text-primary-mainText"
                onClick={onSetEmailAsVerified}
              >
                Set email as verified
              </button>
            )}
          </FormControl>

          <FormControl className="flex-1">
            <Label text="Signed Up Date Timestamp" />
            <p className="text-sm text-primary-mainText break-all">
              {user?.signUpDate || "N/A"}
            </p>
          </FormControl>

          <FormControl>
            <Label text="Latest Login" />
            <p className="text-sm text-primary-mainText">
              {user?.lastLogin
                ? format(new Date(user?.lastLogin), "MMMM dd, yyyy")
                : "N/A"}
            </p>
          </FormControl>

          <FormControl className="flex-1" lastChild>
            <Label text="Primary Identity Provider" />
            <p className="text-sm text-primary-mainText">
              {user?.identityProvider || "N/A"}
            </p>
          </FormControl>

          <FormControl lastChild>
            <Label text="Browser" />
            <p className="text-sm text-primary-mainText">
              {user?.browser !== "" ? user?.browser : "N/A"}
            </p>
          </FormControl>
          <FormControl lastChild>
            <Label text="Registered on" />
            <p className="text-sm text-primary-mainText break-all">
              {user?.registeredOn || "N/A"}
            </p>
          </FormControl>
        </div>
      </form>

      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mb-6"
      >
        <SectionTitle>User Authentication Settings</SectionTitle>

        <div>
          {user?.twoFactorEnabled && (
            <MfaMethods user={user} setUser={setUser} />
          )}
          {!mfaSettingsDisabled && !user?.twoFactorEnabled && (
            <EnrollmentVerification user={user} setUser={setUser} />
          )}

          <ResetPassword user={user} setUser={setUser} />
          <DownloadUserData user={user} />
        </div>
      </Card>

      {profile?.sub !== user?.id && (
        <Card cardStyle={CardStyle.bordered} cardSpace={CardSpace.xl}>
          <SectionTitle>Sensitive Actions</SectionTitle>

          <BlockUser
            user={user}
            isBlocked={user?.isBlocked}
            setUser={setUser}
          />
          <DeleteUser user={user} />
        </Card>
      )}

      {visible && (
        <Modal
          visible={visible}
          hide={() => {
            toggle();
            reset();
          }}
          title={`Edit ${updatedField?.label}`}
          onConfirmClick={handleSubmit(onUpdateUser)}
          confirmBtnText="Save"
          withFooter
          confirmBtnType="submit"
          blockOutsideClick
          loading={loading}
          modalSize={ModalSize.md}
        >
          <FormControl lastChild>
            <Label text={updatedField?.label} />
            <Input
              {...register(updatedField?.key, {
                required: `${updatedField?.label} is required`,
              })}
              maxLength={updatedField.maxLength}
              autoFocus
              className={inputClasses}
              error={
                !!errors &&
                !!errors[updatedField?.key] &&
                errors[updatedField?.key]?.message
              }
              inputSize={InputSize.sm}
            />
          </FormControl>
        </Modal>
      )}
    </>
  );
};
