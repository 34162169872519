import React from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import {
  Modal,
  ModalSize,
  ModalBody,
  ModalFooter,
  StaticTabs,
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
} from "../../../components";
import { cancelBtnClasses, copyToClipboard } from "../../../utils";

interface IViewCodeModal {
  visible: boolean;
  toggle?: () => void;
  jsScript?: string;
  styleScript?: string;
}



export const ViewCodeModal: React.FC<IViewCodeModal> = ({
  visible,
  toggle,
  jsScript,
  // styleScript,
}) => {
  const styleScript = "https://tojnhu4mvp.gjirafa.net/CMP/style.min.css";
  // const styleScript = "https://unpkg.com/gjirafa-cmp@4.6.5/dist/main.min.css";
  const cmpScript = `<link rel="stylesheet" href="${styleScript}"/> \n <script src="${jsScript}" type="module" async="false" ></script>`;

  const scriptContent = `<pre class="html5 my-4" style="font-family:monospace;"><span class="flex flex-wrap" style="color: #009900;">&lt;<span style="color: #000000; font-weight: bold;">script</span> <span style="color: #000066;"> type</span><span style="color: #66cc66;">=</span>”<span style="color: #000066;">text</span><span style="color: #66cc66;">/</span>javascript" <span style="color: #000066;">src</span><span style="color: #66cc66;">=</span><span style="color: #ff0000;">&quot;${jsScript}&quot;</span>&gt;&lt;<span style="color: #66cc66;">/</span><span style="color: #000000; font-weight: bold;">script</span>&gt;</span></pre>`;

  const styleContent = `<pre class="css3 my-4"  style="font-family:monospace;"><span class="flex flex-wrap" style="color: #009900;">&lt;<span style="color: #000000; font-weight: bold;">link</span> <span style="color: #000066;"> rel</span><span style="color: #66cc66;">=</span><span style="color: #ff0000;">&quot;stylesheet&quot;</span> <span style="color: #000066;"> href</span><span style="color: #66cc66;">=</span><span style="color: #ff0000;">&quot;${styleScript}&quot;</span>&gt;</span></pre>`;

  const tabOptions = [
    {
      name: "Production",
      isActive: true,
    },
    {
      name: "Development",
      isActive: false,
    },
  ];

  const getActiveTab = (tab: number) => {
    console.log("Active tab is:", tab);
  };

  return (
    <Modal
      visible={visible}
      hide={toggle}
      modalSize={ModalSize.lg}
      title="View script">
      <form>
        <ModalBody>
          <StaticTabs tabs={tabOptions} getActiveTab={getActiveTab}>
            <div tabIndex={0}>
              <span dangerouslySetInnerHTML={{ __html: scriptContent }} />
              <span dangerouslySetInnerHTML={{ __html: styleContent }} />
            </div>
            <div tabIndex={1}>second tab</div>
          </StaticTabs>
        </ModalBody>

        <ModalFooter className="justify-between mb-0">
          <button
            type="button"
            className="py-2 px-3 flex items-center text-primary-secText hover:text-primary focus:outline-none"
            onClick={() =>
              copyToClipboard(cmpScript, "JSON copied successfully!")
            }>
            <div className="mr-2">
              <Icon name="Copy" size={20} />
            </div>
            Copy script
          </button>

          <Button
            btnType={BtnType.secondary}
            btnStyle={BtnStyle.neutral}
            btnSize={BtnSize.normal}
            type="button"
            onClick={toggle}
            className={cancelBtnClasses}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
