import { useEffect } from "react";

import { AuthProvider } from "oidc-react";
import { Routes, Route } from "react-router-dom";

import { Router as MainRouter } from "./router/Router";
import { oidcConfig, userManager } from "./config/oidc-auth";
import { ToastHolder } from "./components/ui/ToastHolder";
import { PermissionsProvider } from "./context";
import ProtectedRouter from "./router/ProtectedRouter";
import { getUserInfo, setPostLoginUri } from "./utils";
import { useToggle } from "./hooks";

const App = () => {
  const { setVisible } = useToggle();
  const { access_token } = getUserInfo();

  const iFrames = document.getElementsByTagName("iframe");
  if (!!iFrames) {
    for (let index = 0; index < iFrames.length; index++) {
      const element = iFrames[index];
      element.style.display = "none";
    }
  }
  useEffect(() => {
    setPostLoginUri();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !access_token && setVisible(true);
  }, [access_token]); // eslint-disable-line react-hooks/exhaustive-deps

  // const clearAllCookies = () => {
  //   return document.cookie.split(";").forEach(function (c) {
  //     document.cookie = c
  //       .replace(/^ +/, "")
  //       .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  //   });
  // };

  userManager.events.addSilentRenewError(() => {
    setVisible(true);
  });

  return (
    <>
      <AuthProvider {...oidcConfig}>
        <PermissionsProvider>
          <ProtectedRouter>
            <Routes>
              <Route path="*" element={<MainRouter />} />
            </Routes>
          </ProtectedRouter>
        </PermissionsProvider>

        <ToastHolder />
      </AuthProvider>

      {/* {visible && (
        <Modal
          visible={visible}
          title="Session Expired"
          // infoModal
          modalSize={ModalSize.sm}
          warningModal
          blockOutsideClick
          noCloseButton
        >
          <p className="text-sm text-gray-700 mb-6 sm:mb-8">Session expired</p>

          <Button
            type="button"
            onClick={() => {
              window.location.reload();
              window.localStorage.clear();
              clearAllCookies();
            }}
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx([
              "flex items-center mb-2 ml-auto",
              primaryBtnClasses,
            ])}
          >
            Login again
          </Button>
        </Modal>
      )} */}
    </>
  );
};

export default App;
