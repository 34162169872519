import { FC, useState } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { toast } from "react-toastify";

import { Input, InputSize } from "../../../components";

import { IProperty } from "../../../interfaces";
import { useFetch, useToggle } from "../../../hooks";
import { propertiesIState } from "../../../utils";
import { FormControl, Modal, Table, Td } from "../..";
import { Label } from "../../form/Label";

export interface IPropertiesTable {
  response: any;
  setResponse: any;
  handlePageChange: any;
  currentPage: number;
  perPage: number;
  endpoint: string;
  loading?: boolean;
}

export const PropertiesTable: FC<IPropertiesTable> = ({
  response,
  setResponse,
  currentPage,
  handlePageChange,
  perPage,
  endpoint,
  loading,
}) => {
  const [currentProperty, setCurrentProperty] =
    useState<IProperty>(propertiesIState);
  const { properties, totalCount } = !!response && response;
  const { apiCall: deleteProperty, loading: deletePropLoading } =
    useFetch("delete");
  const { visible, toggle } = useToggle();

  const onDeleteProperty = (property: IProperty) => {
    deleteProperty(`${endpoint}/Properties/${property.id}`, {}, () => {
      toast.success("Property deleted successfully!");
      toggle();

      setResponse({
        ...response,
        totalCount: totalCount - 1,
        properties: properties?.filter((item: IProperty) => {
          return item.id !== property.id;
        }),
      });
    });
  };

  const openDeletePropertyModal = (el: IProperty) => {
    toggle();
    setCurrentProperty(el);
  };

  return (
    <>
      <Table
        loading={loading || false}
        names={[{ children: "Key" }, { children: "Value" }, { children: "" }]}
        values={response?.properties}
        renderTr={(el: IProperty) => {
          return (
            <tr className="hover:bg-gray-50 transition">
              <Td>{el.key}</Td>
              <Td>{el.value}</Td>
              <Td className="text-primary-secText" align="right">
                <button
                  type="button"
                  onClick={() => openDeletePropertyModal(el)}
                  className="transition text-primary-secText hover:text-warning focus:shadow-outlineWarning focus:outline-none rounded"
                >
                  <span className="inline-block w-5 h-5">
                    <Icon name="Delete" size={22} />
                  </span>
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: perPage,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: properties?.length,
        }}
        emptyView={{ text: "There are no properties yet." }}
      />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Delete Property"
          withFooter
          warningModal
          confirmBtnText="Delete"
          onConfirmClick={() => onDeleteProperty(currentProperty)}
          loading={deletePropLoading}
          blockOutsideClick
        >
          <p className="mb-6 text-sm">
            Are you sure u want to delete{" "}
            <strong className="font-medium">{currentProperty?.key}</strong>?
          </p>

          <FormControl>
            <Label text="Key" />
            <Input
              inputSize={InputSize.sm}
              readOnly
              value={currentProperty?.key}
              className="bg-primary-stroke"
            />
          </FormControl>

          <FormControl>
            <Label text="Value" />
            <Input
              inputSize={InputSize.sm}
              readOnly
              value={currentProperty?.value}
              className="bg-primary-stroke"
            />
          </FormControl>
        </Modal>
      )}
    </>
  );
};
