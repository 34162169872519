import { FC } from "react";
import {
  AreaChart,
  Area,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

interface IChart {
  data: any;
  startGradientColor: string;
  endGradientColor: string;
  lineStrokeColor: string;
  gradientId: string;
  tootlipTitle: string;
}

export const Chart: FC<IChart> = ({
  data,
  startGradientColor,
  endGradientColor,
  lineStrokeColor,
  gradientId,
  tootlipTitle,
}) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <>
          <div className="p-4 bg-white text-primary-mainText inline-block shadowed-content rounded">
            <h6 className="text-sm text-primary-mainText mb-2">
              <strong>{payload[0].value}</strong> {tootlipTitle}
            </h6>
            <p className="mr-6 text-xs text-primary-secText">
              {payload[0]?.payload?.name}
            </p>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="65%" height={142}>
      <AreaChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 5 }}>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={startGradientColor} />
            <stop offset="95%" stopColor={endGradientColor} />
          </linearGradient>
        </defs>
        <YAxis axisLine={false} tickLine={false} fontSize="8" color="#667C99" />
        <CartesianGrid
          stroke="#E0E5EB"
          strokeDasharray="7 7"
          horizontal
          vertical={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="uv"
          stroke={lineStrokeColor}
          strokeWidth="2"
          fillOpacity="1"
          fill={`url(#${gradientId})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
