import { FC } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import { FormControl, Label, Message } from "../../../../components";
import { cx, reactSelectStyle } from "../../../../utils";
import { frequencyValues, scheduleTypes } from "../../dummy-data";

export const DataImportSchedule: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [scheduleId] = useWatch({
    control,
    name: ["scheduleId"],
  });

  return (
    <>
      <p className="text-md text-primary-mainText font-medium mb-2">Schedule</p>
      <div className="flex gap-3">
        <FormControl className="flex-1">
          <Label required text="Schedule" />
          <Controller
            rules={{
              required: true,
            }}
            name="scheduleId"
            control={control}
            render={({ field: { onChange, ref, value } }) => (
              <Select
                noOptionsMessage={() => "No Schedule type found."}
                options={scheduleTypes}
                getOptionLabel={(x) => x?.label}
                isSearchable
                onChange={onChange}
                ref={ref}
                value={value}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                className={cx([
                  "text-sm",
                  errors?.scheduleId && "invalid-field",
                ])}
                classNamePrefix="porta-react-select"
                placeholder="Select Schedule Type"
                styles={reactSelectStyle}
              />
            )}
          />
          {errors?.scheduleId && <Message message="Schedule is required!" />}
        </FormControl>
        {scheduleId?.label === "Hourly" && (
          <FormControl className="flex-1 max-w-xs">
            <Label required text="Every" />
            <Controller
              rules={{
                required: true,
              }}
              name="scheduleFrequency"
              control={control}
              render={({ field: { onChange, ref, value } }) => (
                <Select
                  noOptionsMessage={() => "Schedule frequency is required!"}
                  options={frequencyValues}
                  getOptionLabel={(x) => x?.label}
                  isSearchable
                  onChange={onChange}
                  ref={ref}
                  value={value}
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  className={cx([
                    "text-sm",
                    errors?.scheduleFrequency && "invalid-field",
                  ])}
                  classNamePrefix="porta-react-select"
                  placeholder="Select"
                  styles={reactSelectStyle}
                />
              )}
            />
            {errors?.scheduleFrequency && (
              <Message message="Schedule frequency is required!" />
            )}
          </FormControl>
        )}
      </div>
    </>
  );
};
