import { cx } from "../../../../utils";

interface ISocialIcon {
  iconName: string;
}

export const SocialIcon: React.FC<ISocialIcon> = ({ iconName }) => {
  const Icon = (icon: string) => {
    if (icon === "apple")
      return "M18.6668 15.738C18.1428 17.258 16.5742 19.9493 14.9582 19.9787C13.8862 19.9993 13.5415 19.3433 12.3162 19.3433C11.0915 19.3433 10.7082 19.9587 9.69483 19.9987C7.98016 20.0647 5.3335 16.114 5.3335 12.6687C5.3335 9.504 7.53883 7.93533 9.4655 7.90667C10.4988 7.888 11.4748 8.60333 12.1048 8.60333C12.7375 8.60333 13.9228 7.74333 15.1688 7.86933C15.6902 7.89133 17.1548 8.07933 18.0948 9.454C15.6008 11.082 15.9895 14.4867 18.6668 15.738ZM15.1855 4C13.3015 4.076 11.7642 6.05267 11.9788 7.68733C13.7202 7.82267 15.3908 5.87067 15.1855 4Z";

    if (icon === "facebook")
      return "M12 4C16.4183 4 20 7.60361 20 12.0489C20 16.0663 17.0745 19.3962 13.25 20V14.3755H15.1141L15.4688 12.0489H13.25V10.5391C13.25 10.4595 13.2548 10.3802 13.2653 10.3023C13.3382 9.75714 13.6842 9.28208 14.5538 9.28208H15.5625V7.3013C15.5625 7.3013 14.647 7.1441 13.7717 7.1441C11.9444 7.1441 10.75 8.25837 10.75 10.2756V12.0489H8.71875V14.3755H10.75V20C6.92547 19.3962 4 16.0663 4 12.0489C4 7.60361 7.58172 4 12 4Z";

    if (icon === "github")
      return "M12 4C7.58011 4 4 7.67017 4 12.2013C4 15.8261 6.29343 18.8972 9.47084 19.9846C9.86863 20.0602 10.016 19.8084 10.016 19.5869C10.016 19.3906 10.011 18.877 10.0061 18.1923C7.78146 18.6857 7.31001 17.0948 7.31001 17.0948C6.94659 16.1483 6.42112 15.8916 6.42112 15.8916C5.69429 15.3831 6.47514 15.3931 6.47514 15.3931C7.27563 15.4536 7.70288 16.2389 7.70288 16.2389C8.41498 17.4925 9.57397 17.1301 10.0307 16.9186C10.1044 16.39 10.3106 16.0275 10.5365 15.8211C8.76366 15.6197 6.89748 14.9149 6.89748 11.7733C6.89748 10.8772 7.20688 10.1472 7.72253 9.57323C7.63904 9.36177 7.36403 8.53108 7.79619 7.40334C7.79619 7.40334 8.469 7.18182 9.99632 8.24411C10.6347 8.06287 11.3174 7.97224 12 7.96721C12.6777 7.97224 13.3653 8.06287 14.0037 8.24411C15.531 7.18182 16.2038 7.40334 16.2038 7.40334C16.6409 8.53108 16.3659 9.36681 16.2824 9.57323C16.7931 10.1472 17.1025 10.8772 17.1025 11.7733C17.1025 14.9249 15.2314 15.6147 13.4487 15.8211C13.7336 16.0728 13.9939 16.5763 13.9939 17.3415C13.9939 18.439 13.984 19.3201 13.984 19.5919C13.984 19.8135 14.1265 20.0652 14.5341 19.9846C17.7115 18.8972 20 15.8261 20 12.2063C20 7.67017 16.4199 4 12 4Z";

    if (icon === "google")
      return "M15.3207 8.28446C14.7311 7.7422 13.6971 7.09331 12.1642 7.09331C10.0055 7.09331 8.17334 8.48889 7.51115 10.4178L7.51115 10.4178L7.51115 10.4178C7.34788 10.9156 7.24815 11.4489 7.24815 12.0001C7.24815 12.5511 7.34788 13.0845 7.52021 13.5823C8.17334 15.5112 10.0055 16.9067 12.1642 16.9067C13.3796 16.9067 14.3048 16.5778 14.9941 16.1067L14.9942 16.1067L14.9942 16.1065C16.0827 15.3599 16.5725 14.2488 16.6631 13.511H12.1643V10.5421H19.8287C19.9466 11.0399 20.001 11.5199 20.001 12.1777C20.001 14.6132 19.1121 16.6665 17.5702 18.0621L17.5699 18.0621L17.5701 18.0623C16.2186 19.2889 14.3683 20.0001 12.1642 20.0001C8.97147 20.0001 6.21409 18.2045 4.8717 15.5911C4.31844 14.5067 4.00098 13.2889 4.00098 12.0001C4.00098 10.7112 4.31844 9.49337 4.8717 8.40891L4.87175 8.40889L4.8717 8.40886C6.21409 5.79554 8.97147 4 12.1642 4C14.3683 4 16.2096 4.79111 17.6245 6.08L15.3207 8.28446Z";

    if (icon === "linkedin")
      return "M5.18252 4C4.52961 4 4 4.51348 4 5.14615V18.8542C4 19.4869 4.52967 20 5.18252 20.0001H18.8175C19.4707 20.0001 20 19.4869 20 18.8541V5.14615C20 4.51348 19.4707 4 18.8176 4H5.18252ZM8.8623 10.1866V17.3898H6.46801V10.1866H8.8623ZM9.02005 7.95889C9.02005 8.65017 8.5004 9.2033 7.66564 9.2033L7.66544 9.20323H7.64996C6.84642 9.20323 6.3269 8.65011 6.3269 7.95883C6.3269 7.25194 6.8621 6.71423 7.68151 6.71423C8.50034 6.71423 9.00443 7.25187 9.02005 7.95889ZM12.5816 17.3898H10.1875L10.1875 17.3901C10.1875 17.3901 10.2189 10.8625 10.1877 10.1868H12.5818V11.2064C12.9001 10.7157 13.4697 10.0177 14.7396 10.0177C16.3148 10.0177 17.4958 11.0473 17.4958 13.2597V17.3898H15.1018V13.5367C15.1018 12.5683 14.7551 11.9078 13.889 11.9078C13.2274 11.9078 12.8334 12.3532 12.6605 12.7836C12.5972 12.9371 12.5816 13.1527 12.5816 13.3677V17.3898Z";

    if (icon === "microsoft")
      return "M4 11.6026H11.6038V4H4V11.6026ZM12.3955 11.6026H19.9993V4H12.3955V11.6026ZM11.6038 20H4V12.3975H11.6038V20ZM12.3955 20H19.9993V12.3975H12.3955V20Z";

    if (icon === "seznam")
      return "M11.006.017c-.111.038-.415.396-2.844.863-.447.072-.89.157-1.332.253-.293.062-.585.12-.873.194-.266.067-.54.127-.8.211-.252.08-.5.163-.748.258a4.78 4.78 0 00-1.33.787 4.088 4.088 0 00-.657.683 4.839 4.839 0 00-.353.574c-.1.19-.17.416-.172.634-.003.642.401 1.18.868 1.58.153.13.316.25.481.365a11.5 11.5 0 001.567.902c.56.268 1.123.525 1.693.77.54.231 1.09.427 1.647.613l.481.16c.505.168 1.02.324 1.52.507.311.113.643.22.954.361.324.147.66.294.945.505.19.14.356.314.478.517.12.2.194.429.2.663.007.224-.058.443-.178.633-.313.492-.857.828-1.382 1.051-.784.334-1.587.622-2.405.864-.466.137-.931.274-1.4.397-.472.123-.952.253-1.428.373a45.2 45.2 0 01-1.426.332 214.366 214.366 0 01-1.306.277c-.34.075-.681.149-1.024.222-.39.087-.779.17-1.168.256-.113.025-.226.056-.34.081-.1.024-.478.112-.197.095.389-.022 1.429-.113 3.65-.412.806-.134 1.614-.25 2.42-.382a42.734 42.734 0 002.995-.596 18.35 18.35 0 001.4-.38c.779-.244 2.029-.673 2.324-.802 1.13-.498 1.835-.873 2.202-1.766a2.14 2.14 0 00.145-1.012 2.307 2.307 0 00-.304-.895 3.178 3.178 0 00-.587-.683 6.39 6.39 0 00-1.369-.942 8.613 8.613 0 00-.426-.209 12.54 12.54 0 00-1.313-.526c-.678-.233-1.366-.442-2.048-.666a14.38 14.38 0 01-1.14-.423 9.046 9.046 0 01-.468-.212 7.132 7.132 0 01-.53-.288c-.214-.13-.564-.354-.466-.657.068-.217.302-.301.494-.379.177-.072.365-.123.549-.171.563-.145 1.139-.244 1.707-.366.167-.035.334-.072.501-.111.444-.104.9-.183 1.323-.352.364-.143.83-.373.965-.773.195-.584-.026-1.215-.344-1.708-.115-.176-.233-.35-.355-.523-.167-.235-.351-.456-.532-.68-.081-.101-.18-.093-.264-.067z";
  };

  return (
    <>
      {/* TODO: should place the Icon instead */}
      <svg
        width={10}
        height={10}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cx([
          "fill-current w-6 mr-2",
          iconName === "seznam" && "relative top-1",
        ])}
      >
        <path d={Icon(iconName)} className="fill-current" />
      </svg>
    </>
  );
};
