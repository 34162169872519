import { useEffect, useState } from "react";

import { Controller } from "react-hook-form";
import Select from "react-select";

import { Label, Message, MessageTypes } from "../../../components";
import { useFetch } from "../../../hooks";
import { CMP_API_URL } from "../../../constants";
import { ICookie } from "../../../interfaces";
import { reactSelectStyle } from "../../../utils";

interface ICategoriesSelect {
  domainId: string;
  control: any;
  errors: any;
  cookie?: ICookie;
}

interface ICategory {
  name: string;
  encryptedId: string;
}

export const CategoriesSelect: React.FC<ICategoriesSelect> = (props) => {
  const [categories, setCategories] = useState<ICategory[]>();
  const { apiCall: getCategories } = useFetch("get");

  useEffect(() => {
    getCategories(
      `${CMP_API_URL}/AddCookies?domainId=${props.domainId}`,
      {},
      (response) => {
        const categoryArr: ICategory[] = [];

        response?.categoryList.forEach((item: ICategory) => {
          categoryArr.push({
            name: item.name,
            encryptedId: item.encryptedId,
          });
        });
        setCategories(categoryArr);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("categories", categories);

  return (
    <>
      <Label text="Category" />
      <Controller
        control={props.control}
        name="category"
        rules={{ required: "Category is required" }}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            onChange={onChange}
            options={categories}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.encryptedId}
            value={value}
            ref={ref}
            isSearchable
            // menuPortalTarget={document.body}
            classNamePrefix="porta-react-select"
            menuPosition="fixed"
            portalPlacement="auto"
            className="text-sm mb-2"
            styles={reactSelectStyle}
          />
        )}
      />

      {!!props.errors.category && props.errors.category.message && (
        <Message
          type={MessageTypes.error}
          message={props.errors.category.message}
        />
      )}
    </>
  );
};
