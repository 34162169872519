import { ChangeEvent, FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";
import Select from "react-select";

import {
  BackButton,
  Card,
  CardSpace,
  CardStyle,
  Checkbox,
  Container,
  FormControl,
  FormWrapper,
  Input,
  InputSize,
  Label,
  Loader,
  Paragraph,
  ParagraphSize,
  SectionTitle,
  UploadImageFrame,
} from "../../../components";
import { BRANDINGS_PATH } from "../../../constants";
import { useFetch, useToggle } from "../../../hooks";
import {
  IApplication,
  IBrandingDetails,
  IReactHookForm,
} from "../../../interfaces";
import { cx, inputClasses, reactSelectStyle } from "../../../utils";
import { handleErrors } from "../../user-profile/utils/handle-errors";
import { BrandingPreviewModal } from "./BrandingPreviewModal";

interface IBrandingForm extends IReactHookForm {
  onSubmit: () => void;
  editMode?: boolean;
  loading?: boolean;
  updateLoading?: boolean;
  branding?: IBrandingDetails;
  id?: string;
  isCreate?: boolean;
}

export interface IAppIds {
  clientName: string;
  clientId: string;
}

export const BrandingForm: FC<IBrandingForm> = (props) => {
  const { toggle, visible } = useToggle();
  const [brandingColors, setBrandingColors] = useState({
    primary: "#1D79F2",
    pageBg: "#ffffff",
    buttonText: "#ffffff",
    copyRightText: "#ffffff",
  });
  const [applications, setApplications] = useState<IApplication[]>();
  const [logo, setLogo] = useState<string>();
  const { primary, pageBg, buttonText, copyRightText } = brandingColors;

  props.setValue("_PrimaryColor", primary);
  props.setValue("_PageBackgroundColor", pageBg);
  props.setValue("_ButtonTextColor", buttonText);
  props.setValue("_CopyRightTextColor", copyRightText);

  const { apiCall: getApplications } = useFetch("get");
  const { apiCall: uploadApplicationLogo, loading } = useFetch("post");

  useEffect(() => {
    getApplications(`Clients/GetClientsForDropdown`, {}, (response) => {
      const appArr: any = [];
      response?.forEach((item: { clientName: string; clientId: string }) => {
        appArr.push({
          applicationName: item.clientName,
          applicationId: item.clientId,
        });
      });
      setApplications(appArr);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!props?.branding) {
      setBrandingColors({
        primary: props?.branding?._PrimaryColor,
        pageBg: props?.branding?._PageBackgroundColor || "#ffffff",
        buttonText: props?.branding?._ButtonTextColor || "#ffffff",
        copyRightText: props?.branding?._CopyRightTextColor || "#ffffff",
      });
    }
  }, [props.branding]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const value = e.target?.files[0];
      const formData = new FormData();

      formData.append("photoFile", value);

      uploadApplicationLogo(
        `BrandingConfigurations/UploadLogo`,
        formData,
        (response) => {
          props.setValue("companyLogo", response);
          setLogo(response);
        },
        (error) => {
          handleErrors(error);
        }
      );
    }
  };

  const removeLogo = () => {
    setLogo("");
    props.setValue("companyLogo", null);
  };

  useEffect(() => {
    if (!!props.branding?.companyLogo && props.branding?.companyLogo !== "null")
      setLogo(props?.branding?.companyLogo);
  }, [props.setValue, props.branding]);

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [visible]);

  return (
    <>
      <div>
        <Container className="mb-6">
          <BackButton to={BRANDINGS_PATH} label="Back to Brandings">
            <></>
          </BackButton>
          <div className="flex justify-between">
            <SectionTitle noMargin className="flex">
              Universal Login
              {props.branding?.isDefault && (
                <div className="text-xs text-primary-mainText flex items-center ml-4">
                  <span className="w-1.5 h-1.5 bg-primary-secText rounded-full inline-block mr-2" />
                  Default
                </div>
              )}
            </SectionTitle>

            <div className="flex">
              <Controller
                control={props.control}
                name="isDefault"
                render={({ field: { onChange, value, ref } }) => (
                  <Checkbox
                    label="Set as Default"
                    id="isDefault"
                    checked={value || false}
                    inputRef={ref}
                    onChange={onChange}
                    wrapperClassName="mr-2 flex items-center"
                  />
                )}
              />

              {/* {props.editMode && ( */}
              <NavLink
                to=""
                state={{
                  branding: {
                    primaryColor: primary,
                    companyLogo: props?.branding?.companyLogo || logo,
                    pageBgColor: pageBg,
                    buttonTextColor: buttonText,
                    copyRightTextColor: copyRightText,
                  },
                }}
                onClick={toggle}
                className="px-2 py-1 border border-primary-stroke rounded text-sm text-primary-mainText bg-white flex items-center hover:text-primary ml-6 focus:shadow-outlinePrimary"
              >
                {" "}
                {/* TODO: this should be replaced with Icon */}
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current mr-1"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.00005 4.20078C5.00594 4.20078 4.20005 5.00667 4.20005 6.00078V12.0008C4.20005 12.9949 5.00594 13.8008 6.00005 13.8008H12C12.9942 13.8008 13.8 12.9949 13.8 12.0008V10.4591C13.8 10.2106 14.0015 10.0091 14.25 10.0091C14.4986 10.0091 14.7 10.2106 14.7 10.4591V12.0008C14.7 13.492 13.4912 14.7008 12 14.7008H6.00005C4.50888 14.7008 3.30005 13.492 3.30005 12.0008V6.00078C3.30005 4.50961 4.50888 3.30078 6.00005 3.30078H7.68755C7.93608 3.30078 8.13755 3.50225 8.13755 3.75078C8.13755 3.99931 7.93608 4.20078 7.68755 4.20078H6.00005ZM10.75 4.20078C10.5015 4.20078 10.3 3.99931 10.3 3.75078C10.3 3.50225 10.5015 3.30078 10.75 3.30078H14.25H14.7V3.75078V7.25078C14.7 7.49931 14.4986 7.70078 14.25 7.70078C14.0015 7.70078 13.8 7.49931 13.8 7.25078V4.83718L11.0682 7.56898C10.8925 7.74472 10.6076 7.74472 10.4318 7.56898C10.2561 7.39324 10.2561 7.10832 10.4318 6.93258L13.1636 4.20078H10.75Z"
                  />
                </svg>
                Preview
              </NavLink>
            </div>
          </div>
          <Paragraph parahraphSize={ParagraphSize.sm}>
            Create a beautiful universal login page where you can redirect to
            authenticate your users.
          </Paragraph>
        </Container>

        {props.loading && !props.isCreate ? (
          <Loader />
        ) : (
          <FormWrapper
            onSubmit={props.onSubmit}
            loading={props.updateLoading || props.loading}
            className="w-full"
            prevPage={BRANDINGS_PATH}
          >
            <FormControl>
              <Label required text="Name" htmlFor="name" />
              <Input
                id="name"
                autoFocus={!props.editMode}
                {...props.register("name", {
                  required: "Costumization name is required",
                  validate: {
                    notValidName: (value: string) =>
                      !!value.trim() || "Costumization name is required",
                  },
                })}
                error={!!props.errors.name && props.errors.name.message}
                inputSize={InputSize.sm}
                className={inputClasses}
                placeholder="Enter your costumization name"
                maxLength={100}
              />
            </FormControl>

            <FormControl>
              <Card cardSpace={CardSpace.xl} cardStyle={CardStyle.bordered}>
                <UploadImageFrame
                  imageScr={logo || ""}
                  onRemoveImage={removeLogo}
                  onFileChange={handleFileChange}
                  id="picture-file"
                  title="Logo"
                  loading={loading}
                />
              </Card>
            </FormControl>

            <FormControl>
              <Label text="Applications" />

              <Controller
                control={props.control}
                name="applicationsDto"
                render={({ field: { onChange, value, ref } }: any) => (
                  <Select
                    ref={ref}
                    value={value}
                    options={applications}
                    getOptionLabel={(x) => x.applicationName}
                    getOptionValue={(x) => x.applicationId}
                    isSearchable
                    isMulti
                    menuPortalTarget={document.body}
                    onChange={onChange}
                    className="text-sm"
                    classNamePrefix="porta-react-select"
                    placeholder="Select Application(s) to apply customization to"
                    styles={reactSelectStyle}
                  />
                )}
              />
            </FormControl>

            <FormControl description="This color will be applied to the primary button background">
              <Label text="Primary Color" htmlFor="primary-color" />
              <input
                type="color"
                className="absolute color-input border-r border-primary-stroke"
                value={primary}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    primary: e.target.value,
                  })
                }
              />
              <Input
                id="primary-color"
                {...props.register("_PrimaryColor", {
                  required: "Primary color is required",
                  validate: {
                    notValidColor: (value: string) =>
                      /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value) ||
                      "Primary Color' must be a valid hex color.",
                  },
                })}
                value={primary}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    primary: e.target.value,
                  })
                }
                error={
                  !!props.errors._PrimaryColor &&
                  props.errors._PrimaryColor.message
                }
                inputSize={InputSize.sm}
                className={cx([inputClasses, "pl-14"])}
              />
            </FormControl>

            <FormControl description="This color will be applied to the login page background">
              <Label text="Page Background Color" htmlFor="page-bg-color" />
              <input
                type="color"
                className="absolute color-input"
                value={pageBg}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    pageBg: e.target.value,
                  })
                }
              />

              <Input
                id="page-bg-color"
                {...props.register("pageBackgroundColor", {
                  required: "Page background color is required",
                  validate: {
                    notValidColor: (value: string) =>
                      /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value) ||
                      "Page background color ' should be a valid hex color.",
                  },
                })}
                error={
                  !!props.errors.pageBackgroundColor &&
                  props.errors.pageBackgroundColor.message
                }
                value={pageBg}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    pageBg: e.target.value,
                  })
                }
                inputSize={InputSize.sm}
                className={cx([inputClasses, "pl-14"])}
              />
            </FormControl>

            <FormControl description="This color will be applied to the primary button text">
              <Label text="Button Text Color" htmlFor="button-color" />
              <input
                type="color"
                className="absolute color-input"
                value={buttonText}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    buttonText: e.target.value,
                  })
                }
              />

              <Input
                id="button-color"
                {...props.register("buttonTextColor", {
                  required: "Button text color is required",
                  validate: {
                    notValidColor: (value: string) =>
                      /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value) ||
                      "Button text color ' must be a valid hex color.",
                  },
                })}
                error={
                  !!props.errors.buttonTextColor &&
                  props.errors.buttonTextColor.message
                }
                value={buttonText}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    buttonText: e.target.value,
                  })
                }
                inputSize={InputSize.sm}
                className={cx([inputClasses, "pl-14"])}
              />
            </FormControl>

            <FormControl
              description="This color will be applied to the copyright text"
              lastChild
            >
              <Label text="Copyright Text Color" htmlFor="copyright-color" />
              <input
                type="color"
                className="absolute color-input"
                value={copyRightText}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    copyRightText: e.target.value,
                  })
                }
              />

              <Input
                id="copyright-color"
                {...props.register("copyRightTextColor", {
                  required: "Copyright text color is required",
                  validate: {
                    notValidColor: (value: string) =>
                      /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value) ||
                      "Copyright text color ' must be a valid hex color.",
                  },
                })}
                error={
                  !!props.errors.copyRightTextColor &&
                  props.errors.copyRightTextColor.message
                }
                value={copyRightText}
                onChange={(e) =>
                  setBrandingColors({
                    ...brandingColors,
                    copyRightText: e.target.value,
                  })
                }
                inputSize={InputSize.sm}
                className={cx([inputClasses, "pl-14"])}
              />
            </FormControl>
          </FormWrapper>
        )}
      </div>

      {visible && <BrandingPreviewModal visible={visible} toggle={toggle} />}
    </>
  );
};
