import { createContext, useContext, useEffect, useState } from "react";
import { USER_PROFILE_API, USER_PROFILE_API_HEADERS } from "../../../constants";
import { useFetch } from "../../../hooks";

const ModelDetailsContext = createContext<any>(null);

const ModelDetailsContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [modelId, setModelId] = useState<string>();
  const { apiCall: getModelDetails, loading, response } = useFetch("get");
  const {
    apiCall: getPipelineJob,
    loading: pipelineLoading,
    response: pipelineDetails,
  } = useFetch("get");

  useEffect(() => {
    if (!!modelId) {
      getModelDetails(
        `${USER_PROFILE_API}/integration-models/${modelId}`,
        USER_PROFILE_API_HEADERS
      );
      getPipelineJob(
        `${USER_PROFILE_API}/integration-models/rest-pipeline-job?integrationStructureId=${modelId}`,
        USER_PROFILE_API_HEADERS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId]);

  const model = response?.data;
  const pipelineData = pipelineDetails?.data;

  return (
    <ModelDetailsContext.Provider
      value={{
        model,
        modelId,
        setModelId,
        loading,
        pipelineData,
        pipelineLoading,
      }}
    >
      {children}
    </ModelDetailsContext.Provider>
  );
};

const useModelDetailsContext = () => {
  return useContext(ModelDetailsContext);
};

export { ModelDetailsContextProvider, useModelDetailsContext };
