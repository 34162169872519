import { FC, useEffect, useState } from "react";

import { format } from "date-fns";
import { toast } from "react-toastify";

import { ILog } from "../../../interfaces";
import { useCurrentPage, useFetch, useToggle } from "../../../hooks";
import { logIState, SEO } from "../../../utils";
import { useUserContext } from "../../../context/UserContext";
import { LogsDetailModal } from "./components";
import { Eye } from "../../../components/Icons";
import DeleteLogsTopBar from "../../../components/ui/DeleteLogsTopBar";
import { Table, Td } from "../../../components";

export const UserHistory: FC = () => {
  const { user } = useUserContext();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState(new Date());
  const [log, setLog] = useState<ILog>(logIState);

  const {
    apiCall: getUserLogs,
    response,
    loading: userLogsLoading,
    errors,
  } = useFetch("get");
  const { apiCall: deleteUserLogs, loading: deleteLoading } = useFetch("post");

  const { totalCount, logs } = !!response && response;
  const { currentPage, handlePageChange } = useCurrentPage();
  const { toggle, visible } = useToggle();

  const PER_PAGE = 10;

  const onDeleteUserLogs = () => {
    const logObject = {
      deleteOlderThan: startDate,
      userId: user?.id,
    };

    // try {
    //   const request = await axios.post("Log/DeleteAuditLogs", logObject);
    //   if (request?.status === 200) {
    //     toast.success("Logs deleted successfully!");
    //   }
    // } catch (error) {}

    deleteUserLogs(
      "Log/DeleteAuditLogs",
      logObject,
      () => {
        toast.success("Logs deleted successfully!");
      },
      () => {
        // toast.error("some");
      }
    );
  };

  useEffect(() => {
    SEO({
      title: "Porta - User History",
    });
  }, []);

  useEffect(() => {
    getUserLogs(
      `Log/AuditLogSearch?search=${searchTerm}&userId=${user?.id}&Page=${currentPage}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchTerm]);

  const emptySectionText =
    !!searchTerm && logs?.length < 1
      ? `No log found.`
      : "There are no logs yet.";

  return (
    <>
      <div className="mt-8 justify-between items-center flex-wrap">
        {/* <h5 className="text-primary-mainText sm:mt-0 my-4 block">Audit Logs</h5> */}

        <DeleteLogsTopBar
          startDate={startDate}
          setStartDate={setStartDate}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          onDeleteLogs={onDeleteUserLogs}
          deleteLoading={deleteLoading}
        />
      </div>

      <Table
        wrapperClassName="mt-4 sm:mt-6"
        names={[
          { children: "Event" },
          { children: "Date Timestamp" },
          { children: "Application" },
          { children: "From" },
          { children: "Details", className: "text-right" },
        ]}
        loading={userLogsLoading}
        values={logs}
        renderTr={(el: ILog) => {
          return (
            <tr className="transition hover:bg-gray-50" key={el.id}>
              <Td className="py-3">{el?.event}</Td>

              <Td className="py-3">
                {format(new Date(el?.created), "MM/dd/yyyy '-' h:mm:ss a")}
              </Td>

              <Td className="py-3">{el?.source}</Td>
              <Td className="py-3">IP</Td>

              <Td className="py-3" align="right">
                <button
                  type="button"
                  className="text-primary text-xs flex items-center hover:text-primary-mainText"
                  title="View Details"
                  onClick={() => {
                    toggle();
                    setLog(el);
                  }}
                >
                  <Eye />
                  <span className="ml-1">View</span>
                </button>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: logs?.length,
        }}
        emptyView={{ text: errors || emptySectionText }}
      />

      {!!visible && (
        <LogsDetailModal visible={visible} toggle={toggle} log={log} />
      )}
    </>
  );
};
