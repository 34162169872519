import { FC, useEffect, useState } from "react";
import { AppDataTable } from "../../../components";
import { IExtraProp } from "../../../components/applications/interface";
import { API_RES_PATH } from "../../../constants";
import { SEO } from "../../../utils";

export const ApiResources: FC = () => {
  const [extraProps, setExtraProps] = useState<IExtraProp>({
    searchTerm: "",
    itemsCount: 0,
  });

  const { searchTerm, itemsCount } = extraProps;

  useEffect(() => {
    SEO({
      title: "Porta - API Resources",
    });
  }, []);

  const props = {
    endpoint: "/ApiResources",
    itemsKey: "apiResources",
    addButtonLabel: "New API Resource",
    deleteSuccessMessage: "API Resource deleted successfully!",
    emptyViewMessage: `${
      searchTerm !== "" && itemsCount < 1
        ? "No API resource found."
        : "There are no API resources yet."
    }`,
    path: API_RES_PATH,
    searchKey: "searchText",
    pageTitle: "API Resources",
    pageDescription:
      "Set-up API Resources to represent functionality a client wants to invoke.",
    deleteModalTitle: "Delete API Resource",
  };

  return <AppDataTable {...props} setExtraProps={setExtraProps} />;
};
