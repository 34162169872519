import { ReactNode } from "react";
import { cx } from "../../utils";

interface IContainer {
  id?: string;
  className?: string;
  childClassName?: string;
  children: ReactNode;
}

export const Container: React.FC<IContainer> = ({
  className,
  children,
  childClassName,
}) => {
  return (
    <div className={cx(["flex-1 relative", className])}>
      <div className={cx(["max-w-4xl mx-auto sm:px-4", childClassName])}>
        {children}
      </div>
    </div>
  );
};
