import { FC } from "react";
import { ArrowRotateLeft } from "../../../components/Icons";
import { cx } from "../../../utils";

interface IResetButton {
  onClick?: () => void;
  disabled?: boolean;
}

export const ResetButton: FC<IResetButton> = ({ onClick, disabled }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={cx([
        disabled
          ? "opacity-70 cursor-default"
          : "hover:bg-gray-200 focus:bg-gray-300",
        "p-2 flex items-center bg-gray-100 transition font-medium text-sm text-primary-mainText rounded",
      ])}
    >
      <ArrowRotateLeft className="mr-2" />
      Reset to default
    </button>
  );
};
