//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import {
  Modal,
  ModalSize,
  ModalBody,
  ModalFooter,
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
} from "../../../components";
import { cancelBtnClasses, copyToClipboard } from "../../../utils";
import { IErrorLog } from "../../../interfaces";

interface IDeleteModal {
  visible: boolean;
  toggle?: () => void;
  log: IErrorLog;
}

export const ErrorLogDetailModal: React.FC<IDeleteModal> = ({
  visible,
  toggle,
  log,
}) => {
  return (
    <Modal
      visible={visible}
      hide={toggle}
      modalSize={ModalSize.md}
      title="Details"
    >
      <form>
        <ModalBody>
          <div>
            <div className="rounded border border-stroke p-2 mt-8 overflow-auto max-w-full max-h-96">
              <pre className="text-xs text-primary-secText leading-7 whitespace-pre-wrap">
                {log?.logEvent}
              </pre>
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="justify-between">
          <button
            type="button"
            className=" py-2 px-3 flex items-center text-primary-secText hover:text-primary focus:outline-none"
            onClick={() =>
              copyToClipboard(log?.logEvent, "JSON copied successfully!")
            }
          >
            <div className="mr-2 w-5 h-5">
              <Icon name="Copy" size={20} />
            </div>
            Copy Details
          </button>

          <Button
            btnType={BtnType.secondary}
            btnStyle={BtnStyle.neutral}
            btnSize={BtnSize.normal}
            type="button"
            onClick={toggle}
            className={cancelBtnClasses}
          >
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
