import { FC } from "react";

export const SelectIcon: FC = () => {
  return (
    <span className="w-6 h-6">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="3" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5C5 4.44772 5.44772 4 6 4H12C12.5523 4 13 4.44772 13 5V11C13 11.5523 12.5523 12 12 12H6C5.44772 12 5 11.5523 5 11V5Z"
          fill="#A3B0C2"
        />
        <path
          d="M13.1619 9.74791C12.5154 9.18301 11.5044 9.64189 11.504 10.5005L11.5 18.2034C11.4995 19.1875 12.6675 19.7046 13.3957 19.0425L14.0966 18.4053L14.7347 19.7876C14.9601 20.2757 15.5311 20.4983 16.0274 20.2915L17.6336 19.622C18.157 19.4038 18.3945 18.7946 18.1568 18.2798L17.5593 16.9855L18.4362 16.9326C19.4479 16.8715 19.8768 15.6147 19.1136 14.9478L13.1619 9.74791Z"
          fill="#1D79F2"
          stroke="white"
        />
      </svg>
    </span>
  );
};
