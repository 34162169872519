import { useEffect, useState, FC } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { IApiResource } from "../../../interfaces";
import { apiResourceIState, SEO } from "../../../utils";
import { ApiResourceForm } from "./components";
import { API_RES_PATH } from "../../../constants";
import { useFetch } from "../../../hooks";
import { Loader } from "../../../components";

export const ApiResourceCreate: FC = () => {
  const [accessTokens, setAccessTokens] = useState<string[]>([]);
  const [claims, setClaims] = useState<string[]>([]);
  const [scopes, setScopes] = useState<string[]>([]);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);

  const { apiCall: createApiResource, loading } = useFetch("post");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta -  API Resource Create",
    });

    reset(apiResourceIState);

    setTimeout(() => {
      setLoadingVisible(false);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateApiResource: SubmitHandler<any> = (data: IApiResource) => {
    const apiResourceData = {
      ...data,
      allowedAccessTokenSigningAlgorithms: accessTokens,
      scopes,
      name: data.name.trim(),
      userClaims: claims,
    };

    createApiResource(
      `/ApiResources`,
      apiResourceData,
      () => {
        toast.success("API resource created successfully!");
        navigate(API_RES_PATH);
      },
      (errors) => {
        const errRes = errors?.response?.data;

        toast.error(errRes);

        if (errRes?.errors?.Name) {
          toast.error(errRes?.errors?.Name[0]);
        }
      }
    );
  };

  const props = {
    register,
    control,
    onSubmit: handleSubmit(onCreateApiResource),
    claims,
    setClaims,
    scopes,
    setScopes,
    accessTokens,
    setAccessTokens,
    errors,
  };

  return (
    <>
      {loadingVisible && <Loader />}

      {!loadingVisible && <ApiResourceForm {...props} loading={loading} />}
    </>
  );
};
