import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Animation,
  BtnSize,
  BtnStyle,
  BtnType,
  Button,
  Input,
  InputSize,
  Modal,
  ModalSize,
} from "../../../../components";
import { ConfigIconButton } from "../../../../components/button/ConfigIconButton";
import { LayoutIcon } from "../../../../components/Icons/LayoutIcon";
import { SelectIcon } from "../../../../components/Icons/SelectIcon";
import {
  cancelBtnClasses,
  cx,
  history,
  inputClasses,
  primaryBtnClasses,
} from "../../../../utils";
import Accordion from "../components/Accordion";
import { Layouts } from "../components/layouts/Layouts";
import { WidgetsLayouts } from "../components/widget/WidgetsLayout";
import {
  BannerBottom,
  BannerBottomLeft,
  BannerBottomRight,
  BannerCategories,
  BannerCenter,
  BannerLeft,
  BannerRight,
  BannerTop,
  BannerTopLeft,
  BannerTopRight,
} from "./BannerPositions.config";

import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { CMP_API_URL, DOMAINS_PATH } from "../../../../constants";
import { useDomainContext } from "../../../../context";
import { useTemplateContext } from "../../../../context/TemplateContext";
import { useFetch, useToggle } from "../../../../hooks";
import { IComplianceConsent } from "../../../../interfaces";
import { Generals } from "../components/generals";
export const schema = object().shape({
  brandLogoURL: string().url().nullable().required("Please enter website"),
});

const TemplateConfig: FC = () => {
  let { templateId } = useParams();
  const did = localStorage.getItem("did");
  const { domain } = useDomainContext();
  const { apiCall: saveConsentLogs } = useFetch("post");
  const { geolocationEnum, geolocationEnumAsString, id } = !!domain && domain;
  // const { apiCall: editSelectedTheme } = useFetch("put");

  const consent: IComplianceConsent = {
    encryptedId: uuidv4(),
    domainId: "",
    geolocationEnum: "",
    geolocationEnumAsString: "",
    showDNSMD: "",
    userConsent: "",
  };
  console.log("🚀 ~ file: TemplateConfig.tsx ~ line 63 ~ consent", consent);

  const {
    theme,
    editTheme,
    switchBannerType,
    setSwitchBannerType,
    preview,
    setPreview,
    // showWidget,
    setShowWidget,
    bannerType,
    setBannerType,
  } = useTemplateContext();

  const methods = useForm({
    defaultValues: theme,
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = methods;

  const { toggle, visible } = useToggle();
  // const [preview, setPreview] = useState("desktop");
  const [width, setWidth] = useState<any>(window.innerWidth / 2);

  useEffect(() => {
    reset(theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    switch (bannerType) {
      case "info":
        // setSwitchBannerType(true)
        return () => setShowWidget(false);
      case "categories":
        // setSwitchBannerType(true)
        return () => setShowWidget(false);
      case "widget":
        // setSwitchBannerType(false)
        return () => setShowWidget(true);
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerType]);

  const data = [
    {
      title: "Layout",
      content: <Layouts />,
      icon: <LayoutIcon />,
    },
    // { title: "Content", content: <div>Layout Content</div>, icon: <Content /> },
    {
      title: "Icon Position",
      content: <WidgetsLayouts />,
      icon: <SelectIcon />,
    },
    { title: "General", content: <Generals />, icon: <SelectIcon /> },
    // { title: "Style", content: <StylesConfig />, icon: <StylePalete /> },
    // {
    //   title: "Add custom CSS",
    //   content: <div>Layout Content</div>,
    //   icon: <CustomCode />,
    // },
  ];

  const BannerPosition = () => {
    // switch (bannerType) {
    switch (watch("bannerPlacement")) {
      case "center":
        return (
          <BannerCenter
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
            preview={preview}
          />
        );
      case "left":
        return (
          <BannerLeft
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "right":
        return (
          <BannerRight
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "bottom":
        return (
          <BannerBottom
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "top":
        return (
          <BannerTop
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "top-right":
        return (
          <BannerTopRight
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "top-left":
        return (
          <BannerTopLeft
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "bottom-right":
        return (
          <BannerBottomRight
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );
      case "bottom-left":
        return (
          <BannerBottomLeft
            watch={watch}
            theme={theme}
            setShowWidget={setShowWidget}
          />
        );

      default:
        return null;
    }
  };

  const onSubmitHandler = (data: any) => {
    if (
      (data.showDNSMD === true && geolocationEnum !== 2) ||
      (data.showDNSMD === false && geolocationEnum === 2)
    ) {
      toggle();
      // history.push(`${DOMAINS_PATH}/${did}/ui-builder`);
      // localStorage.removeItem("did");
    } else {
      editTheme({
        ...theme,
        encryptedId: templateId,
        bannerPlacement: data?.bannerPlacement,
        bannerName: data?.bannerName,
        showLanguagesIcon: data?.showLangGlobe,
        showDNSMD: data?.showDNSMD,
        showCloseButton: data?.showCloseButton,
        showBrandLogo: data?.showBrandLogo,
        brandLogoURL: data?.brandLogoURL,
      });
      // toggle();
      history.push(`${DOMAINS_PATH}/${did}/ui-builder`);
      localStorage.removeItem("did");
    }
  };

  const onCancelHandler = () => {
    reset(theme);
    history.push(`${DOMAINS_PATH}/${did}/ui-builder`);
  };

  const confirmComplianceTypeModal = (data: any) => {
    editTheme({
      ...theme,
      encryptedId: templateId,
      bannerPlacement: data?.bannerPlacement,
      bannerName: data?.bannerName,
      showLanguagesIcon: data?.showLangGlobe,
      showDNSMD: data?.showDNSMD,
      showCloseButton: data?.showCloseButton,
      showBrandLogo: data?.showBrandLogo,
      brandLogoURL: data?.brandLogoURL,
    });
    saveConsentLogs(`${CMP_API_URL}/AddComplianceConsent`, {
      ...consent,
      encryptedId: uuidv4(),
      domainId: id,
      geolocationEnum: `${geolocationEnum}`,
      geolocationEnumAsString: geolocationEnumAsString,
      showDNSMD: `${data?.showDNSMD}`,
      userConsent: "true",
    });
    toggle();
    history.push(`${DOMAINS_PATH}/${did}/ui-builder`);
    localStorage.removeItem("did");
  };

  return (
    <>
      <div className="theme-editor flex justify-between relative w-full">
        <Animation
          type="fade"
          show={true}
          duration={450}
          transform="transform-gpu"
          translateShift="translate-width"
        >
          {/* //? PREVIEW SECTION */}
          <div
            className={`preview-section relative ${
              preview === "desktop" && "w-2/3"
            } transform my-0 mx-auto transition-width duration-300 relative border-2 border-dashed  border-gray-300 rounded-md`}
            style={{ width: width }}
          >
            {
              <BannerCategories
                watch={watch}
                switchBannerType={switchBannerType}
                setSwitchBannerType={setSwitchBannerType}
                width={width}
              />
            }
            {BannerPosition()}
            <button
              className={
                bannerType === "widget" ? "absolute bottom-3 left-3" : "hidden"
              }
              onClick={() => setBannerType("info")}
            >
              <img
                src="https://tojnhu4mvp.gjirafa.net/CMP/widgetCircle.svg"
                alt="banner widget"
              />
            </button>
          </div>
        </Animation>
        {/* <div className="preview-section border border-red h-screen w-3/4"></div> */}
        <FormProvider {...methods}>
          <form
            // onSubmit={confirmComplianceTypeModal}
            // onSubmit={handleSubmit(onSubmitHandler)}
            className="w-1/3 overflow-y-auto"
          >
            <Animation
              type="fade-right"
              show={true}
              duration={450}
              translateShift="translate-x-full"
            >
              <div className="config-sidebar z-10 flex flex-1 flex-col p-4 pt-0 w-full ">
                {/*//? STICKY SECTION  */}
                <section className="z-50 sticky top-0 right-0 border flex flex-col p-4 border-white bg-white rounded-lg shadow-configCard">
                  {/* <section className="z-50 sticky top-0 right-0 border flex flex-col p-4 border-white bg-white rounded-lg shadow-configCard"> */}
                  <ConfigIconButton
                    className="border font-medium border-gray-300"
                    iconName="Refresh"
                    // btnSize={BtnWidth.large}
                    text="Reset to default"
                    rounded="rounded"
                    fontSize="text-xs"
                    iconSize={24}
                    // iconSize={IconSize.normal}
                    onButtonClick={() => {
                      reset();
                    }}
                    // disabled={!isDirty}
                    disabled={!isDirty}
                  />
                  <div className="flex flex-col my-4  w-full text-primary-mainText">
                    <h5 className="font-normal text-gray-600 text-sm">
                      Preview
                    </h5>

                    {/*//! DEVICES */}
                    {/* <div className="inline-flex justify-between mt-2"> */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 lg:gap-4 mt-2">
                      <ConfigIconButton
                        className={`border border-gray-200 ${
                          preview === "desktop"
                            ? "text-blue border border-blue border-opacity-100"
                            : "border-gray border-opacity-30"
                        }`}
                        iconName="Desktop"
                        text="Desktop"
                        rounded="rounded"
                        fontSize="text-xs"
                        // iconSize={IconSize.normal}
                        onButtonClick={() => {
                          setPreview("desktop");
                          setWidth("100%");
                          // setWidth("750px");
                        }}
                        disabled={false}
                      />
                      <ConfigIconButton
                        className={`border border-gray-200 ${
                          preview === "tablet"
                            ? "text-blue border border-blue border-opacity-100"
                            : "border-gray border-opacity-30"
                        }`}
                        iconName="Tablet"
                        text="Tablet"
                        fontSize="text-xs"
                        rounded="rounded"
                        // iconSize={IconSize.normal}
                        onButtonClick={() => {
                          setWidth("420px");
                          setPreview("tablet");
                        }}
                        disabled={false}
                      />
                      <ConfigIconButton
                        className={`border border-gray-200 ${
                          preview === "mobile"
                            ? "text-blue border border-blue border-opacity-100"
                            : "border-gray border-opacity-30"
                        }`}
                        iconName="Mobile"
                        text="Mobile"
                        fontSize="text-xs"
                        rounded="rounded"
                        // iconSize={IconSize.normal}
                        onButtonClick={() => {
                          setWidth("360px");
                          setPreview("mobile");
                        }}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <Input
                    {...register("bannerName")}
                    type="text"
                    placeholder="Banner Name"
                    // onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                    // onWheel={(e) => e.currentTarget.blur()}
                    labelProps={{ children: "Template Name" }}
                    labelClassname="font-normal text-sm text-gray-600"
                    inputSize={InputSize.sm}
                    className={cx([inputClasses, "w-full rouded"])}
                  />
                </section>

                {/*//! ACCORDION SECTION */}
                <section className="mt-4 border border-white rounded-lg shadow-configCard">
                  {/* <StylesConfig /> */}
                  <div className="accordion w-full">
                    {data.map((item, index) => (
                      <Accordion
                        // data={item}
                        key={index}
                        title={item?.title}
                        content={item?.content}
                        icon={item?.icon}
                      />
                    ))}
                  </div>

                  {/*//! STYLE ACCORDION */}
                  {/* <StylesConfig /> */}
                </section>

                {/*//? SUBMIT SECTION */}
                <section className="p-4 my-4 border flex flex-col border-white rounded-lg shadow-configCard">
                  <Button
                    type="submit"
                    // btnType={BtnType.secondary}
                    btnStyle={BtnStyle.primary}
                    btnSize={BtnSize.normal}
                    // onClick={()=>toggle()}
                    onClick={handleSubmit(onSubmitHandler)}
                    // disabled={!isDirty}
                    disabled={!isDirty}
                    className={cx(["mb-2", primaryBtnClasses])}
                  >
                    Save Template
                  </Button>
                  <Button
                    type="button"
                    btnType={BtnType.secondary}
                    btnStyle={BtnStyle.primary}
                    btnSize={BtnSize.normal}
                    onClick={() => onCancelHandler()}
                    className={cancelBtnClasses}
                  >
                    Cancel
                  </Button>
                </section>
              </div>
            </Animation>
            {/* <div className="configuration-section border border-red h-screen w-1/4"></div> */}
          </form>
        </FormProvider>
      </div>

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          modalSize={ModalSize.md}
          title="Compliance Type Collision"
          // onConfirmClick={handleSubmit(onSubmitHandler)}
          onConfirmClick={handleSubmit(confirmComplianceTypeModal)}
          confirmBtnText="Yes"
          withFooter
          // warningModal
          // loading={deleteSecretLoading}
          blockOutsideClick
        >
          <p className="mb-6 text-md ">
            You are trying to submit the changes to a <em>CCPA-compliant</em>{" "}
            banner without DNSMD(do not sell my data) button.
          </p>
          <p className=" text-sm ">Are you syre you want to do that?</p>
        </Modal>
      )}
    </>
  );
};

export default TemplateConfig;
