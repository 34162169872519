import { FC, ReactNode, useState } from "react";
import { useRef } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../../../utils";
// import { StyleConfigItem } from "./styles/StyleConfigItem";
// import { ColorInput } from "./ColorInput";

interface AccordionItemProps {
  title?: string;
  content?: any;
  icon?: ReactNode;
  className?: string;
  submenu?: any;
  nested?: boolean;
}

const Accordion: FC<AccordionItemProps> = ({
  title,
  content,
  icon,
  className,
  submenu,
  nested,
}) => {
  // console.log(submenu);
  const [clicked, setClicked] = useState(false);
  const contentEl = useRef<HTMLDivElement | null>(null);


  const handleToggle = () => {
    setClicked((prev) => !prev);
  };
  return (
    <div
      className={cx([
        `accordion-item ${clicked ? "active" : ""}
    accrdion-item `,
        nested ? "border-none mb-0" : "border-b border-gray-200 py-2",
        className,
      ])}>
      <button
        type="button"
        onClick={handleToggle}
        className={cx([
          `accordion-item overflow-x-hidden flex items-center h-auto w-full px-4 transform transition-all duration-500 text-base font-normal`,
          clicked ? "mb-4" : "border-none",
          nested &&
            "mb-0 py-3 pl-2 pr-0 border-b border-gray-200 hover:bg-gray-100 hover:text-primary hover:rounded",
          nested && clicked ? "leading-6 mb-4 " : " mb-0",
        ])}>
        {icon && (
          <span className="p-2 mr-2 bg-gray-200 rounded-md">{icon}</span>
        )}
        <p className={`text-sm md:text-base ${title}`}>{title}</p>
        <span
          className={`ml-auto transform duration-200 text-left ${
            clicked && "rotate-180"
          }`}>
          <Icon name="ArrowDown" size={20} />
        </span>
      </button>

      <div
        ref={contentEl}
        className={cx([
          `accordion-content grid gap-4 px-4`,
          nested ? "grid-cols-2 " : "grid-cols-1",
          className,
        ])}
        style={
          clicked
            ? {
                height: contentEl?.current?.scrollHeight,
                marginTop: contentEl?.current?.style?.marginTop,
              }
            : { height: "0px" }
        }>
        {content}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Accordion;
