import { FC } from "react";

interface IChatLeftIcon {
  width?: number;
}

export const ChatLeft: FC<IChatLeftIcon> = ({ width }) => {
  return (
    <svg
      width={width || 24}
      height={width || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2 18.6L3.6 19.8C2.94076 20.2944 2 19.824 2 19V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H7C6.35089 18 5.71929 18.2105 5.2 18.6ZM3.5 18L4.3 17.4C5.07893 16.8158 6.02633 16.5 7 16.5H19C19.8284 16.5 20.5 15.8284 20.5 15V6C20.5 5.17157 19.8284 4.5 19 4.5H5C4.17157 4.5 3.5 5.17157 3.5 6V18Z"
      />
    </svg>
  );
};
