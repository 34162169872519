import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { Input, InputSize, Label, Modal } from "../../../../components";
import { ModalSize } from "../../../../components/modal";
import { APPS_PATH } from "../../../../constants";
import { useFetch } from "../../../../hooks";
import { inputClasses } from "../../../../utils";

interface IDeleteAppModal {
  visible: boolean;
  toggle: () => void;
  id: string;
  clientName: string;
}

export const DeleteAppModal: React.FC<IDeleteAppModal> = ({
  visible,
  toggle,
  id,
  clientName,
}) => {
  const navigate = useNavigate();
  const { apiCall, loading } = useFetch("delete");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const deleteApplication = (data: any) => {
    apiCall(`/Clients/${id}`, data, () => {
      toast.success("Application successfully deleted!");
      navigate(`${APPS_PATH}/`);
    });
  };

  const closeModal = () => {
    toggle();
    reset();
  };

  return (
    <Modal
      visible={visible}
      blockOutsideClick
      hide={closeModal}
      modalSize={ModalSize.md}
      title="Are you sure?"
      withFooter
      onConfirmClick={handleSubmit(deleteApplication)}
      onCancel={closeModal}
      confirmBtnText="Delete"
      confirmBtnType="submit"
      warningModal
      loading={loading}
    >
      <p className="text-sm  text-gray-700">
        This action cannot be undone. This will permanently delete the{" "}
        <strong>{clientName}</strong> application.
      </p>

      <p className="text-sm  text-gray-700 mt-8 mb-6">
        Please type in the name of the application to confirm.
      </p>

      <Label required text="Name" htmlFor="Name" />
      <Input
        id="Name"
        {...register("name", {
          required: "Application name is required",
          validate: (value: string) =>
            value === clientName || "Application name is not correct",
        })}
        autoFocus
        error={!!errors.name && errors.name.message}
        inputSize={InputSize.sm}
        className={inputClasses}
        type="text"
      />
    </Modal>
  );
};
