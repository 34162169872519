import { ReactNode } from "react";
import { cx } from "../../utils";

interface IMainContainer {
  id?: string;
  className?: string;
  children: ReactNode;
}

export const MainContainer: React.FC<IMainContainer> = ({
  className,
  children,
}) => {
  return (
    <div
      className={cx([
        "max-w-screen-xl max-w-sc mx-auto sm:px-4 flex flex-col h-full",
        className,
      ])}
    >
      {children}
    </div>
  );
};
