import { FC } from "react";
import {
  Card,
  CardSpace,
  CardStyle,
  Paragraph,
  SectionTitle,
  ToggleSwitch,
} from "../../components";

export const IdentityResolution: FC = () => {
  return (
    <div>
      <SectionTitle noMargin>Identity Resolution</SectionTitle>
      <Paragraph className="mb-6">
        Collect user consents and match identifiers across devices and
        touchpoints to build a cohesive, omnichannel view of an individual user.
      </Paragraph>
      <div className="flex flex-wrap">
        <Card
          cardStyle={CardStyle.bordered}
          cardSpace={CardSpace.xl}
          className="w-72 mr-6"
        >
          <div className="mb-6 flex justify-between">
            <img
              src="images/unified.png"
              alt="Unified Logo"
              width={64}
              height={64}
            />

            <ToggleSwitch id="unified" />
          </div>

          <div>
            <h6 className="text-primary-secText font-medium">Unified ID 2.0</h6>

            <Paragraph>
              Create connection with theTradeDesk Unified ID 2.0
            </Paragraph>
          </div>
        </Card>

        <Card
          cardStyle={CardStyle.bordered}
          cardSpace={CardSpace.xl}
          className="w-72 mr-6"
        >
          <div className="mb-6 flex justify-between">
            <img src="images/id5.png" alt="ID 5 Logo" width={64} height={64} />

            <ToggleSwitch id="id-5" />
          </div>

          <div>
            <h6 className="text-primary-secText font-medium">ID 5</h6>

            <Paragraph>Create connection with the ID5 Universal ID</Paragraph>
          </div>
        </Card>
      </div>
    </div>
  );
};
