import { useEffect, useState, FC } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { IIdentityResource } from "../../../interfaces";
import { identityResourceIState, SEO } from "../../../utils";
import { IdentityResourceForm } from "./components";
import { I_RES_PATH } from "../../../constants";
import { useFetch } from "../../../hooks";
import { Loader } from "../../../components";

export const IdentityResourceCreate: FC = () => {
  const [claims, setClaims] = useState<string[]>([]);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const { apiCall: createIdentityResource, loading } = useFetch("post");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SEO({
      title: "Porta - Identity Resource Create",
    });

    reset(identityResourceIState);

    setTimeout(() => {
      setLoadingVisible(false);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateIdentityResource: SubmitHandler<any> = (
    data: IIdentityResource
  ) => {
    const iResourceData = {
      ...data,
      name: data.name.trim(),
      userClaims: claims,
    };

    createIdentityResource(
      `/IdentityResources`,
      iResourceData,
      () => {
        toast.success("Identity Resource created successfully!");
        navigate(I_RES_PATH);
      },
      (error) => {
        const errRes = error?.response?.data;
        toast.error(errRes);

        if (errRes?.errors?.Name) {
          toast.error(errRes?.errors?.Name[0]);
        }
      }
    );
  };

  const props = {
    register,
    control,
    onSubmit: handleSubmit(onCreateIdentityResource),
    claims,
    setClaims,
    errors,
  };

  return (
    <>
      {loadingVisible && <Loader />}
      {!loadingVisible && <IdentityResourceForm {...props} loading={loading} />}
    </>
  );
};
