import { FC } from "react";

import { Flip, ToastContainer } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import "react-toastify/dist/ReactToastify.css";

const contextClass: any = {
  success: "text-primary-mainText success",
  error: "text-primary-mainText error",
  info: "bg-gray-600 info",
  warning: "text-primary-mainText warning",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const CloseButton = ({ closeToast }: any) => (
  <div className="mt-2 mr-2 text-primary-tertText w-6 h-6" onClick={closeToast}>
    <Icon name="Close" size={24} />
  </div>
);

interface IToastHolder {}

export const ToastHolder: FC<IToastHolder> = () => {
  return (
    <ToastContainer
      position="top-right"
      toastClassName={({ type }: any) =>
        contextClass[type || "default"] +
        " relative flex p-1 min-h-20 rounded-md justify-between overflow-hidden cursor-pointer mb-2 toast-container"
      }
      bodyClassName={() => "text-sm font-white font-med block pl-10 pr-5 py-3"}
      autoClose={2500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Flip}
      closeButton={CloseButton}
    />
  );
};
