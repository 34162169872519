import { FC, useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Select from "react-select";

import { Input, InputSize, Label, Message } from "../../../../components";
import { cx, inputClasses, reactSelectStyle } from "../../../../utils";
import { IProperty, visibilitySettingsIState } from "../../interface";
import { ConstTypes, useConstant } from "../../utils/useConstant";

interface IVisibilitySettings {
  index: number;
  properties: IProperty[];
}

export const VisibilitySettings: FC<IVisibilitySettings> = ({
  index,
  properties,
}) => {
  const { constTypes: operators } = useConstant(ConstTypes.operators);
  const [visibilityProperties, setVisibilityProperties] = useState<any>();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, replace } = useFieldArray({
    control,
    name: `actions[${index}].visibilitySettings`,
  });

  useEffect(() => {
    const filteredProps = properties?.filter((prop) => prop.name !== "user_id");
    setVisibilityProperties(filteredProps);
  }, [properties]);

  useEffect(() => {
    fields?.length < 1 && replace(visibilitySettingsIState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-8">
      {fields?.map((field, k) => {
        const vbSettingsErrors = errors.actions?.[index]?.visibilitySettings;
        return (
          <div key={field.id} className="flex gap-4 mb-3">
            <div className="max-w-sm flex-1">
              <Label text="Property Name" required />
              <Controller
                control={control}
                name={`actions[${index}].visibilitySettings[${k}].property`}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    noOptionsMessage={() => "No property found."}
                    options={visibilityProperties || []}
                    getOptionLabel={(x) => x?.name}
                    getOptionValue={(x) => x?.id}
                    isSearchable
                    ref={ref}
                    value={!!value?.name ? value : null}
                    menuPosition="fixed"
                    menuPortalTarget={document.body}
                    onChange={onChange}
                    className={cx([
                      "text-sm",
                      vbSettingsErrors?.[k]?.property && "invalid-field",
                    ])}
                    classNamePrefix="porta-react-select"
                    placeholder="Select Property"
                    styles={reactSelectStyle}
                  />
                )}
              />

              {vbSettingsErrors?.[k]?.property && (
                <Message message="Property is required" />
              )}
            </div>

            <div className="max-w-sm flex-1">
              <Label text="Operator" required />
              <Controller
                control={control}
                name={`actions[${index}].visibilitySettings[${k}].operator`}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    noOptionsMessage={() => "No operator found."}
                    options={operators || []}
                    getOptionLabel={(x) => x?.name}
                    getOptionValue={(x) => x?.id}
                    isSearchable
                    value={!!value?.name ? value : null}
                    ref={ref}
                    menuPosition="fixed"
                    menuPortalTarget={document.body}
                    onChange={onChange}
                    className={cx([
                      "text-sm",
                      vbSettingsErrors?.[k]?.operator && "invalid-field",
                    ])}
                    classNamePrefix="porta-react-select"
                    placeholder="Select Operator"
                    styles={reactSelectStyle}
                  />
                )}
              />

              {vbSettingsErrors?.[k]?.operator && (
                <Message message="Operator is required" />
              )}
            </div>

            <div>
              <Label text="Value" required />
              <Input
                id="value"
                {...register(
                  `actions[${index}].visibilitySettings[${k}].value`,
                  {
                    required: "Value is required",
                  }
                )}
                error={
                  !!vbSettingsErrors?.[k]?.value &&
                  vbSettingsErrors?.[k]?.value?.message
                }
                inputSize={InputSize.sm}
                className={inputClasses}
                placeholder="Enter Value"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
