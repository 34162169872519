import { useEffect, useState } from "react";
import { USER_PROFILE_API, USER_PROFILE_API_HEADERS } from "../../../constants";
import { useFetch } from "../../../hooks";

export enum ConstTypes {
  "operators" = "0",
  "actionTypes" = "1",
  "inputTypes" = "2",
  "defaultProperties" = "3",
  "defaultTemplates" = "4",
  "AIPipeLines" = "5",
  "designationTypes" = "6",
}

interface IConstType {
  id: number;
  name: string;
  designation?: string;
  inputTypeId?: number;
  isRequired?: boolean;
  inputType?: {
    name: string;
  };
}

export const useConstant = (constTypeId: ConstTypes) => {
  const [constTypes, setConstTypes] = useState<IConstType[]>([
    {
      name: "",
      id: 0,
    },
  ]);
  const { apiCall: getConstTypes, loading } = useFetch("get");

  useEffect(() => {
    getConstTypes(
      `${USER_PROFILE_API}/constants?constant=${constTypeId}`,
      USER_PROFILE_API_HEADERS,
      (response) => {
        setConstTypes(response?.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    constTypes,
    loading,
  };
};
