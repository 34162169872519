import { Dispatch, FC, SetStateAction } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { Modal, ModalSize } from "../../../components";
import { useDomainContext } from "../../../context";
import { ManageCookieForm } from ".";
import { useFetch } from "../../../hooks";
import { CMP_API_URL } from "../../../constants";
import { toast } from "react-toastify";
import { IDomainCategory } from "../interface";
import { ICookie } from "../../../interfaces";

interface ICreateDomainModal {
  visible: boolean;
  toggle: () => void;
  categories: IDomainCategory[];
  setCategories: Dispatch<SetStateAction<IDomainCategory[] | undefined>>;
}

export const CreateCookieModal: FC<ICreateDomainModal> = ({
  visible,
  toggle,
  categories,
  setCategories,
}) => {
  const { domain } = useDomainContext();
  const { encryptedId: id } = !!domain && domain;
  const { apiCall: createCookie, loading } = useFetch("post");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const closeModal = () => {
    toggle();
    reset();
  };

  const createCookieCategory = (
    categories: IDomainCategory[],
    categoryName: string,
    createdCookie: ICookie
  ) => {
    // debugger;
    return categories?.map((category) => {
      if (category.categoryName === categoryName) {
        return {
          ...category,
          cookies: {
            ...category.cookies,
            data: [createdCookie, ...category.cookies.data],
            pageSize: category.cookies?.totalCount + 1,
            totalCount: category.cookies?.totalCount + 1,
          },
        };
      } else {
        return {
          ...category,
        };
      }
    });
  };

  const onCreateCookie: SubmitHandler<any> = (cookie: ICookie) => {
    const cookieObj: ICookie = {
      ...cookie,
      encryptedDomainId: id,
      encryptedCategoryId: cookie?.category?.encryptedId as number,
    };

    delete cookieObj?.category;

    createCookie(`${CMP_API_URL}/AddCookie`, cookieObj, (response) => {
      // debugger;
      const { id, encryptedId } = response;
      toggle();
      toast.success("Cookie created successfully!");

      // TODO: should return id and encryptedId from response
      const upda = {
        ...cookieObj,
        category: cookie.category,
        cookieLifeTime:cookie.expiration,
        id,
        encryptedId,
      };

      const updatedCategories = createCookieCategory(
        categories,
        cookie?.category?.name || "",
        upda
      );

      setCategories(updatedCategories);
    });
  };

  return (
    <Modal
      visible={visible}
      hide={closeModal}
      modalSize={ModalSize.lg}
      title="Add Cookie"
      confirmBtnText="Add Cookie"
      onConfirmClick={handleSubmit(onCreateCookie)}
      onCancel={toggle}
      withFooter
      loading={loading}
      blockOutsideClick
    >
      <ManageCookieForm
        control={control}
        errors={errors}
        register={register}
        domainId={id}
      />
    </Modal>
  );
};
