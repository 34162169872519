import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTemplateContext } from "../../../../../context";
import {
  WidgetBottomLeft,
  WidgetBottomRight,
} from "../../config/LayoutCards.config";

interface ILayoutProps {
  title?: string;
  position: string;
  bannerId?: any;
  selected?: boolean;
  onClick: () => void;
}

export const Widget: FC<ILayoutProps> = ({
  title,
  position,
  bannerId,
  selected,
  onClick,
}) => {
  const { theme, setTheme } = useTemplateContext();
  const { control } = useFormContext();

  const onClicHandler = () => {
    onClick();
    // setClicked((prev) => !prev);
    // removeActiveThemes(id);
    setTheme({ ...theme, widgetPosition: position });
  };

  const banenrType = () => {
    switch (position?.toLowerCase()) {
      case "bottom-right":
        return <WidgetBottomRight selectedLayout={selected} />;
      case "bottom-left":
        return <WidgetBottomLeft selectedLayout={selected} />;

      default:
        break;
    }
  };

  return (
    <Controller
      control={control}
      name={position}
      render={({ field: { onChange, value, ref } }) => (
        <button
          id={bannerId}
          ref={ref}
          onClick={onClicHandler}
          type="button"
          className={`layout-item group  border  hover:border-blue col-span-1 flex flex-col items-center py-4 px-3 rounded-lg bg-gray-100 transform transition-all duration-300 ${
            selected ? "bg-green-100" : ""
          }`}
          style={
            selected ? { borderColor: "blue" } : { borderColor: "transparent" }
          }>
          <>
            {banenrType()}
            <span
              className={`text-center w-full group-hover:text-primary-mainText ${
                selected ? "text-primary-mainText" : ""
              }`}
              style={
                selected
                  ? { borderColor: "blue" }
                  : { borderColor: "transparent" }
              }>
              {title}
            </span>
          </>
        </button>
      )}
    />
  );
};
