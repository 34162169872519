import { ChangeEvent, useState } from "react";
import DatePicker from "react-date-picker";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { BtnSize, BtnStyle, Button } from "../../components";

import { cx, warningBtnClasses } from "../../utils";
import Search from "../form/Search";
import { Spinner, SpinnerSize } from "./Spinner";

interface IDeleteLogsTopBar {
  className?: string;
  onDeleteLogs: () => void;
  startDate: any;
  searchTerm: string;
  setSearchTerm: any;
  setStartDate: any;
  deleteLoading?: boolean;
}

const DeleteLogsTopBar: React.FC<IDeleteLogsTopBar> = ({
  className,
  onDeleteLogs,
  startDate,
  setSearchTerm,
  setStartDate,
  searchTerm,
  deleteLoading,
}) => {
  const [dateTouched, setDateTouched] = useState<boolean>(false);

  const _onLogsSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target?.value);
  };

  // TODO: should implement debouncw
  // const onLogsSearch = debounce(_onLogsSearch);

  const handleDateChange = (date: Date) => {
    // const parsedTime = new Date(Date.UTC(date));
    // cons
    // debugger;

    // const myTimesecod = format(date, "yyyy-MM-ddTHH:mm:ss");

    setStartDate(date);
    setDateTouched(true);
  };

  return (
    <div
      className={cx([
        "flex sm:flex-1 flex-wrap items-center justify-between",
        className,
      ])}
    >
      <div className="flex">
        <Button
          btnStyle={BtnStyle.warning}
          btnSize={BtnSize.normal}
          disabled={!dateTouched || deleteLoading}
          className={cx([warningBtnClasses, "flex items-center flex-shrink-0"])}
          onClick={onDeleteLogs}
        >
          {deleteLoading && (
            <Spinner
              size={SpinnerSize.xs}
              color="text-white"
              className="mr-2"
              padding="p-0"
            />
          )}
          Delete Logs Older Than
        </Button>
        <DatePicker
          calendarIcon={
            <span className="inline-block w-6 h-6">
              <Icon name="Calendar" />
            </span>
          }
          className="bg-white focus:shadow-primary w-50 ml-4"
          onChange={(date: any) => handleDateChange(date)}
          value={startDate || ""}
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          maxDate={new Date()}
        />
      </div>

      <div className="sm:ml-4 w-50 sm:mb-0 mb-4">
        <Search
          onChange={_onLogsSearch}
          value={searchTerm}
          placeholder="Search..."
        />
      </div>
    </div>
  );
};

export default DeleteLogsTopBar;
