import { FC } from "react";

interface IFilledInfo {
  width?: number;
}

export const InfoFillPrimary: FC<IFilledInfo> = ({ width }) => {
  return (
    <span className="w-4 h-4">
      <svg
        width={width || 19}
        height={width || 18}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.994141 8.99844C0.994141 4.24879 4.84449 0.398437 9.59414 0.398438C14.3438 0.398438 18.1941 4.24879 18.1941 8.99844C18.1941 13.7481 14.3438 17.5984 9.59414 17.5984C4.84449 17.5984 0.99414 13.7481 0.994141 8.99844ZM9.59414 4.95844C9.92551 4.95844 10.1941 5.22707 10.1941 5.55844V5.56987C10.1941 5.90124 9.92551 6.16987 9.59414 6.16987C9.26277 6.16987 8.99414 5.90124 8.99414 5.56987V5.55844C8.99414 5.22707 9.26277 4.95844 9.59414 4.95844ZM7.27986 8.42701C7.27986 8.09564 7.54848 7.82701 7.87986 7.82701H10.1941V11.827H11.3084C11.6398 11.827 11.9084 12.0956 11.9084 12.427C11.9084 12.7584 11.6398 13.027 11.3084 13.027H7.87985C7.54848 13.027 7.27985 12.7584 7.27985 12.427C7.27985 12.0956 7.54848 11.827 7.87985 11.827H8.99414V9.02701H7.87986C7.54848 9.02701 7.27986 8.75838 7.27986 8.42701Z"
          fill="#1D79F2"
        />
      </svg>
    </span>
  );
};

export default InfoFillPrimary;
