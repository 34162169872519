import { ChangeEvent, FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { FormControl, Label, Loader, Message } from "../../../../components";
import {
  USER_PROFILE_API_HEADERS,
  USER_PROFILE_MAIN_PATH,
} from "../../../../constants";
import { useFetch } from "../../../../hooks";
import { cx } from "../../../../utils";
import { handleErrors } from "../../utils/handle-errors";

interface IModelIcon {
  isFromEdit: boolean;
}

export const ModelIcon: FC<IModelIcon> = ({ isFromEdit }) => {
  const [iconUrl, setIconUrl] = useState<string>("");
  const { apiCall: addModelIcon, loading: addIconLoading } = useFetch("post");
  const {
    setValue,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register("icon", { required: "Icon is required" });
    isFromEdit && setIconUrl(getValues()?.icon || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues()?.icon]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const value = e.target?.files[0];
      const formData = new FormData();

      e.target.value = "";

      formData.append("Image", value);

      addModelIcon(
        `${USER_PROFILE_MAIN_PATH}/image`,
        formData,
        (response) => {
          const image = response?.data?.image;
          setIconUrl(image);
          response?.data && setValue("icon", image);
          trigger("icon");
        },
        (error) => {
          handleErrors(error);
        },
        USER_PROFILE_API_HEADERS
      );
    }
  };

  const removeModelIcon = () => {
    setIconUrl("");
    setValue("icon", "");
  };

  return (
    <FormControl>
      <Label required text="Icon" htmlFor="icon" />

      <div
        className={cx([
          "border rounded border-dashed relative cursor-pointer hover:border-gray-400 transition flex flex-col items-center py-4",
          errors?.icon ? "border-warning" : "border-primary-stroke",
        ])}
      >
        {addIconLoading ? (
          <Loader className="p-0" noPadding />
        ) : (
          <>
            {iconUrl ? (
              <img
                src={iconUrl}
                width={40}
                height={40}
                alt=""
                className="rounded object-cover"
              />
            ) : (
              <div className="text-primary-secText w-10 h-10">
                <Icon name="Image" size={38} />
              </div>
            )}
            <p className="text-xs text-gray-500 mt-2">
              {!!iconUrl ? iconUrl : "Upload model icon"}
            </p>
          </>
        )}

        <input
          type="file"
          name="icon"
          onChange={handleFileChange}
          accept="image/x-png, image/png, image/jpg, image/jpeg, image/svg+xml"
          className="opacity-0 w-full h-full absolute left-0 top-0 cursor-pointer"
        />

        {iconUrl && (
          <button
            type="button"
            className="w-6 h-6 text-primary-secText hover:text-warning absolute top-1 right-1 bg-white rounded"
            title="Remove"
            onClick={removeModelIcon}
          >
            <span className="w-6 h-6 inline-block">
              <Icon name="Close" />
            </span>
          </button>
        )}
      </div>
      {errors?.icon && <Message message={errors?.icon?.message} />}
    </FormControl>
  );
};
