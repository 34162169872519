import axios from "axios";
import { isAfter, isValid } from "date-fns";
import { getUserInfo } from ".";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
axios.defaults.headers = {
    "Content-Type": "application/json, text/plain, */*"
};

const clearAllCookies = () => {
    return  document.cookie.split(";").forEach(function(c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
   });
}

const clearUserStoredInfo = (errorState: any) => {
   /**
     * This function clears all user info on local storage and cookies if any change on the configuration has been made
     * After certain configurations are changed, user token has changed, so this user is no longer authenticated, it throws an error with status code 401
     * So, when certain conditions are met, we clear localStorage and all the cookies, and reload the page
     * 
     * @const deploymentDate returns the date when the latest deploy is made (we manage this by changing manually the const on .env file)
     * @const datesAreValid checks if the deploy date and the date when we clear these user data are valid
     * @const clearUserInfo checks if the deploy date is before the date when we clear these user data
   */

  const deploymentDate = new Date(process.env.REACT_APP_DEPLOYMENT_DATE || "")
 
  if (errorState.response?.status === 401) {
      let clearUserInfoDate = localStorage.getItem('clear_session_date');
      
      const datesAreValid = isValid(new Date(clearUserInfoDate || "")) && isValid(deploymentDate || "");
      const clearUserInfo = datesAreValid && isAfter(deploymentDate, new Date(clearUserInfoDate || ""));
          
      if(!clearUserInfoDate || (datesAreValid && clearUserInfo)) {
         /**
         * Clear all localStorage data
         */
          window.localStorage.clear();

          clearAllCookies();
          window.location.reload();

         /**
         * Save the current date on clear_session_date key in localStorage
         */
          localStorage.setItem("clear_session_date", new Date().toString());
      }
  }
}
  
export const setupAxios = (history: any) => {
    // request intercpetor
    axios.interceptors.request.use((request) => {
        // config token as default per request
        const {access_token} = getUserInfo()
            if (access_token) {
                request.headers.authorization = `Bearer ${access_token}`;
            }
        return request;
    });

    axios.interceptors.response.use(
        (response) => {
            // Edit response config
            return { ...response };
        },
        (error) => {
            clearUserStoredInfo(error)
            return Promise.reject(error);
        }
    );
};

export { axios };
