import { FC } from "react";

export const LockSecurity: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path d="M12.9686 15.7502C13.5837 15.4091 14 14.7532 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 14.7532 10.4163 15.4091 11.0314 15.7502C11.0109 15.8301 11 15.9138 11 16V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V16C13 15.9138 12.9891 15.8301 12.9686 15.7502Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.56231 4.85048C10.1589 4.17452 11.0294 3.75 12 3.75C13.7949 3.75 15.25 5.20507 15.25 7V9H7C5.34315 9 4 10.3431 4 12V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V12C20 10.3431 18.6569 9 17 9H16.75V7C16.75 4.37665 14.6234 2.25 12 2.25C10.5812 2.25 9.30698 2.87299 8.43771 3.85788C8.16361 4.16843 8.19316 4.64238 8.50371 4.91648C8.81426 5.19058 9.28821 5.16103 9.56231 4.85048ZM7 10.5H17C17.8284 10.5 18.5 11.1716 18.5 12V19C18.5 19.8284 17.8284 20.5 17 20.5H7C6.17157 20.5 5.5 19.8284 5.5 19V12C5.5 11.1716 6.17157 10.5 7 10.5Z"
      />
    </svg>
  );
};
