import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ButtonSection } from ".";
import { Modal } from "../../../../components";
import { ModalSize } from "../../../../components/modal";
import { USERS_PATH } from "../../../../constants";
import { useFetch, useToggle } from "../../../../hooks";

interface IDeleteUser {
  user: {
    mfaEnrollmentVerification: any;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
  };
}

export const DeleteUser: React.FC<IDeleteUser> = ({ user }) => {
  const navigate = useNavigate();
  const { apiCall: deleteUser, loading: deleteUserLoading } =
    useFetch("delete");

  const { toggle: deleteModalToggle, visible: deleteModalVisible } =
    useToggle();

  const onDeleteUser = () => {
    deleteUser(`/Users/${user.id}`, {}, () => {
      toast.success("User deleted successfully!");
      deleteModalToggle();
      navigate(`${USERS_PATH}`);
    });
  };

  return (
    <>
      <ButtonSection
        title="Delete user"
        description="The user will be removed and it will no longer have access to your
    applications."
        btnText="Delete"
        onClick={deleteModalToggle}
      />

      {deleteModalVisible && (
        <Modal
          hide={deleteModalToggle}
          visible={deleteModalVisible}
          title="Delete user"
          onConfirmClick={onDeleteUser}
          confirmBtnText="Delete"
          warningModal
          modalSize={ModalSize.sm}
          withFooter
          loading={deleteUserLoading}
          blockOutsideClick
        >
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">
              {user?.firstName} {user?.lastName}
            </strong>
            ?
          </p>
        </Modal>
      )}
    </>
  );
};
