import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { NavLink } from "react-router-dom";
import {
  DeleteSection,
  FormControl,
  Label,
  Paragraph,
  ToggleSwitch,
  Input,
  InputSize,
  Textarea,
  BtnSize,
  BtnStyle,
  Button,
} from "../../../../components";
import { MultiTagsCard } from "../../../../components/ui/MultiTagsCard";
import {
  APPS_PATH,
  MANAGE_USER_DATA_DELETION_PATH,
} from "../../../../constants";
import { IApplication } from "../../../../interfaces";
import {
  inputClasses,
  premiumFeatureEnabled,
  primaryBtnClasses,
  validateUrl,
} from "../../../../utils";
import { ApplicationLogo, DeleteAppModal } from ".";
import { useToggle } from "../../../../hooks";

interface IBasics {
  scopes: string[];
  setScopes: Dispatch<SetStateAction<string[]>>;
  redirectUris: string[];
  setRedirectUris: Dispatch<SetStateAction<string[]>>;
  grantTypes: string[];
  setGrantTypes: Dispatch<SetStateAction<string[]>>;
  client: {
    clientId: string;
    clientName: string;
    id: string;
  };
}

export const Basics: FC<IBasics> = (props) => {
  const { visible, toggle } = useToggle();
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<IApplication>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const applicationEnabled = watch("displayApplicationInUserAccount");

  return (
    <>
      <FormControl>
        <Label required text="Name" />
        <Input
          {...register("clientName", {
            required: "Client name is required",
          })}
          className={inputClasses}
          maxLength={100}
          error={(!!errors.clientName && errors.clientName.message) || ""}
          inputSize={InputSize.sm}
        />
      </FormControl>

      <FormControl description="A free text description of the application.">
        <Label htmlFor="description" text="Description" />
        <Textarea
          className="resize-none transition focus:shadow-primary hover:border-gray-400  text-blue-mainText"
          {...register("description")}
          maxLength={3000}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="displayApplicationInUserAccount"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="enable-application"
              label="Display the applications under user’s My Account profile"
              description="Specifies if this application is shown on the application's dropdown menu on user's account"
              onChange={onChange}
              checked={value}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      {applicationEnabled && (
        <FormControl description="URI to redirect the user to the application (used on user profile). Make sure to specify the HTTP/HTTPS protocol (https://).">
          <Label required text="Application URL" />
          <Input
            {...register("applicationUriToDisplay", {
              required: "Application URL is required",
              validate: (value) =>
                validateUrl(value) || "Please enter a valid URL.",
            })}
            className={inputClasses}
            maxLength={100}
            type="url"
            error={
              (!!errors.applicationUriToDisplay &&
                errors.applicationUriToDisplay.message) ||
              ""
            }
            inputSize={InputSize.sm}
          />
        </FormControl>
      )}

      <ApplicationLogo register={register} setValue={setValue} />

      <FormControl>
        <Controller
          control={control}
          name="enabled"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="enabled"
              label="Enabled"
              description="Specifies if client is enabled. Defaults to true."
              onChange={onChange}
              checked={value}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="requireClientSecret"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="request-client-secret"
              label="Require Client Secret"
              description="Specifies whether this client needs a secret to request tokens from the token endpoint (defaults to true)"
              onChange={onChange}
              checked={value}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="requireRequestObject"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="request-obj"
              label="Require Request Object"
              description="Specifies whether this client needs to wrap the authorize
                    request parameters in a JWT (defaults to false)"
              onChange={onChange}
              checked={value}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="requirePkce"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="require-pkce"
              label="Require PKCE"
              description="Specifies whether clients using an authorization code based
                    grant type must send a proof key"
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="allowPlainTextPkce"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="text-pkce"
              label="Allow Plain Text PKCE"
              description="Specifies whether clients using PKCE can use a plain text code
                challenge (not recommended - and default to false)"
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="allowOfflineAccess"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="offline-access"
              label="Allow Offline Access"
              description="Specifies whether this client can request refresh tokens (be
                      requesting the offline_access scope)"
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Controller
          control={control}
          name="allowAccessTokensViaBrowser"
          render={({ field: { onChange, value, ref } }: any) => (
            <ToggleSwitch
              id="access-token"
              label="Allow Access Token Via Browser"
              description="Specifies whether this client is allowed to receive access
                    tokens via the browser. This is useful to harden flows that
                    allow multiple response types (e.g. by disallowing a hybrid flow
                    client that is supposed to use code id_token to add the token
                    response type and thus leaking the token to the browser."
              onChange={onChange}
              checked={value || false}
              inputRef={ref}
            />
          )}
        />
      </FormControl>

      <FormControl>
        <Label text="Allowed Scopes" />
        <Paragraph className="mb-3">
          By default a client has no access to any resources - specify the
          allowed resources by adding the corresponding scopes names
        </Paragraph>

        <MultiTagsCard
          tags={props.scopes}
          setTags={props.setScopes}
          suggestedEndpoint="/Clients/SearchScopes?limit=0"
          hasInput
        />
      </FormControl>

      <FormControl
        description={
          <>
            After the user authenticates we will only redirect to any of
            registered URL's. You can specify multiple valid URLs by writing
            their names on input and pressing Enter. Make sure to specify the
            HTTP/HTTPS protocol (
            <span className="bg-primary-stroke px-1 py-0.5 rounded">
              https://
            </span>
            ) otherwise the callback may fail in some cases. You can specify
            multiple valid URLs, and also use wildcards at the path level (
            <span className="bg-primary-stroke px-1 py-0.5 rounded">
              e.g.: https://google.com/*
            </span>
            ). With the exception of custom URL schemes for native clients, all
            callbacks should use HTTP/HTTPS protocol.
          </>
        }
      >
        <Label text="Allowed Redirect URLs" />
        <MultiTagsCard
          tags={props.redirectUris}
          setTags={props.setRedirectUris}
          suggestedEndpoint=""
          noSuggestedTags
          hasInput
        />
      </FormControl>

      <FormControl>
        <Label text="Allowed Grant Types" />
        <Paragraph className="mb-3">
          Specifies the grant types the client is allowed to use. Use the
          GrantTypes class for common combinations. List of default grants:
          Implicit Grant - (implicit), Client Credentials Grant -
          (client_credentials), Authorization Code Grant - (authorization_code),
          Hybrid Grant - (hybrid), Resource Owner Password Credentials Grant -
          (password)
        </Paragraph>

        <MultiTagsCard
          tags={props.grantTypes}
          setTags={props.setGrantTypes}
          suggestedEndpoint="/Clients/SearchGrantTypes?limit=0"
          hasInput
        />
      </FormControl>

      <FormControl description="List of client secrets credentials to access the token endpoint.">
        <Label text="Client Secrets" />
        <NavLink
          to={`${APPS_PATH}/client-secrets/${props.client?.id}`}
          state={{
            id: props.client?.id,
            name: props.client?.clientName,
          }}
        >
          <Button
            type="button"
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={primaryBtnClasses}
          >
            Manage Client Secrets
          </Button>
        </NavLink>
      </FormControl>

      <FormControl description="Dictionary to hold any custom client-specific values as needed.">
        <Label text="Properties" />
        <NavLink
          to={`${APPS_PATH}/client-properties/${props.client?.id}`}
          state={{
            id: props.client?.id,
            name: props.client?.clientName,
          }}
        >
          <Button
            type="button"
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={primaryBtnClasses}
          >
            Manage Client Properties
          </Button>
        </NavLink>
      </FormControl>

      {premiumFeatureEnabled["userDeletion"] === true && (
        <FormControl description="Manage how you are informed of a user's request to delete their data so that you can comply with user's request across your application(s).">
          <Label text="User Data" />
          <NavLink
            to={`${MANAGE_USER_DATA_DELETION_PATH}/${props.client?.id}`}
            state={{
              id: props.client?.id,
              name: props.client?.clientName,
            }}
          >
            <Button
              type="button"
              btnStyle={BtnStyle.primary}
              btnSize={BtnSize.normal}
              className={primaryBtnClasses}
            >
              Manage User Data Deletion
            </Button>
          </NavLink>
        </FormControl>
      )}

      <FormControl lastChild>
        <DeleteSection
          title="Delete this application"
          message="All your apps using this client will stop working."
          onDelete={toggle}
          className="items-center p-4 rounded bg-opacity-10 bg-red"
        />
      </FormControl>

      {visible && (
        <DeleteAppModal
          toggle={toggle}
          visible={visible}
          id={props.client?.id}
          clientName={props.client?.clientName}
        />
      )}
    </>
  );
};
