import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardSpace,
  CardStyle,
  Modal,
  SectionTitle,
} from "../../components";
import { ModalSize } from "../../components/modal";
import { CMP_API_URL, DOMAINS_PATH } from "../../constants";
import { useDomainContext } from "../../context";
import { useFetch, useToggle } from "../../hooks";
import { SEO } from "../../utils";
import { ButtonSection } from "../user-management/users/components";
import { BlockSection, ViewCodeModal } from "./components";

interface IDomainOverview {
  className?: string;
  message?: string;
}

export const DomainOverview: React.FC<IDomainOverview> = () => {
  const { toggle, visible } = useToggle();
  const navigate = useNavigate();
  const { toggle: deleteDomainModalToggle, visible: deleteDomainModalVisible } =
    useToggle();

  const { domain } = useDomainContext();
  const { name, websiteUrl, encryptedId, consentScriptUri, consentCssUri } =
    !!domain && domain;
  const { apiCall: deleteDomain, loading } = useFetch("delete");

  const onDeleteDomain = () => {
    deleteDomain(`${CMP_API_URL}/DeleteDomain?id=${encryptedId}`, {}, () => {
      navigate(DOMAINS_PATH);
      toast.success("Domain deleted successfully!");
    });
  };

  useEffect(() => {
    SEO({
      title: "Porta - Domain Overview",
    });
  }, []);

  return (
    <>
      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6">
        <SectionTitle className="mb-6">General Information</SectionTitle>

        <div className="flex justify-between">
          <div className="flex-1">
            <BlockSection
              title="Domain name"
              message={websiteUrl}
              className="mb-6"
            />
            <BlockSection
              title="Code"
              message="bd1244b1"
              withButton
              onButtonClick={toggle}
              buttonText="View code"
            />
          </div>

          <div className="flex-1">
            <BlockSection
              title="Cookies"
              message={domain?.cookies.length}
              className="mb-6"
              withButton
              buttonText="View cookies"
              onButtonClick={() =>
                navigate(`${DOMAINS_PATH}/${encryptedId}/cookies`)
              }
            />
            <BlockSection title="Widget Version" message="2.3.6 (latest)" />
          </div>
        </div>
      </Card>

      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6">
        <SectionTitle className="mb-6">Actions</SectionTitle>

        <ButtonSection
          disabled
          primary
          title="Reset user consents"
          description="If you have made drastic changes to your web site or for some reason want to ask all users to give consent again you can reset user consents."
          btnText="Reset User Consents"
          className="mb-12"
        />

        <ButtonSection
          primary
          disabled
          title="Import Settings"
          description="Speed up configuring this domain by importing settings from another domain in your account"
          btnText="Import Settings"
          className="mb-12"
        />
        {/* </Card>

      <Card
        cardStyle={CardStyle.bordered}
        cardSpace={CardSpace.xl}
        className="mt-6"
      > */}
        <SectionTitle className="mb-6">Sensitive Actions</SectionTitle>
        <ButtonSection
          title="Delete domain"
          description="This will delete your domain including any customizations and consent log. Please note that this action CANNOT be reverted."
          btnText="Delete"
          onClick={deleteDomainModalToggle}
          className="flex justify-between items-center bg-warning-100 bg-opacity-30 py-4 px-3 rounded-md"
          inlineButtonSection
        />
      </Card>

      {visible && (
        <ViewCodeModal
          toggle={toggle}
          visible={visible}
          jsScript={consentScriptUri}
          styleScript={consentCssUri}
        />
      )}
      {deleteDomainModalVisible && (
        <Modal
          hide={deleteDomainModalToggle}
          visible={deleteDomainModalVisible}
          title="Delete domain"
          onConfirmClick={onDeleteDomain}
          confirmBtnText="Delete"
          modalSize={ModalSize.sm}
          warningModal
          withFooter
          loading={loading}>
          <p className="text-sm text-primary-mainText mb-4">
            Are you sure you want to delete{" "}
            <strong className="font-medium">{name}</strong>?
          </p>
        </Modal>
      )}
    </>
  );
};
