import { useEffect, FC } from "react";
import { useParams } from "react-router-dom";

import { BackButton, Loader, Tab, Tabs, TopDetails } from "../../components";
import { MainContainer } from "../../components/layout/MainContainer";
import { DOMAINS_PATH } from "../../constants";
import { useDomainContext } from "../../context";
import { DomainOverview, DomainSettings, DomainCookies } from "./../";
import { DomainCategories } from "./DomainCategories";
import { DomainCookieScan } from "./DomainCookieScan";
import { DomainConsentLogs } from "./DomainConsentLogs";
import { DomainUIBuilder } from "./DomainUIBuilder";

export const Domain: FC = () => {
  const { domain, setDomainId, loading } = useDomainContext();
  const { name, websiteUrl } = !!domain && domain;

  let { domainId } = useParams();

  useEffect(() => {
    setDomainId(domainId);
    localStorage.setItem("did", `${domainId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  if (loading) {
    return <Loader />;
  }

  const routes = [
    { path: `overview`, element: <DomainOverview />, index: true },
    { path: `settings`, element: <DomainSettings /> },
    { path: `categories`, element: <DomainCategories /> },
    { path: `cookies`, element: <DomainCookies /> },
    { path: `cookie-scan`, element: <DomainCookieScan /> },
    { path: `consent-log`, element: <DomainConsentLogs /> },
    { path: `ui-builder`, element: <DomainUIBuilder /> },
  ];

  return (
    <MainContainer>
      <div>
        <BackButton to={DOMAINS_PATH} className="mb-4" label="Back to Domains">
          <></>
        </BackButton>
        <TopDetails
          imgUrl={""}
          title={name}
          message={websiteUrl}
          index={domain?.colorId}
        />
      </div>

      <Tabs routes={routes} wrapperClassname="mt-8 sm:mt-0">
        <Tab path="overview" label="Overview" />
        <Tab path="settings" label="Settings" />
        <Tab path="categories" label="Categories" />
        <Tab path="cookies" label="Cookies" />
        <Tab path="cookie-scan" label="Cookie Scan" />
        <Tab path="consent-log" label="Consent Log" />
        <Tab path="ui-builder" label="UI Builder" />
      </Tabs>
    </MainContainer>
  );
};
