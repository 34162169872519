import { FC } from "react";
import { TemplatesContextProvider } from "../../../context";
import TemplateConfig from "./config/TemplateConfig";

const EditTemplate: FC = () => {
  return (
    <TemplatesContextProvider>
      <TemplateConfig />
    </TemplatesContextProvider>
  );
};

export default EditTemplate;
