import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";

import {
  DropdownOption,
  Initials,
  Td,
  ThreeDotsButton,
} from "../../../../components";
import { MODELS_PATH } from "../../../../constants";
import { useToggle } from "../../../../hooks";
import { Dropdown } from "../../../clients/api-resources/components";
import { DeleteModelModal, PublishModelModal } from "../index";
import { Status } from "./Status";

export const ModelListItem = (props: any) => {
  const sortable = useSortable({ id: props.index + 1 });
  const {
    // attributes, listeners, setNodeRef,
    transform,
    transition,
  } = sortable;

  const { toggle: deleteModalToggle, visible: deleteModalVisible } =
    useToggle();
  const { toggle: publishModalToggle, visible: publishModalVisible } =
    useToggle();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <tr className="transition hover:bg-gray-50" style={style}>
        <Td className="py-3">
          <NavLink
            to={`${MODELS_PATH}/${props.id}/details`}
            className="hover:text-primary flex items-center transition"
          >
            <Initials text={props?.name} className="mr-4" />
            {props.name}
          </NavLink>
        </Td>
        {/* <Td className="relative py-3">{props?.modelType}</Td> */}
        <Td className="relative py-3">
          {props?.createdDate
            ? format(new Date(props?.createdDate), "MMMM dd, yyyy")
            : ""}
        </Td>
        <Td className="py-3">
          <Status
            label={props?.isPublished ? "Published" : "Unpublished"}
            isPublished={props?.isPublished}
          />
        </Td>
        <Td />
        {/* <Td className="relative py-3">
          {props.isPublished &&
            <button
              title="Drag"
              type="button"
              ref={setNodeRef}
              {...attributes}
              {...listeners}
              className="mr-3 w-7 h-7 cursor-move focus:shadow-outlinePrimary rounded flex items-center transition justify-center border border-dashed border-transparent hover:border-primary-stroke"
            >
              <DragPoints />
            </button>
          }
        </Td> */}

        <Td className="text-primary-secText py-3" align="right">
          <Dropdown
            width="w-48"
            noPadding
            dropdownContent={
              <>
                <DropdownOption
                  isLink
                  withIcon
                  iconName="Info"
                  label="View Details"
                  to={`${MODELS_PATH}/${props.id}/details`}
                />
                <DropdownOption
                  isLink
                  withIcon
                  iconName="Server"
                  label={
                    props.canUpdateDataImport
                      ? "Edit Data Import"
                      : "Create Data Import"
                  }
                  to={`${MODELS_PATH}/${props.id}/data-import`}
                />
                <DropdownOption
                  withIcon
                  iconName="UploadCloud"
                  label={props.isPublished ? "Unpublish" : "Publish"}
                  onClick={publishModalToggle}
                />
                <DropdownOption
                  isLink
                  withIcon
                  iconName="Edit"
                  label="Edit"
                  to={`${MODELS_PATH}/${props.id}/edit`}
                />
                {!props.isPublished && (
                  <DropdownOption
                    withIcon
                    iconName="Delete"
                    label="Delete"
                    onClick={deleteModalToggle}
                  />
                )}
              </>
            }
          >
            <ThreeDotsButton />
          </Dropdown>
        </Td>
      </tr>
      {deleteModalVisible && (
        <DeleteModelModal
          visible={deleteModalVisible}
          toggle={deleteModalToggle}
          props={props}
        />
      )}
      {publishModalVisible && (
        <PublishModelModal
          visible={publishModalVisible}
          toggle={publishModalToggle}
          props={props}
        />
      )}
    </>
  );
};
