import { useEffect } from "react";
import { Properties } from "../../../components";
import { APPS_PATH } from "../../../constants";
import { SEO } from "../../../utils";

export const ApplicationProperties = () => {
  useEffect(() => {
    SEO({
      title: "Porta - Application Properties",
    });
  }, []);

  return (
    <Properties
      endpoint="/Clients"
      mainTitle="Application Properties"
      backTo={APPS_PATH}
    />
  );
};
