import { ReactElement, useEffect } from "react";
import { Animation } from "../../../../components";
import useOnclickOutside from "../../../../hooks/useOnclickOutside";
import { cx } from "../../../../utils";

interface IDropdown {
  className?: string;
  children: ReactElement;
  dropdownContent: ReactElement;
  left?: boolean;
  noPadding?: boolean;
  width?: string;
  dropdownVisibility?: (opened: boolean) => void;
}

export const Dropdown: React.FC<IDropdown> = ({
  className,
  children,
  dropdownContent,
  left,
  width,
  noPadding,
  dropdownVisibility,
}) => {
  const { ref, isVisible, setIsVisible } = useOnclickOutside(false);

  const toggle = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    dropdownVisibility && dropdownVisibility(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div className={cx(["relative", className])} ref={ref}>
      <div onClick={toggle}>{children}</div>

      <Animation show={isVisible} type="fade-bottom">
        <div
          className={cx([
            "shadow-sm mt-2 border border-primary-stroke p-2 rounded inline-flex flex-col max-w-xs bg-white items-start absolute z-20 max-h-64 overflow-auto animate-fade-in",
            left && "left-0",
            !left && "right-0",
            !width && "w-56",
            width,
            !noPadding && "p-2",
          ])}
        >
          {dropdownContent}
        </div>
      </Animation>
    </div>
  );
};
