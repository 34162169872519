import { FC, ReactNode } from "react";
import { cx } from "../../utils";

interface IDropdownOption {
  icon: ReactNode;
  isLink?: boolean;
  onButtonClick?: () => void;
  name: string;
  path?: string;
  target?: string;
}

export const DropdownOption: FC<IDropdownOption> = ({
  icon,
  isLink,
  onButtonClick,
  name,
  path,
  target,
}) => {
  const optionClasses = cx([
    "py-3 px-4 transition flex flex-1 items-center group rounded w-full focus:bg-gray-200",
    "text-sm text-gray-700",
    "hover:bg-gray-100",
  ]);
  return (
    <>
      {isLink ? (
        <a target={target} href={path} className={optionClasses}>
          <div className="mr-2">{icon}</div>
          {name}
        </a>
      ) : (
        <button type="button" onClick={onButtonClick} className={optionClasses}>
          <div className="mr-2">{icon}</div>
          {name}
        </button>
      )}
    </>
  );
};
