import { FC, useEffect } from "react";

import { format } from "date-fns";
import { NavLink } from "react-router-dom";
// import { toast } from "react-toastify";

import { useCurrentPage, useFetch } from "../../hooks";
import { IConsentLog, IConsentLogsAll } from "../../interfaces";
import { SEO } from "../../utils";
import { Dropdown } from "../../pages/clients/api-resources/components";
import {
  DropdownOption,
  // ThreeDotsButton,
  Table,
  Td,
  SectionTitle,
  Tooltip,
  TooltipPosition,
  ArrowPosition,
  IconButton,
  // IconSize,
} from "../../components";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import {
  InlineStatus,
  ScanStatusProps,
} from "../../components/ui/InlineStatus";
import { useDomainContext } from "../../context";
import { CMP_API_URL } from "../../constants";

export const DomainConsentLogs: FC = () => {
  const { domain } = useDomainContext();
  const { encryptedId, name } = !!domain && domain;
  const PER_PAGE = 10;
  const { currentPage, handlePageChange } = useCurrentPage();

  const {
    apiCall: getLogs,
    response: logsData,
    loading: logsLoading,
    errors,
  } = useFetch("get");
  const { apiCall: getLogsFile, loading: logsDownloadLoading } =
    useFetch("get");

  const downloadFile = (content: any, name: string, contentType: any) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = name;
    a.click();
  };

  const onCSVFileDownload = (domainId: string) => {
    if (domainId) {
      getLogsFile(
        `${CMP_API_URL}/DownloadConsentLogs?domainId=${encryptedId}`,
        {},
        (response) => {
          downloadFile(
            response,
            `${name}__consentLogs.csv`,
            "text/csv"
            // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
      );
    }
  };

  useEffect(() => {
    SEO({
      title: "Porta - Domain Consent Log",
    });
    if (encryptedId) {
      getLogs(
        `${CMP_API_URL}/GetCookieConsentByDomainId?domainId=${encryptedId}&page=${currentPage}&pageSize=${PER_PAGE}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const checkLogStatus = (acceptedAll: boolean) => {
    let logStatus = "";
    let logMessage;
    if (acceptedAll === true) {
      logMessage = "Accepted";
      logStatus = ScanStatusProps.completed;
      return { logStatus, logMessage };
    } else {
      logMessage = "Partial";
      logStatus = ScanStatusProps.scanning;
      return { logStatus, logMessage };
    }
  };

  const { cookieConsents, pagingInfo } = !!logsData && logsData;
  // console.log(logsFileData);
  return (
    <>
      <div className="flex justify-between mt-6 mb-6">
        <div className="relative flex items-top">
          <SectionTitle noMargin>Consent Log</SectionTitle>
          <div className="relative group inline-block ml-2">
            <Tooltip
              text="
              Details of consent provided by all users that have visited your site. It can be presented to authorities or others when required. "
              innerClassName="mb-0 w-96 z-50 pb-9"
              className="text-sm text-primary-secText ml-2 w-7"
              tooltipPosition={TooltipPosition.underTooltip}
              arrowPosition={ArrowPosition.top}
              // bottom="-bottom-12"
              bottom="bottom-0"
            >
              <Icon name="Info" width={7} />
            </Tooltip>
          </div>
        </div>
        <IconButton
          className="w-max py-2 border border-gray-200 rounded-md"
          iconName="Download"
          text="Download CSV"
          // iconSize={IconSize.medium}
          onButtonClick={() => onCSVFileDownload(encryptedId)}
          loading={logsDownloadLoading}
          disabled={logsDownloadLoading}
        />
      </div>

      <Table
        loading={logsLoading}
        wrapperClassName="mt-4 sm:mt-12"
        names={[
          { children: "Consent Date" },
          { children: "IP Address" },
          { children: "Browser Agent" },
          { children: "Status", className: "text-left" },
        ]}
        values={cookieConsents as IConsentLogsAll[]}
        renderTr={(el: IConsentLog, index) => {
          // const lockoutEnd = new Date(el?.lockoutEnd) > new Date();
          const { logMessage, logStatus } = checkLogStatus(el?.acceptedAll);
          return (
            <tr className="transition hover:bg-gray-50" key={index}>
              <Td className="py-3">
                <NavLink to="#" className="hover:text-primary transition">
                  {format(new Date(el?.date), "yyyy-MM-dd, HH:mm:ss")}
                  {/* {el?.date.slice(1, -1)} */}
                </NavLink>
              </Td>
              <Td className="relative py-3">{el?.ipAddress}</Td>
              <Td className="py-3">{el?.browserAgent}</Td>
              <Td className="py-3">
                <InlineStatus
                  statusContent={logMessage}
                  // statusContent={checkLogStatus(el?.statusEnum)}
                  scanStatus={logStatus}
                  loading={logsLoading}
                  // className="ml-6"
                />
              </Td>
              <Td className="text-primary-secText py-3" align="right">
                <Dropdown
                  width="w-32"
                  noPadding
                  dropdownContent={
                    <>
                      <DropdownOption
                        isLink
                        to={""}
                        withIcon
                        iconName="Show"
                        label="Details"
                      />
                    </>
                  }
                >
                  <></>
                  {/* <ThreeDotsButton /> */}
                </Dropdown>
              </Td>
            </tr>
          );
        }}
        pagination={{
          pageSize: PER_PAGE,
          totalCount: pagingInfo?.totalItems,
          onPageChange: handlePageChange,
          page: currentPage,
          length: cookieConsents?.length,
        }}
        emptyView={{ text: errors || "No Logs yet" }}
      />
    </>
  );
};
